import React, { Component } from 'react';
import SessionStore from '../services/session-store.service';

export class Cookie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cookie: false
    };


  }
  componentDidMount() {
    let cookie = SessionStore.getGenericData('cookie');
    this.setState({ cookie })
  }
  confirmCallback = async () => {
    SessionStore.saveGenericData('cookie', true)
    this.setState({
      cookie: true
    })

  }
  cancelCallback = async () => {
    SessionStore.saveGenericData('cookie', false)
    return window.location = 'https://google.com.br/';
  }



  render() {
    return this.state.cookie ? null : <div className="modal" aria-hidden="false" style={{ backgroundColor: '#0000002f', display: true ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Uso de cookies</h5>
            <button type="button" className="close" aria-label="Close" onClick={(e) => this.cancelCallback(e)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Utilizamos cookies essenciais para navegação. Os detalhes estão em nossa  <a href="#/politica-privacidade"><u>Política de Privacidade</u></a>.</p>
            <p>Caso não autorize o uso dos cookies, será redirecionado a outro site.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={(e) => this.confirmCallback(e)}>Aceitar Cookies</button>
            <button type="button" className="btn btn-secondary" onClick={(e) => this.cancelCallback(e)}>Recusar</button>
          </div>
        </div>
      </div>
    </ div>
  }
}
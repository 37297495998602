import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';

import Environment from '../../../environment';
import CoreService from '../../../services/core.service';
import PropostasService from '../../../services/propostas.service';
import AuthService from '../../../services/auth.service';
import Loader from '../../../components/loader';
import Msgs from '../../../components/msgs';
import ResumoProposta from '../../../components/formularios/produtos/veiculos/resumo-proposta'
import ChecklistFormalizacao from '../../../components/formularios/produtos/veiculos/checklist-formalizacao';
import AbasProposta from '../../../components/formularios/produtos/veiculos/abas-propostas';
import CadastroNovo from '../../../components/formularios/produtos/veiculos/cadastro-novo';
import analyticsService from '../../../services/analytics.service';
import DataService from '../../../services/data.service';
import sessionStoreService from '../../../services/session-store.service';
import { ModalEnviarProposta } from '../../../components/modal-enviar-proposta'

class CadastroPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loader: {},
      proposta: null,
      modalEnviarProposta: {},
      formularioAtivo: null,
      pendencias: [],
      enviandoArquivo: false,
      aba: 'RESUMO',
      appInstalado: false,
      cdOrigem: sessionStoreService.getGenericData('cdOrigem'),
      camposFormularios: {
        PRECADASTRO: [],
        COMPRADOR: [],
        PROFISSIONAIS: [],
        VENDEDOR: [],
        VEICULO: [],
        PAGAMENTO: [],
      }
    };

    // Adiciona evento para atualizar a página caso o cliente saia do app e volte para esta tela.
    // Isso vai evitar que o cliente veja várias telas com dados diferentes para cada uma.
    document.addEventListener("visibilitychange", async event => {
      if (document.visibilityState === "visible") {
        if (this.state.aba === 'RESUMO') {
          await this.recuperarProposta(this.props.match.params.cdProposta);

        }
      }
    })
  }

  async componentDidMount() {
    if (!this.props.match.params.cdProposta) {
      return window.location.hash = `#/`;
    }

    if (!AuthService.isAuthenticated()) {
      sessionStoreService.saveGenericData('redirecionamentoAutenticacao', `produtos/veiculos/cp/${this.props.match.params.cdProposta}`)
      sessionStoreService.saveGenericData('cdPropostaAutenticacao', this.props.match.params.cdProposta)
      return window.location.hash = `#/clientes/auth`;
    }

    await this.listarCamposFormularios();
    await this.recuperarProposta(this.props.match.params.cdProposta);

    if (this.state.proposta && this.state.proposta.cdStatus)
      ReactGA.pageview(`/proposta/${this.state.proposta.cdStatus}`, [], `Cadastro de Proposta ${this.state.proposta.cdStatus}`);
  }

  listarCamposFormularios = async () => {
    try {
      this.setState({ loader: { message: 'processando...', show: true } });
      let camposFormularios = await DataService.listarCamposFormularios();
      this.setState({
        camposFormularios,
        loader: {}
      });
    } catch (err) {
      console.error(err);
      this.setState({ loader: {} });
    }
  }

  recuperarProposta = async (cdProposta) => {
    try {
      this.setState({ loader: { message: 'Consultando proposta...', show: true } });
      let proposta = await PropostasService.recuperarProposta(cdProposta);

      if (!proposta || proposta.status === 'fail') {
        return this.setState({ loader: {}, error: 'Você não tem autorização para visualizar esta proposta.' })
      }

      if (proposta.cdOrigem == 'OLX') {
        return window.location.hash = '#/j/OLX?cdProposta=' + proposta.cdProposta
      }

      if (proposta && proposta.pendencias.length <= 0 && ['DADOS_COMPRADOR', 'DADOS_VENDEDOR'].includes(proposta.cdStatus)) {
        this.setState({ proposta, pendencias: [], loader: {} });
        if (proposta.cdOrigem != 'OLX' || proposta.cdStatus != 'DADOS_VENDEDOR')
          this.handleEnviarPropostaModal()
      }
      else if (['ENVIO_COMPROVANTES'].includes(proposta.cdStatus)) {
        if (proposta.comprovantesEnviar.length <= 0) {
          this.setState({ proposta, pendencias: [], loader: {} });
          this.handleEnviarPropostaModal()
        } else {
          this.setState({ proposta, pendencias: [], loader: {} });
        }
      }
      else {
        this.setState({ proposta, pendencias: proposta.pendencias, loader: {} });
      }
    } catch (err) {
      console.error(err);
      this.setState({ loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  handleEnviarPropostaModal = async () => {
    this.setState({
      modalEnviarProposta: {
        show: true,
        title: 'Solicitar aprovação de crédito',
        message: this.state.proposta && ['DADOS_COMPRADOR'].includes(this.state.proposta.cdStatus) ? 'Os dados da sua proposta já estão preenchidos. Podemos solicitar a análise de crédito?' : 'Os comprovantes já foram enviados. Podemos solicitar a análise de crédito?',
        confirmText: 'Solicitar Análise',
        cancelText: 'Quero revisar os dados',
        confirmCallback: async (e) => {
          this.setState({ modalEnviarProposta: { show: false } });
          await this.handleEnviarProposta();
        },
        cancelCallback: (e) => this.setState({ modalEnviarProposta: { show: false }, aba: this.state.proposta.cdStatus })
      }
    })
  }

  handleClickAbaTela = async (tela, salvarDados = true) => {
    if (salvarDados && this.state.proposta.flPermiteEditar && ((this.state.aba === 'CADASTRO' && tela !== 'CADASTRO'))) {
      await this.salvarDadosProposta();
    }
    this.setState({ aba: tela });
  }

  handleSalvarDados = async (alterarTela = true, recuperarDados = true) => {
    try {
      await this.salvarDadosProposta();
      if (recuperarDados)
        await this.recuperarProposta(this.state.proposta.cdProposta);
      if (alterarTela)
        this.handleClickAbaTela('RESUMO', false);
    } catch (err) {
      console.error(err);
      analyticsService.sendException(err, false);
    }
  }

  salvarDadosProposta = async () => {
    try {
      this.setState({ loader: { message: 'Salvando dados...', show: true }, error: null });
      await PropostasService.persistirDadosProposta(this.state.proposta);
      this.setState({ loader: {} });
    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao salvar os dados. Tente novamente.' });
      analyticsService.sendException(err, false);
    }
  }

  handleEnviarProposta = async () => {
    try {
      this.setState({ loader: { message: 'Enviando proposta...', show: true }, error: null });
      let proposta = await PropostasService.enviarPropostaAprovacao(this.state.proposta.cdProposta);
      if (!proposta)
        this.setState({ loader: {}, error: 'Ocorreu uma falha ao enviar proposta. Tente novamente.' });

      else
        this.setState({ loader: {} }, async () => {
          await this.recuperarProposta(proposta.cdProposta);
          this.handleClickAbaTela('RESUMO');
        });
    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao enviar proposta. Tente novamente.' });
      analyticsService.sendException(err, false);
    }
  }

  handleFalarWhatsApp = () => {
    let url = `https://wa.me/${Environment.get('WHATSAPP')}?text=Olá, preciso de ajuda.`;
    var win = window.open(url, '_blank');
    win.focus();
  }
  handleNotificarVendedor = () => {
    let url = `https://wa.me/?text=${encodeURIComponent(`O banco está aguardando os dados do veículo. Acesse o link para cadastrar os dados: ${Environment.get('PORTAL_VENDEDOR_URL')}#/produtos/veiculos/proposta/${this.state.proposta.cdProposta}`)}`;
    var win = window.open(url, '_blank');
    win.focus();
  }

  handleAssinarContrato = () => {
    let url = `https://app.clicksign.com/sign/${this.state.proposta.idSignatarioAssinaturaDigital}`;
    var win = window.open(url, '_blank');
    win.focus();
  }

  handleSalvarProposta = async () => {
    await CoreService.installApp();
    this.setState({ appInstalado: !CoreService.canInstallApp() })
  }

  handleDadosPropostaAlterados = (proposta) => {
    let dadosProposta = Object.assign(this.state.proposta, proposta);
    this.setState({ proposta: dadosProposta });
  }

  render() {
    let permiteSalvar = this.state.appInstalado ? false : CoreService.canInstallApp();
    let etapaProcesso = PropostasService.recuperarEtapa(this.state.proposta ? this.state.proposta.cdStatus : '');
    if (this.state.proposta && this.state.proposta.flPermiteEditar && ['CADASTRO', 'DADOS_COMPRADOR', 'DADOS_VENDEDOR'].indexOf(this.state.aba) > -1) {
      window.onbeforeunload = (e) => {
        if (['CADASTRO', 'DADOS_COMPRADOR', 'DADOS_VENDEDOR'].indexOf(this.state.aba) > -1) {
          return true
        }
        return false
      };
    } else {
      window.onbeforeunload = undefined
    }
    return <Fragment>
      <Loader message={this.state.loader.message} show={this.state.loader.show} />
      {this.state.enviandoArquivo ?
        <Fragment>
          <h5>Enviando imagem</h5>
          <div className="progress"><div className="progress-bar" style={{ width: this.state.loaded + '%' }}></div></div>
        </Fragment>

        : <div className="container-fluid">
          <div className="row justify-content-center" >
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">

              {this.state.proposta ? <AbasProposta
                abaAtiva={this.state.aba}
                etapaProcesso={etapaProcesso}
                cdOrigem={this.state.proposta ? this.state.proposta.cdOrigem : ''}
                onClickAba={this.handleClickAbaTela}
              /> : null}

              <Msgs info={this.state.msg} error={this.state.error} />

              {this.state.aba === 'RESUMO' && this.state.proposta ? <ChecklistFormalizacao
                proposta={this.state.proposta}
                pendencias={this.state.pendencias}
                permiteSalvar={permiteSalvar}
                cdOrigem={this.state.cdOrigem}
                onClickPreencherCadastro={e => this.handleClickAbaTela('CADASTRO')}
                onClickPreencherDados={e => this.handleClickAbaTela('DADOS_COMPRADOR')}
                onClickEnviarProposta={e => this.handleClickAbaTela('PROPOSTA')}
                onClickFalarWhatsApp={this.handleFalarWhatsApp}
                onClickSalvarProposta={this.handleSalvarProposta}
                onClickEnviarComprovantes={e => this.handleClickAbaTela('ENVIO_COMPROVANTES')}
                onClickEnviarPropostaAprovacao={this.handleEnviarProposta}
                onClickNotificarVendedor={this.handleNotificarVendedor}
                onClickAssinarContrato={this.handleAssinarContrato}
              /> : null}
              {this.state.aba === 'RESUMO' && this.state.proposta && this.state.pendencias.length <= 0 ? <ModalEnviarProposta
                show={this.state.modalEnviarProposta.show}
                title={this.state.modalEnviarProposta.title}
                message={this.state.modalEnviarProposta.message}
                confirmText={this.state.modalEnviarProposta.confirmText}
                cancelText={this.state.modalEnviarProposta.cancelText}
                confirmCallback={this.state.modalEnviarProposta.confirmCallback}
                cancelCallback={this.state.modalEnviarProposta.cancelCallback} /> : null}

              {this.state.aba === 'PROPOSTA' && this.state.proposta ? <ResumoProposta
                proposta={this.state.proposta}
                pendencias={this.state.pendencias}
              /> : null}

              {['CADASTRO', 'DADOS_COMPRADOR', 'DADOS_VENDEDOR', 'ENVIO_COMPROVANTES'].indexOf(this.state.aba) > -1
                && this.state.proposta && this.state.proposta.cdProposta ? <CadastroNovo
                camposFormularios={this.state.camposFormularios}
                proposta={this.state.proposta}
                produto={this.state.proposta.dsProduto}
                etapaProcesso={etapaProcesso}
                pendencias={this.state.pendencias}
                flPermiteEditar={this.state.proposta.flPermiteEditar}
                onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
                onSalvarDados={this.handleSalvarDados}
                onClickEnviarPropostaAprovacao={this.handleEnviarProposta}
                onRecuperarDadosProposta={this.recuperarProposta}
              /> : null}

              {this.state.proposta &&
                (
                  (this.state.proposta.cdOrigem == 'OLX' && ['CADASTRO', 'DADOS_COMPRADOR'].includes(etapaProcesso)) ||
                  (this.state.proposta.cdOrigem != 'OLX' && ['CADASTRO', 'DADOS_COMPRADOR', 'DADOS_VENDEDOR'].includes(etapaProcesso))
                )
                && this.state.aba === 'PROPOSTA'
                && (!this.state.pendencias || this.state.pendencias.length <= 0) ?
                <button className="btn btn-block btn-primary py-2"
                  onClick={this.handleEnviarProposta}>Encaminhar Proposta</button>
                : null}
            </div>

          </div>
        </div>
      }

      {/* {this.state.proposta && ['CADASTRO', 'COMPLEMENTO', 'ENVIO_COMPROVANTES'].indexOf(etapaProcesso) > -1 && this.state.aba === 'PROPOSTA' && (!this.state.pendencias || this.state.pendencias.length <= 0) ?
        <div className="row">
          <div className='col-12'>
            <button className="btn btn-block btn-primary"
              onClick={this.handleEnviarProposta}>Encaminhar Proposta</button>
          </div>

        </div>
        : null} */}
    </Fragment>
  }
}

export default CadastroPage;
import React, { Component, Fragment } from 'react';

import ReactCamera, { IMAGE_TYPES, FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

class Camera extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileUri: null
        }
    }

    onTakePhoto = (fileUri) => {
        this.setState({ fileUri });
    }

    onTakeAgain = () => {
        this.setState({ fileUri: undefined });
    }

    onConfirm = () => {
        if (this.props.onTakePhoto)
            this.props.onTakePhoto(this.state.fileUri);
    }

    onCameraError = (err) => {
        console.error(err);
        if (this.props.onCameraError)
            this.props.onCameraError(err);
    }

    onClickVoltar = () => {
        if (this.props.onClickVoltar)
            this.props.onClickVoltar();
    }

    render() {
        return (
            <div className="row justify-content-center p-0 m-0" style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, background: '#000' }}>
                {!this.state.fileUri ?
                    <Fragment>
                        <ReactCamera
                            idealResolution={{ width: 720, height: 1280 }}
                            imageType={IMAGE_TYPES.JPG}
                            imageCompression={0.97}
                            isMaxResolution={true}
                            isImageMirror={false}
                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                            onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri); }}
                            onCameraError={(error) => { this.onCameraError(error); }}
                        />
                        <button className="btn btn-link" style={{ position: 'fixed', bottom: 35, left: 35, color: '#fff', background: 'rgba(0,0,0,0.8)' }}
                            onClick={this.onClickVoltar}>Voltar</button>
                    </Fragment> :
                    <Fragment>
                        <img alt="Daycoval" src={this.state.fileUri} style={{ width: '100%' }} />
                        <button className="btn btn-link" style={{ position: 'fixed', bottom: 35, left: 35, color: '#fff', background: 'rgba(0,0,0,0.8)' }}
                            onClick={this.onTakeAgain}>Voltar</button>
                        <button className="btn btn-link" style={{ position: 'fixed', bottom: 35, right: 35, color: '#fff', background: 'rgba(0,0,0,0.8)' }}
                            onClick={this.onConfirm}>Confirmar</button>
                    </Fragment>
                }
            </div>
        );
    }
}

export default Camera;
import ApiService from './api.service';

class SimulacoesService {
  async cadastrarSimulacao(cdProduto, vlSolicitado, qtParcelas, vlEntrada, vlAnoBem, idVeiculo, cdUf, cdOrigem) {
    return ApiService.post(`financiamentos/cadastrar-simulacao`, { cdProduto, vlSolicitado, qtParcelas, vlEntrada, vlAnoBem, idVeiculo, cdUf, cdOrigem });
  }

  async confirmarSimulacao(cdSimulacao, cdOpcao) {
    return ApiService.post(`financiamentos/confirmar-simulacao`, { cdSimulacao, cdOpcao });
  }
}

export default new SimulacoesService();
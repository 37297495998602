import React, { Component } from 'react';

class LoaderSpinner extends Component {
  render() {
    return !this.props.show ? '' : <div style={{
      background: '#00000055', zIndex: 9999,
      width: '100%', height: '100%', position: "fixed",
      left: 0, right: 0, bottom: 0, top: 0, display: 'block'
    }}>
      <p style={{
        width: '300px', marginLeft: 'auto', marginRight: 'auto', marginTop: '100px',
        padding: '15px', borderRadius: '7px', background: '#00000077',
        boxShadow: '1px 1px 4px #000000bb',
        textAlign: 'center', color: '#eee', fontSize: '1.3rem'
      }}>{this.props.message || ''}</p>
    </div>
  }
}

export default LoaderSpinner;
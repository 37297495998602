import React, { Component, Fragment } from 'react';

import CampoFormatado from '../../../campo-formatado';

const ESTADOS = [
  { cdOpcao: 'SP', dsOpcao: 'São Paulo' },
  // { cdOpcao: 'AL', dsOpcao: 'Alagoas' },
  // { cdOpcao: 'AC', dsOpcao: 'Acre' },
  // { cdOpcao: 'AM', dsOpcao: 'Amazonas' },
  // { cdOpcao: 'AP', dsOpcao: 'Amapá' },
  // { cdOpcao: 'BA', dsOpcao: 'Bahia' },
  // { cdOpcao: 'CE', dsOpcao: 'Ceará' },
  { cdOpcao: 'DF', dsOpcao: 'Distrito Federal' },
  { cdOpcao: 'ES', dsOpcao: 'Espírito Santo' },
  { cdOpcao: 'GO', dsOpcao: 'Goiás' },
  // { cdOpcao: 'MA', dsOpcao: 'Maranhão' },
  { cdOpcao: 'MT', dsOpcao: 'Mato Grosso' },
  { cdOpcao: 'MS', dsOpcao: 'Mato Grosso do Sul' },
  { cdOpcao: 'MG', dsOpcao: 'Minas Gerais' },
  { cdOpcao: 'PR', dsOpcao: 'Paraná' },
  // { cdOpcao: 'PA', dsOpcao: 'Pará' },
  // { cdOpcao: 'PB', dsOpcao: 'Paraíba' },
  // { cdOpcao: 'PE', dsOpcao: 'Pernambuco' },
  // { cdOpcao: 'PI', dsOpcao: 'Piauí' },
  // { cdOpcao: 'RJ', dsOpcao: 'Rio de Janeiro' },
  // { cdOpcao: 'RN', dsOpcao: 'Rio Grande do Norte' },
  { cdOpcao: 'RS', dsOpcao: 'Rio Grande do Sul' },
  // { cdOpcao: 'RO', dsOpcao: 'Rondônia' },
  // { cdOpcao: 'RR', dsOpcao: 'Roraima' },
  { cdOpcao: 'SC', dsOpcao: 'Santa Catarina' },
  // { cdOpcao: 'SE', dsOpcao: 'Sergipe' },
  // { cdOpcao: 'TO', dsOpcao: 'Tocantins' },
]

class IdentificarCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: {},
  
    }
  }

  handleIdade = (idade) => {
    this.setState({ idade });
  }

  handleEstado = (estado) => {
    this.setState({ estado });
  }

  handleConfirmar = () => {
    if (this.props.onClienteIdentificado)
      this.props.onClienteIdentificado({
        idade: this.state.idade,
        estado: this.state.estado
      });
  }

  render() {
    return <Fragment>
      {this.props.cdLandingPage ?<div className="col-12 col-sm-6 col-md-6 col-lg-5 form-landing-page">
      <div className="row justify-content-center py-2">
        <h5 className="text-center m-0 pt-1">Qual a sua idade?</h5>
        <CampoFormatado
          label={'Leve em consideração quantos anos completos você tem hoje.'}
          formatacao="digitos"
          obrigatorio={true}
          maximo={99}
          minimo={18}
          tamano={2}
          onChange={this.handleIdade}
        />

        <h5 className="text-center m-0 pt-2">Seu estado de residência?</h5>
        <CampoFormatado
          label={'Você irá precisar de comprovante de residência no futuro.'}
          tipo="opcoes"
          opcoes={ESTADOS}
          obrigatorio={true}
          esconderOk={true}
          tamano={2}
          placeholder={'Selecione'}
          onChange={this.handleEstado}
        />
      </div>

      {this.state.idade && this.state.estado ?
        <button className="btn btn-block btn-primary" onClick={this.handleConfirmar}>Confirmar</button>
        : null}
    </div> : <div className="col-12 col-sm-6 col-md-6 col-lg-6 pb-2">
      <div className="row justify-content-center py-2">
        <h5 className="text-center m-0 pt-1">Qual a sua idade?</h5>
        <CampoFormatado
          label={'Leve em consideração quantos anos completos você tem hoje.'}
          formatacao="digitos"
          obrigatorio={true}
          maximo={99}
          minimo={18}
          tamano={2}
          onChange={this.handleIdade}
        />

        <h5 className="text-center m-0 pt-2">Seu estado de residência?</h5>
        <CampoFormatado
          label={'Você irá precisar de comprovante de residência no futuro.'}
          tipo="opcoes"
          opcoes={ESTADOS}
          obrigatorio={true}
          esconderOk={true}
          tamano={2}
          placeholder={'Selecione'}
          onChange={this.handleEstado}
        />
      </div>

      {this.state.idade && this.state.estado ?
        <button className="btn btn-block btn-primary" onClick={this.handleConfirmar}>Confirmar</button>
        : null}
    </div>}
    
    </Fragment>
  }
}

export default IdentificarCliente;
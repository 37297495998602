import SessionStoreService from './session-store.service';

class AuthService {
  setAuthData(authData) {
    SessionStoreService.saveAuthData(authData);
  }

  getAuthData() {
    return SessionStoreService.getAuthData();
  }

  clearAuthData() {
    SessionStoreService.clearAuthData();
  }

  isAuthenticated() {
    let authData = SessionStoreService.getAuthData();
    if (authData && authData.token) return true;
    return false;
  }
}

export default new AuthService();
import React, { Fragment, useState } from 'react';
import Environment from '../../environment';
import CamposInput from '../campo-input';
import BtnActions from './btn-actions';
import Paragrafo from './paragrafo';

export default function ParagrafoEditavel({ componente, input, onDadosAlterados = async () => { }, onEvento = async () => { } }) {

    const [edicao, setEdicao] = useState(false);

    async function handleDadosAlterados(dados) {
        if (onDadosAlterados)
            await onDadosAlterados(dados);
    }

    async function handleEvento(cdEvento) {
        if (cdEvento == 'CANCELAR') {
            setEdicao(false)
            return;
        }

        if (onEvento) {
            setEdicao(false)
            await onEvento(cdEvento);
        }
    }

    function handleEditar() {
        if (!permiteEditar()) return
        setEdicao(true)
    }

    function permiteEditar() {
        if (edicao) return false
        if (!componente.props) return false
        return true
    }

    return (
        !componente ? null :
            <Fragment>
                {!edicao ? <Fragment>
                    <Paragrafo
                        paragrafo={{
                            props: {
                                label: componente.props.label,
                                style: componente.props.styleLabel
                            }
                        }}
                    />
                    <Paragrafo
                        paragrafo={{
                            props: {
                                label: componente.props.valor,
                                style: componente.props.styleLabelValor,
                            }
                        }}
                        icone={permiteEditar() ? componente.props.icone : undefined}
                        onClick={() => handleEditar()}
                    />
                </Fragment> : null}

                {edicao && componente.props ? <Fragment>
                    <CamposInput
                        campos={[componente.props]}
                        flPermiteEditar={true}
                        valores={{ [componente.props.id]: input[componente.props.id] }}
                        onDadosAlterados={handleDadosAlterados}
                        onEvento={handleEvento}
                    />

                    {componente.props.acoes ?
                        <BtnActions
                            tipo={componente.props.acoes.tipo}
                            botoes={componente.props.acoes.botoes || []}
                            onEvento={handleEvento}
                        /> : null}
                </Fragment> : null}
            </Fragment>
    )
}
import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import Utils from '../../../utils/text';

import ProdutosService from '../../../services/produtos.service';
import SessionStore from '../../../services/session-store.service';

import Loader from '../../../components/loader';
import Msgs from '../../../components/msgs';

import SelecionarVeiculo from '../../../components/formularios/produtos/veiculos/selecionar-veiculos';
import IdentificarCliente from '../../../components/formularios/produtos/veiculos/identificar-cliente';
import SelecionarValor from '../../../components/formularios/produtos/veiculos/selecionar-valor';
import ResumoSimulacao from '../../../components/formularios/produtos/veiculos/resumo-escolha';
import environment from '../../../environment';
import CampoMoeda from '../../../components/campo-moeda'
import analyticsService from '../../../services/analytics.service';


const PRODUTOS = ProdutosService.listarProdutos();

let calcularParcelaTimeout;
let railStyle = { backgroundColor: '#c4dfed', height: 7 };
let trackStyle = { backgroundColor: '#0373b0', height: 7 };
let handleStyle = { borderColor: '#0373b0', height: 24, width: 24, marginLeft: 0, marginTop: -8, backgroundColor: 'white' };
let boxStyle = { background: '#efefef', borderRadius: '5px', boxShadow: '1px 2px 5px #ccc' };

class SimularPage extends Component {

  constructor(props) {
    super(props);

    let produtoSelecionado = PRODUTOS.filter(p => p.cdProduto === this.props.match.params.acao)[0];

    let produtoSimulacao = SessionStore.getGenericData('produtoSimulacao') || null;
    let { identificacao } = { identificacao: {} };
    let garantia = null;
    let cliente = null;
    let valor = 0;
    let entrada = 0;
    let parcelas = null;
    let dtSimulacao = null;
    // let produtoSimulacao = SessionStore.getGenericData('produtoSimulacao') || null;
    // let { identificacao } = SessionStore.getGenericData('params') || { identificacao: {} };
    // let garantia = SessionStore.getGenericData('garantia') || null;
    // let cliente = SessionStore.getGenericData('clienteSimulacao') || null;
    // let valor = SessionStore.getGenericData('valorSelecionado') || 0;
    // let entrada = SessionStore.getGenericData('entradaSelecionado') || 0;
    // let parcelas = SessionStore.getGenericData('parcelasSelecionado') || null;
    // let dtSimulacao = SessionStore.getGenericData('dataSimulacao') || null;
    SessionStore.clearGenericData('garantia');
    SessionStore.clearGenericData('clienteSimulacao');
    SessionStore.clearGenericData('valorSelecionado');
    SessionStore.clearGenericData('entradaSelecionado');
    SessionStore.clearGenericData('parcelasSelecionado');

    // if (produtoSimulacao != this.props.match.params.acao || !dtSimulacao || dtSimulacao < new Date().getTime() - 1000 * 60 * 60) {
    //   dtSimulacao = new Date().getTime();
    //   garantia = null;
    //   cliente = null;
    //   valor = 0;
    //   entrada = 0;
    //   parcelas = null;
    //   SessionStore.clearGenericData('garantia');
    //   SessionStore.clearGenericData('clienteSimulacao');
    //   SessionStore.clearGenericData('valorSelecionado');
    //   SessionStore.clearGenericData('entradaSelecionado');
    //   SessionStore.clearGenericData('parcelasSelecionado');
    // }

    this.state = {
      loader: {},
      produtoSelecionado,
      produto: {},
      identificacao,
      garantia,
      cliente,
      valor,
      entrada,
      cdProduto: this.props.match.params,
      pagina: this.props.match.params,
      parcelas,
      stepSize: 100,
      vlBem: 0,
      etapa: '',
      opcoesValor: {
        vlMin: 0,
        vlMax: 0
      },
      opcoesEntrada: {
        vlMin: 0,
        vlMax: 0
      },
      opcoesParcelas: [],
      editarValor: false
    };
  }

  async componentDidMount() {
    try {

      this.setState({ loader: { message: 'Iniciando simulação...', show: true } });
      let cdProduto = this.props.match.params.acao;
      let { cdCliente } = this.state.identificacao;

      if (!cdProduto)
        return window.location.hash = `#/`;
      ReactGA.pageview(`/simular/${cdProduto}`, [], `Simular ${cdProduto}`);

      await this.recuperarProduto(cdProduto, cdCliente);
    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao iniciar a simulação.' });
      analyticsService.sendException(err, false);
    }
  }

  recuperarProduto = async (cdProduto, cdCliente) => {
    try {
      this.setState({ loader: { message: 'Consultando produto...', show: true } });
      let produto = await ProdutosService.recuperarProduto(cdProduto, cdCliente);
      SessionStore.saveGenericData('produtoSimulacao', cdProduto);
      this.setState({
        produto,
        paramsLoaded: true,
        loader: {}
      }, this.avancar);
    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao consultar dados do produto selecionado.' });
      analyticsService.sendException(err, false);
      return window.location.hash = `#/`;
    }
  }

  recuperarOpcoesValores = async () => {
    try {
      this.setState({ loader: { message: 'Consultando opções de valores...', show: true } });
      let produto = this.state.produto;
      let { idade, estado } = this.state.cliente;
      let { cdCliente } = this.state.cliente || {};
      let { vlAnoModelo: vlAnoBem, idVeiculo } = this.state.garantia || {};

      let opcoes = await ProdutosService.recuperarOpcoesValores(produto.cdProduto, cdCliente, vlAnoBem, idade, estado, idVeiculo);

      if (!opcoes || opcoes.err) {
        this.setState({ loader: {} });
        return false;
      }

      let stepSize = 100;
      let valor = parseInt((opcoes.vlRecomendado || opcoes.vlMax) / stepSize) * stepSize

      let vlMin = (parseInt((opcoes.vlMax * opcoes.vlPercentualEntradaMin) / stepSize) * stepSize) + stepSize;
      let vlMax = parseInt((opcoes.vlMax * opcoes.vlPercentualEntradaMax) / stepSize) * stepSize;

      this.setState({
        stepSize,
        vlBem: opcoes.vlBem,
        valor: valor,
        entrada: parseInt(produto.cdTipoEntrada === 'SEM_ENTRADA' ? 0 : vlMin),
        opcoesValor: {
          vlMin: opcoes.vlMin,
          vlMax: opcoes.vlMax
        },
        opcoesEntrada: {
          vlPercentualEntradaMin: opcoes.vlPercentualEntradaMin,
          vlPercentualEntradaMax: opcoes.vlPercentualEntradaMax,
          vlMin,
          vlMax
        }
      });

      SessionStore.saveGenericData('dataSimulacao', new Date().getTime());

      return true;
    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao consultar valores para simulação.' });
      analyticsService.sendException(err, false);
      return [];
    }
  }

  recuperarOpcoesParcelas = async () => {
    try {
      this.setState({ loader: { message: 'Consultando opções de parcelamento...', show: true } });
      let { cdProduto, cdTipoEntrada } = this.state.produto;
      let { idade, estado } = this.state.cliente;
      let { vlAnoModelo: vlAnoBem, idVeiculo } = this.state.garantia || {};
      let vlSolicitado = this.state.valor;
      let vlEntrada = cdTipoEntrada === 'SEM_ENTRADA' ? 0 : this.state.entrada;

      let opcoes = await ProdutosService.recuperarOpcoesParcelas(cdProduto, vlSolicitado, vlEntrada, vlAnoBem, idade, estado, idVeiculo);
      this.setState({
        opcoesParcelas: opcoes,
        loader: {}
      });

      SessionStore.saveGenericData('dataSimulacao', new Date().getTime());
    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao consultar as opções de parcelamento.' });
      analyticsService.sendException(err, false);
      return [];
    }
  }

  avancar = async () => {
    let etapa;

    // Verifica se precisa de garantia
    if (!this.state.garantia && this.state.produto.cdTipoGarantia === 'VEICULO') {
      etapa = 'IDENTIFICAR_VEICULO';
    }
    // Verifica se precisa identificar o cliente
    else if (!this.state.cliente) {
      etapa = 'IDENTIFICAR_CLIENTE';
    }

    // Verifica se precisa de valor
    else if (!this.state.valor || !this.state.parcelas || (!this.state.entrada && ['SEM_ENTRADA'].indexOf(this.state.produto.cdTipoEntrada) === -1)) {
      if (await this.recuperarOpcoesValores()) {
        await this.recuperarOpcoesParcelas();
        etapa = 'SELECIONAR_VALOR';
      } else {
        etapa = 'ERRO_POLITICA';
      }
    }

    else {
      etapa = 'RESUMO_SIMULACAO';
    }

    this.setState({ etapa });
  }

  handleVeiculoSelecionado = async (veiculo) => {
    SessionStore.saveGenericData('garantia', veiculo);
    this.setState({ garantia: veiculo }, this.avancar);
  }

  handleClienteIdentificado = async (cliente) => {
    SessionStore.saveGenericData('clienteSimulacao', cliente);
    this.setState({ cliente }, this.avancar);
  }

  handleValorSelecionado = async (valor) => {
    if (valor < this.state.opcoesValor.vlMin) {
      valor = this.state.opcoesValor.vlMin
      this.setState({ valor: valor, loader: {} })
    }
    else if (valor > this.state.opcoesValor.vlMax) {
      valor = this.state.opcoesValor.vlMax
      this.setState({ valor: valor, loader: {} })
    }

    let opcoesEntrada = this.state.opcoesEntrada;
    let entrada = this.state.entrada;

    let diferencaValor = valor - this.state.vlBem;
    diferencaValor = diferencaValor > 0 ? diferencaValor + this.state.stepSize : 0;
    diferencaValor = (parseInt(diferencaValor / this.state.stepSize) * this.state.stepSize);

    opcoesEntrada.vlMin = (valor * opcoesEntrada.vlPercentualEntradaMin) + diferencaValor;
    opcoesEntrada.vlMax = valor * opcoesEntrada.vlPercentualEntradaMax

    opcoesEntrada.vlMin = (parseInt(opcoesEntrada.vlMin / this.state.stepSize) * this.state.stepSize) + this.state.stepSize;
    opcoesEntrada.vlMax = parseInt(opcoesEntrada.vlMax / this.state.stepSize) * this.state.stepSize;

    if (this.state.produto.cdTipoEntrada === 'SEM_ENTRADA') {
      entrada = 0;
    } else {
      if (entrada > opcoesEntrada.vlMax) entrada = opcoesEntrada.vlMax;
      if (entrada < opcoesEntrada.vlMin) entrada = opcoesEntrada.vlMin;
    }

    this.setState({ valor, opcoesEntrada, entrada });
  }

  handleEntradaSelecionado = async (entrada) => {
    if (this.state.produto.cdTipoEntrada === 'SEM_ENTRADA') {
      entrada = 0;
      this.setState({ entrada });
    }
    else if (entrada < this.state.opcoesEntrada.vlMin) {
      entrada = this.state.opcoesEntrada.vlMin
      this.setState({ entrada });
    }
    else if (entrada > this.state.opcoesEntrada.vlMax) {
      entrada = this.state.opcoesEntrada.vlMax
      this.setState({ entrada });
    } else {
      this.setState({ entrada });
    }
  }

  handleParcelasSelecionado = async (index) => {
    SessionStore.saveGenericData('parcelasSelecionado', this.state.opcoesParcelas[index]);
    SessionStore.saveGenericData('valorSelecionado', this.state.valor);
    SessionStore.saveGenericData('entradaSelecionado', this.state.entrada);
    this.setState({ parcelas: this.state.opcoesParcelas[index] }, this.avancar);
  }

  handleConfirmar = () => {
    SessionStore.saveGenericData('produtoSelecionado', this.state.produto);
    SessionStore.saveGenericData('dataSimulacao', new Date().getTime());
    SessionStore.saveGenericData('redirecionamentoAutenticacao', '#/produtos/veiculos/confirmar')
    window.location.hash = `/clientes/auth`;
  }

  handleCancelar = () => {
    SessionStore.clearGenericData('valorSelecionado');
    SessionStore.clearGenericData('entradaSelecionado');
    SessionStore.clearGenericData('parcelasSelecionado');

    let valor = this.state.produto.vlLimite || this.state.produto.vlMax;
    let entrada = this.state.produto.cdTipoEntrada === 'SEM_ENTRADA' ? 0 : valor * 0.3;

    this.setState({
      valor,
      entrada,
      parcelas: null,
      etapa: ''
    }, this.avancar);
  }

  voltarAoInicio = () => {
    window.location = '#/'
  }

  alterarGarantia = () => {
    SessionStore.clearGenericData('garantia');
    this.setState({ garantia: null }, this.avancar);
  }

  alterarMeusDados = () => {
    SessionStore.clearGenericData('clienteSimulacao');
    this.setState({ cliente: null }, this.avancar);
  }

  calcularParcelas = () => {
    if (calcularParcelaTimeout)
      clearTimeout(calcularParcelaTimeout);

    calcularParcelaTimeout = setTimeout(async () => {
      await this.recuperarOpcoesParcelas();
    }, 200)
  }


  handleInputChangeEntrada = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value }, () => { this.handleEntradaSelecionado(this.state.entrada) });
  }
  handleEditarValor = () => {
    this.setState({ editarValor: true })

  }
  render() {
    return <Fragment>
      {/*    {!this.state.pagina.cdLandingPage ? <Navbar /> : null} */}
      <div className="container-fluid">
        <div className="row justify-content-center" >
          {this.state.produtoSelecionado ? <div className="col-12">
            <div className="row justify-content-center py-2 mb-2" style={{ borderBottom: '1px solid #038cc9', background: '#efefef' }}>
              <img alt="Daycoval" style={{ width: '30px', height: '30px', display: 'inline-block' }} src={environment.get('ASSETS') + `/img/${this.state.produtoSelecionado.cdProduto}.svg`} />
              <h5 className="m-0 align-middle pt-1 pl-2" style={{ display: 'inline-block' }}>{this.state.produtoSelecionado.dsDestaque}</h5>
            </div>
          </div> : null}
          {this.state.pagina.cdLandingPage ? <Fragment>

            <div className='d-none d-sm-block col-12 col-sm-4 col-md-4 col-lg-7 ' style={{
              backgroundImage: 'url("/tarion/img/mercadolivre1.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', width: '100%',
              height: '100vh'
            }}>
            </div>

            <img alt="Daycoval" className='d-block d-sm-none' src='/tarion/img/mercadolivre2.png' style={{ backgroundColor: '#F5F5F7	', width: '100%', height: '30%' }}></img>
          </Fragment> : null}

          <Msgs info={this.state.msg} error={this.state.error} />

          {this.state.etapa === 'ERRO_POLITICA' ? <Fragment>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 p-2">
              <h5 className="text-center">Infelizmente não temos opções de crédito disponíveis para você.</h5>
              <div className="col-12">
                <button className="btn btn-block btn-secondary" onClick={this.voltarAoInicio}>Voltar ao início</button>
              </div>
            </div>
          </Fragment> : null}

          {this.state.paramsLoaded && !this.state.error ? <Fragment>
            {!this.state.loader.show && this.state.etapa === 'IDENTIFICAR_VEICULO' ?
              <SelecionarVeiculo
                cdLandingPage={this.state.pagina.cdLandingPage}
                onVeiculoSelecionado={this.handleVeiculoSelecionado} />
              : null}

            {!this.state.loader.show && this.state.etapa === 'IDENTIFICAR_CLIENTE' ?
              <IdentificarCliente
                cdLandingPage={this.state.pagina.cdLandingPage}
                onClienteIdentificado={this.handleClienteIdentificado} />
              : null}

            {!this.state.loader.show && this.state.etapa === 'SELECIONAR_VALOR' ?
              <div className="col-12 col-sm-6 col-md-6 pt-4 col-lg-5">
                <div className="row justify-content-center p-2">
                  <h5 className="text-center">Simule com as melhores condições:</h5>
                  <div className="col-12  p-2 mb-3" style={boxStyle}>
                    {this.state.editarValor === false ?
                      <h4 className="text-center " style={{ color: '#0373b0' }}><b>{Utils.formatarMoedaV2('R$', this.state.valor)}</b>
                        <div className="float-right">
                          <i onClick={this.handleEditarValor} className="material-icons" style={{ fontSize: 20 }}>edit</i>
                        </div>
                      </h4> :
                      <CampoMoeda
                        className="form-control text-center"
                        id={this.props.id}
                        value={this.state.valor}
                        placeholder={this.props.placeholder || ''}
                        onChange={(valor) => {
                          this.setState({
                            valor: valor,
                            opcoesParcelas: [],
                            err: null
                          }, () => {
                            this.handleValorSelecionado(valor);
                          });
                        }}
                        onBlur={this.calcularParcelas} />
                    }
                    {this.state.editarValor === false ?
                      <Slider
                        value={this.state.valor}
                        onChange={this.handleValorSelecionado}
                        onAfterChange={this.calcularParcelas}
                        railStyle={railStyle}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        step={this.state.stepSize}
                        min={this.state.opcoesValor.vlMin}
                        max={this.state.opcoesValor.vlMax}
                      />
                      : null}
                    {this.state.produto.cdTipoValor === 'GARANTIA' && this.state.produto.cdTipoEntrada === 'LIMITE' ?
                      <h6 className="text-center pt-2">Valor de compra do veículo</h6> :
                      <h6 className="text-center pt-2">Valor para empréstimo</h6>}
                    {this.state.editarValor === false ? null : <Fragment>
                      <h6 className="text-center"><small style={{ color: '#0373b0' }}>Mín: {Utils.formatarMoedaV2('R$', this.state.opcoesValor.vlMin)} </small>
                        <small style={{ color: '#519839', paddingLeft: 4 }}> Max: {Utils.formatarMoedaV2('R$', this.state.opcoesValor.vlMax)} </small></h6>
                    </Fragment>}
                  </div>

                  {this.state.produto.cdTipoEntrada !== 'SEM_ENTRADA' ?
                    <div className="col-12 p-2" style={boxStyle}>
                      {this.state.editarValor === false ? <h4 className="text-center" style={{ color: '#0373b0' }}><b>{Utils.formatarMoedaV2('R$', this.state.entrada)} </b></h4> :
                        <CampoMoeda
                          className="form-control text-center"
                          id={this.props.id}
                          value={this.state.entrada}
                          placeholder={this.props.placeholder || ''}
                          onChange={(valor) => {
                            this.setState({
                              entrada: valor,
                              opcoesParcelas: [],
                              err: null
                            }, () => {
                              this.handleEntradaSelecionado(valor);
                            });
                          }}
                          onBlur={this.calcularParcelas} />
                      }
                      {this.state.editarValor === false ? <Slider
                        value={this.state.entrada}
                        onChange={this.handleEntradaSelecionado}
                        onAfterChange={this.calcularParcelas}
                        railStyle={railStyle}
                        trackStyle={trackStyle}
                        handleStyle={handleStyle}
                        step={this.state.stepSize}
                        min={this.state.opcoesEntrada.vlMin}
                        max={this.state.opcoesEntrada.vlMax} /> : null}

                      <h6 className="text-center pt-2">Valor da entrada</h6>
                      {this.state.editarValor === false ? null : <Fragment>
                        <h6 className="text-center"><small style={{ color: '#0373b0' }}>Mín: {Utils.formatarMoedaV2('R$', this.state.opcoesEntrada.vlMin)} </small>
                          <small style={{ color: '#519839', paddingLeft: 4 }}> Max: {Utils.formatarMoedaV2('R$', this.state.opcoesEntrada.vlMax)} </small></h6>
                      </Fragment>}  </div> : null}


                </div>

                {!this.state.loader.show && this.state.opcoesParcelas && this.state.opcoesParcelas.length > 0 ?
                  <div className="row justify-content-center p-2">
                    <div className="col-12 py-2 px-3 mb-2" style={boxStyle}>
                      <h6 className="text-center pt-2" >Selecione a melhor opção para você!</h6>
                      <SelecionarValor
                        opcoesValores={this.state.opcoesParcelas ? this.state.opcoesParcelas.map(v =>
                          `<span style="display: block;">${v.qtParcelas}x ${Utils.formatarMoeda('R$', v.vlParcela)}</span>
                        <span style="display: block; font-size: 12px">Tx. juros: ${Utils.formatarMoeda('', v.vlTaxa)}% a.m.</span>
                        <span style="display: block; font-size: 12px">Renda min: ${Utils.formatarMoeda('R$', v.vlRendaMinimaRecomendada)}</span>`
                        ) : []}
                        onClickOpcao={this.handleParcelasSelecionado} />
                    </div>
                  </div> : null}

                {this.state.etapa === 'SELECIONAR_VALOR' ? <p className="text-center m-0 pb-2" style={{ fontSize: '12px' }}>As condições aqui apresentadas estão sujeitas à análise documental e de crédito, não constituindo, portanto, garantia da aprovação desta proposta.</p> : null}
                {this.state.etapa === 'SELECIONAR_VALOR' ? <p className="text-center m-0 pb-2" style={{ fontSize: '12px' }}>Além disso, os valores ora informados poderão sofrer pequenas variações, já que serão recalculados no momento da análise e aprovação do crédito</p> : null}

                {this.state.garantia ? <div className="row justify-content-center p-2">
                  <div className="col-12 m-0 p-0">
                    <p className="text-center mb-0" style={{ fontSize: '13px' }}>Garantia informada:</p>
                    <p className="text-center mb-0 pb-1" style={{ fontSize: '13px' }}>{this.state.garantia.dsTrim}</p>
                    <button className="btn btn-block btn-secondary" onClick={this.alterarGarantia}>Alterar garantia</button>
                    <button className="btn btn-block btn-secondary" onClick={this.alterarMeusDados}>Alterar meus dados</button>
                  </div>
                </div> : null}

              </div> : null}

            {this.state.etapa === 'RESUMO_SIMULACAO' ?
              <ResumoSimulacao
                garantia={this.state.garantia}
                valor={this.state.valor}
                entrada={this.state.entrada}
                parcelas={this.state.parcelas}
                produto={this.state.produto}
                identificacao={this.state.identificacao}
                onClickConfirmar={this.handleConfirmar}
                onClickCancelar={this.handleCancelar}
                cdLandingPage={this.state.pagina.cdLandingPage} />
              : null}

          </Fragment> : null}

          <Loader message={this.state.loader.message} show={this.state.loader.show} />
        </div>
      </div>
    </Fragment>
  }
}

export default SimularPage;
import React, { Component } from 'react';
import Environment from '../environment';

class BtnWhatsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkWA: `https://wa.me/${props.numero}?text=${props.textoMensagem || 'Olá, preciso de ajuda.'}`
    }
  }

  render() {
    return <div className="col-12 animated fadeIn p-0">
      <a href={this.state.linkWA} target="_blank" rel="noopener noreferrer">
        <h6 className="text-center m-0 p-2" style={{ background: '#0373b0', color: '#fff', borderRadius: '15px', height: '58px', width: 'auto' }}>
          {this.props.textoBotao || <span>Atendimento<br />WhatsApp</span>}
          <img alt="Daycoval" src={Environment.get('ASSETS') + '/img/wp.svg'} style={{ height: '20px', paddingLeft: '3px' }}></img>
        </h6>
      </a>
    </div>
  }
}

export default BtnWhatsApp;
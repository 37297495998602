import ApiService from './api.service';

class RenegService {
  
  async listarCarenciasContrato(cdContrato) {
    return await ApiService.get(`cobrancas/listar-carencias-contrato?cdContrato=${cdContrato}`);
  }
 
  async calcularOfertasReneg(cdContrato, dtPagamento, qtDiasCarencia) {
    return await ApiService.get(`cobrancas/calcular-ofertas-reneg?cdContrato=${cdContrato || ''}&dtPagamento=${dtPagamento || ''}&qtDiasCarencia=${qtDiasCarencia || ''}`);
  }

  async listarProximasDatasVencimento(qtDias) {
    return await ApiService.get(`cobrancas/listar-proximas-datas-vencimento?qtDias=${qtDias || ''}`);
  }

  async confirmarOfertaReneg(oferta) {
    return await ApiService.post(`cobrancas/confirmar-oferta-reneg`,oferta)
  }
  async recuperarLinkDownloadContratoReneg(cdReneg) {
    return await ApiService.get(`cobrancas/recuperar-link-download-contrato-reneg?cdReneg=${cdReneg}`);
  }
}

export default new RenegService();
import React, { Component, Fragment } from 'react';


import Utils from '../../../../utils/text';

class ResumoSimulacao extends Component {
  render() {
    return <Fragment>
      {this.props.cdLandingPage ?
        <div className="col-12 col-sm-6 col-md-6 col-lg-5 pt-5 ">
          <div className="row justify-content-center py-2 ">
            {this.props.identificacao && this.props.identificacao.nome ?
              <h5 className="text-center">{this.props.identificacao.nome}, verifique se está tudo correto antes de prosseguir com a solicitação:</h5>
              : <h5 className="text-center">Verifique se está tudo correto antes de prosseguir com a solicitação:</h5>
            }

            <div className="col-12 py-2 px-0">
              <table className="table">
                <tbody>
                  {this.props.valor ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">
                      {this.props.produto.cdTipoValor === 'GARANTIA' && this.props.produto.cdTipoEntrada === 'LIMITE' ?
                        <h6 className="text-center pt-2">Valor de compra do veículo</h6> :
                        <h6 className="text-center pt-2">Valor solicitado para empréstimo</h6>}
                    </td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoedaV2('R$', this.props.valor)}</td>
                  </tr>
                    : null}

                  {this.props.entrada ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Valor da entrada</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoedaV2('R$', this.props.entrada)}</td>
                  </tr>
                    : null}

                  {this.props.valor && this.props.produto.cdTipoValor === 'GARANTIA' && this.props.produto.cdTipoEntrada === 'LIMITE' ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">
                      <h6 className="text-center pt-2">Valor que será pago ao vendedor</h6>
                    </td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoedaV2('R$', this.props.valor - this.props.entrada)}</td>
                  </tr>
                    : null}

                  {this.props.parcelas && this.props.parcelas.qtParcelas ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Parcelas</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{this.props.parcelas.qtParcelas}x {Utils.formatarMoeda('R$', this.props.parcelas.vlParcela)}</td>
                  </tr>
                    : null}

                  {this.props.parcelas && this.props.parcelas.vlTaxa ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Taxa juros</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('', this.props.parcelas.vlTaxa)}% a.m.</td>
                  </tr>
                    : null}

                  {this.props.parcelas && this.props.parcelas.vlRendaMinimaRecomendada ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Renda necessária</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.parcelas.vlRendaMinimaRecomendada)}</td>
                  </tr>
                    : null}

                  {this.props.garantia ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Garantia</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{this.props.produto.cdTipoGarantia === 'VEICULO' ? `${this.props.garantia.dsTrim} ${this.props.garantia.vlAnoModelo}` : ''}</td>
                  </tr>
                    : null}
                </tbody>
              </table>

              <p className="text-center m-0 p-2" style={{ fontSize: '12px' }}>As condições aqui apresentadas estão sujeitas à análise documental e de crédito, não constituindo, portanto, garantia da aprovação desta proposta.</p>
              <p className="text-center m-0 p-2" style={{ fontSize: '12px' }}>Além disso, os valores ora informados poderão sofrer pequenas variações, já que serão recalculados no momento da análise e aprovação do crédito</p>
            </div>

            <div className="col-12 py-2">
              <button className="btn btn-block btn-primary"
                style={{ paddingBottom: '10px', paddingTop: '10px'}}
                onClick={this.props.onClickConfirmar}
              >Prosseguir com estes valores</button>
            </div>
            <div className="col-12 py-2">
              <button className="btn btn-block btn-secondary"
                style={{ paddingBottom: '10px', paddingTop: '10px' }}
                onClick={this.props.onClickCancelar}
              >Quero alterar</button>
            </div>
          </div>
        </div>
        :
        <div className="col-12 col-sm-6 col-md-6 col-lg-5">
          <div className="row justify-content-center py-2">

            {this.props.identificacao && this.props.identificacao.nome ?
              <h5 className="text-center">{this.props.identificacao.nome}, verifique se está tudo correto antes de prosseguir com a solicitação:</h5>
              : <h5 className="text-center">Verifique se está tudo correto antes de prosseguir com a solicitação:</h5>
            }
            <div className="col-12 py-2 px-0">
              <table className="table">
                <tbody>
                  {this.props.valor ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">
                      {this.props.produto.cdTipoValor === 'GARANTIA' && this.props.produto.cdTipoEntrada === 'LIMITE' ?
                        <h6 className="text-center pt-2">Valor de compra do veículo</h6> :
                        <h6 className="text-center pt-2">Valor solicitado para empréstimo</h6>}
                    </td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoedaV2('R$', this.props.valor)}</td>
                  </tr>
                    : null}

                  {this.props.entrada ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Valor da entrada</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoedaV2('R$', this.props.entrada)}</td>
                  </tr>
                    : null}

                  {this.props.valor && this.props.produto.cdTipoValor === 'GARANTIA' && this.props.produto.cdTipoEntrada === 'LIMITE' ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">
                      <h6 className="text-center pt-2">Valor que será pago ao vendedor</h6>
                    </td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoedaV2('R$', this.props.valor - this.props.entrada)}</td>
                  </tr>
                    : null}

                  {this.props.parcelas && this.props.parcelas.qtParcelas ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Parcelas</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{this.props.parcelas.qtParcelas}x {Utils.formatarMoeda('R$', this.props.parcelas.vlParcela)}</td>
                  </tr>
                    : null}

                  {this.props.parcelas && this.props.parcelas.vlTaxa ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Taxa juros</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('', this.props.parcelas.vlTaxa)}% a.m.</td>
                  </tr>
                    : null}

                  {this.props.parcelas && this.props.parcelas.vlRendaMinimaRecomendada ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Renda necessária</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.parcelas.vlRendaMinimaRecomendada)}</td>
                  </tr>
                    : null}

                  {this.props.garantia ? <tr style={{ height: 50 }}>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Garantia</td>
                    <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{this.props.produto.cdTipoGarantia === 'VEICULO' ? `${this.props.garantia.dsTrim} ${this.props.garantia.vlAnoModelo}` : ''}</td>
                  </tr>
                    : null}
                </tbody>
              </table>

              <p className="text-center m-0 p-2" style={{ fontSize: '12px' }}>As condições aqui apresentadas estão sujeitas à análise documental e de crédito, não constituindo, portanto, garantia da aprovação desta proposta.</p>
              <p className="text-center m-0 p-2" style={{ fontSize: '12px' }}>Além disso, os valores ora informados poderão sofrer pequenas variações, já que serão recalculados no momento da análise e aprovação do crédito</p>
            </div>

            <div className="col-12 py-2">
              <button className="btn btn-block btn-primary"
                style={{ paddingBottom: '10px', paddingTop: '10px'}}
                onClick={this.props.onClickConfirmar}
              >Prosseguir com estes valores</button>
            </div>
            <div className="col-12 py-2">
              <button className="btn btn-block btn-secondary"
                style={{ paddingBottom: '10px', paddingTop: '10px' }}
                onClick={this.props.onClickCancelar}
              >Quero alterar</button>
            </div>
          </div>
        </div>
      }

    </Fragment>
  }
}

export default ResumoSimulacao;
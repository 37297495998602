import React, { Component } from 'react';
import Camera from '../camera'
import BtnActions from './btn-actions';


class ModalCamera extends Component {


  handleVoltar = () => {
    if (this.props.onEvento)
      this.props.onEvento('VOLTAR');
  }

  handleGetTakePhoto = async (img64) => {
    let result = await this.props.onEvento(this.props.componente.props.cdEventoConfirmarFoto, {img64});
    return result;
  }
  handleCameraError = async (error) => {
    let result = await this.props.onEvento(this.props.componente.props.cdEventoPermissaoCameraInvalida, { error });
    return result;
  }

  render() {
    return <div className="modal" aria-hidden="false" style={{ backgroundColor: '#0000002f', display: this.props.input.flTirarFoto ? 'block' : 'none' }}>
      <Camera
        onClickVoltar={this.handleVoltar}
        onTakePhoto={this.handleGetTakePhoto}
        onCameraError={this.handleCameraError}
      />
    </ div>

  }
}

export default ModalCamera;
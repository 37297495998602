import React, { Component } from 'react';

class Page extends Component {
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || false;
    const name = target.name;
    this.setState({ [name]: value });
  }

  render() {
    return <h1>Página não implementada</h1>
  }
}

export default Page;
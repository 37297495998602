import React, { Component, Fragment } from 'react';

import AnalyticsService from '../services/analytics.service';

import Msgs from '../components/msgs';
import Navbar from '../components/navbar';
import Intro from '../components/intro';
import Footer from '../components/footer';
import AbaInicial from '../components/aba-inicial';
import Aviso from '../components/aviso';
import sessionStoreService from '../services/session-store.service';
import BannerCampanha from '../components/banner-campanha';

const ABAS_INICIAIS = {
  'localhost': 'SERVICOS',
  'homolog.contrato.srv.br': 'SERVICOS',
  'daycoval.contrato.srv.br': 'SERVICOS',
  'homolog.facildepagar.com.br': 'SERVICOS',
  'daycoval.facildepagar.com.br': 'SERVICOS',
  'homolog.facildefinanciar.com.br': 'PRODUTOS',
  'daycoval.facildefinanciar.com.br': 'PRODUTOS',
};

class InicioPage extends Component {

  constructor(props) {
    super(props);

    AnalyticsService.sendPageView('inicio', 'Início');

    // Recuperamos o código de origem armazenada.
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    let dtOrigem = sessionStoreService.getGenericData('dtOrigem');

    // Verificamos se foi informado uma nova origem.
    if (props.match.params.cdOrigem) {
      cdOrigem = props.match.params.cdOrigem;
      dtOrigem = new Date().getTime();
      this.cdOrigemURL = cdOrigem;
    }

    // Caso a origem armazenada seja anterior a 48 horas, limpamos o codigo de origem.
    if (!dtOrigem || dtOrigem <= new Date().getTime() - 1000 * 60 * 60 * 48) {
      cdOrigem = undefined;
      dtOrigem = undefined;
    }

    if (cdOrigem && cdOrigem != 'undefined') {
      sessionStoreService.saveGenericData('cdOrigem', cdOrigem);
      sessionStoreService.saveGenericData('dtOrigem', dtOrigem);
    } else {
      cdOrigem = undefined;
      sessionStoreService.clearGenericData('cdOrigem');
      sessionStoreService.clearGenericData('dtOrigem');
    }

    this.cdOrigem = cdOrigem;

    console.log('Origem: ', cdOrigem);
 

    this.state = {
      exibirAviso: true,
      aba: ABAS_INICIAIS[window.location.hostname] || 'PRODUTOS'
      // aba: 'PRODUTOS'
    };
  }

  componentDidMount() {
    if (this.cdOrigemURL) {
      if (['MUTIRAO_FEBRABAN'].includes(this.cdOrigemURL)) {
        sessionStoreService.saveGenericData('redirecionamentoAutenticacao', '#/servicos/contratos');
        window.location.hash = '#/clientes/auth';
      }
      if (['OLX', 'MERCADOLIVRE'].includes(this.cdOrigemURL)) {
        window.location.hash = '#/clientes/auth';
      }
    }
  }

  handleClickAbaTela = async (tela) => {
    this.setState({ aba: tela })
  }

  handleFecharAviso = () => [
    this.setState({ exibirAviso: false })
  ]

  render() {
    return <Fragment>
      <Aviso show={this.state.exibirAviso && this.state.aba === 'SERVICOS'} onClickOk={this.handleFecharAviso} />
      <Navbar aba={this.state.aba} />
      <BannerCampanha campanha={this.cdOrigem} />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <AbaInicial abaAtiva={this.state.aba} onClickAba={this.handleClickAbaTela} />
            <Intro aba={this.state.aba} />
          </div>
          <div className="col-12 px-0">
            <Footer />
          </div>
          <Msgs info={this.state.msg} error={this.state.error} />
        </div>
      </div>
    </Fragment>
  }
}

export default InicioPage;
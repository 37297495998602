import React, { Fragment } from "react";
import moment from 'moment';
import Page from '../../page';
import Table from "../../../components/table";
import AbaProposta from '../../../components/aba-proposta';
import ViewPagamento from '../../../components/formularios/servicos/view-pagamento';
import ViewAgradecimento from '../../../components/formularios/servicos/view-agradecimento';
import ViewNegociacaoEfetuada from '../../../components/formularios/servicos/view-negociacao-efetuada';
import FormCancelarNegociacao from '../../../components/formularios/servicos/form-cancelar-negociacao';
import ContratosService from "../../../services/contratos.service";
import Msgs from '../../../components/msgs';
import Loader from '../../../components/loader';
import analyticsService from "../../../services/analytics.service";

class CobrancaContrato extends Page {
  constructor(props) {
    super(props);

    analyticsService.sendPageView('servicos/contratos/cobranca', 'Serviços - Contratos - Cobrança');

    this.state = {
      loader: false,
      error: undefined,
      cdContrato: props.match.params.cdContrato,
      contrato: {},
      parcelas: {},
      cliente: {},
      negociacao: {},
      ofertas: {},
      pagamento: {},
      pagamentoExibicao: {},
      dtPagamento: undefined,
      datasPagamento: [],
      aba: undefined,
      telaAtual: 'TELA_INICIAL',
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    this.setState({ loader: { message: 'Consultando contrato...', show: true } });
    try {
      let datasPagamento = await ContratosService.listarProximasDatasVencimento();
      let { contrato, cliente, ofertas, negociacao } = await ContratosService.recuperarPorContrato(this.state.cdContrato);

      this.setState({ contrato, cliente, ofertas, negociacao, datasPagamento, dtPagamento: datasPagamento[0] }, async () => {
        if ([0, 1].indexOf(contrato.cdStatus) > -1) {
          await this.handleCalcularPagamento();
          this.setState({ telaAtual: 'NEGOCIAR' });
        } else {
          this.setState({ loader: {} });
        }
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar o contrato. Tente novamente ou contate nosso suporte.' });
      this.setState({ loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  handleCalcularPagamento = async () => {
    this.setState({ loader: { message: 'Calculando...', show: true } });
    try {
      let pagamentoExibicao = await ContratosService.calcularPagamento(
        this.state.contrato.cdContrato,
        this.state.dtPagamento,
        'EXIBICAO'
      );
      let pagamento = await ContratosService.calcularPagamento(
        this.state.contrato.cdContrato,
        this.state.dtPagamento,
        undefined,
        this.state.qtParcelas
      );
      this.setState({ pagamento, pagamentoExibicao });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar a cobrança. Tente novamente ou contate nosso suporte.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  handleConfirmarNegociacao = async () => {
    analyticsService.sendEvent('confirmou_negociacao_divida');
    this.setState({ loader: { message: 'Efetuando negociação...', show: true }, error: null });
    try {
      let negociacao = await ContratosService.confirmarNegociacao(this.state.contrato.cdContrato, this.state.dtPagamento, undefined, this.state.qtParcelas);
      if (!negociacao) {
        this.setState({ error: 'Ocorreu algum erro ao confirmar sua negocição. Tente novamente.' });
        this.loadData();
      } else {
        this.setState({ negociacao, tentativasConsulta: 0 }, () => {
          this.handleRecuperarNegociacaoEfetuada();
        })
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao confirmar negociação. Tente novamente ou contate nosso suporte.' });
      setTimeout(this.loadData, 2000);
      analyticsService.sendException(err, false);
    }
  }

  handleRecuperarNegociacaoEfetuada = async () => {
    this.setState({
      loader: {
        message:
          this.state.tentativasConsulta < 2 ? 'Consultando negociação...'
            : this.state.tentativasConsulta < 4 ? 'Aguardando registro...'
              : 'Gerando boleto...',
        show: true
      }, error: null
    });
    try {
      let negociacao = await ContratosService.recuperarNegociacao(this.state.negociacao.cdNegociacao);
      if (!negociacao) {
        this.setState({ error: 'Ocorreu algum erro ao confirmar sua negocição, por favor entre em contato com o Banco Daycoval' });
        this.loadData();
      } else {
        if (negociacao.cdStatus !== 1 && this.state.tentativasConsulta < 6) {
          setTimeout(() => {
            this.setState({ tentativasConsulta: this.state.tentativasConsulta + 1 })
            this.handleRecuperarNegociacaoEfetuada();
          }, 6000);
        } else {
          await this.loadData();
          this.setState({ loader: {}, telaAtual: 'AGRADECIMENTO' });
        }
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao confirmar negociação. Tente novamente ou contate nosso suporte.' });
      this.setState({ loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  handleCancelarNegociacao = async (dsMotivoCancelamento) => {
    analyticsService.sendEvent('cancelou_negociacao_divida');
    this.setState({ loader: { message: 'Carregando aplicação...', show: true }, error: null });
    try {
      if (!dsMotivoCancelamento) {
        return this.setState({ loader: { show: false }, error: 'Por favor, informe o motivo do cancelamento', show: false })
      }
      await ContratosService.cancelarNegociacao(this.state.negociacao.cdNegociacao, dsMotivoCancelamento);
      this.setState({ telaAtual: 'TELA_INICIAL' }, () => {
        this.loadData()
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao salva os dados. Tente novamente ou contate nosso suporte.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: false });
  }

  handleReneg = async (cdContrato) => {
    let acao = 'renegociacao'
    window.location.hash = `servicos/reneg/${acao}/${encodeURIComponent(cdContrato)}`;
  }

  handleTrocaTela = async (tela) => {
    this.setState({ telaAtual: tela });
  }

  handleDataSelecionada = async (dataPagamento) => {
    analyticsService.sendEvent('escolheu_data_pagamento_atraso', { dias: moment().diff(moment(dataPagamento), 'days', false) });
    try {
      this.setState({ dtPagamento: dataPagamento }, () => {
        this.handleCalcularPagamento();
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao listar as datas. Tente novamente ou contate nosso suporte.' });
      analyticsService.sendException(err, false);
    }
  }

  handleQuantidadeParcelasSelecionada = async (qtParcelas) => {
    analyticsService.sendEvent('escolheu_quantidade_parcelas_atraso', { qtParcelas });
    try {
      this.setState({ qtParcelas }, () => {
        this.handleCalcularPagamento()
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao listar as datas. Tente novamente ou contate nosso suporte.' });
      analyticsService.sendException(err, false);
    }
  }

  handleVoltar = () => {
    window.location.hash = `servicos/contratos/${encodeURIComponent(this.state.cdContrato)}`;
  }

  render() {
    return <Fragment>
      {this.state.contrato ? <AbaProposta
        key={'Contrato'}/*  icon={aba.icon}  */
        label={`Contrato ${this.state.contrato.cdContrato}`}
        active={true} /> : null}

      <div className="container-flex">
        <Loader message={this.state.loader.message} show={this.state.loader.show} />
        <Msgs info={this.state.msg} error={this.state.error} />

        {this.state.telaAtual === 'TELA_INICIAL' ? <Fragment>
          {this.state.contrato && this.state.contrato.cdContrato ? <Fragment>
            <Table rows={ContratosService.mapContratosToTable([this.state.contrato], [])} />

            <div className="container-fluid pt-3">
              {[2, 3, 4, 5, 6].indexOf(this.state.contrato.cdStatus) > -1 && this.state.negociacao ? <ViewNegociacaoEfetuada
                negociacao={this.state.negociacao}
                onClickCancelarNegociacao={() => this.handleTrocaTela('CANCELAR_NEGOCIACAO')} /> : null}

              {[0, 1].indexOf(this.state.contrato.cdStatus) > -1 && !this.state.negociacao ? <button type="button" className="btn btn-block btn-success mb-2 mt-3">Pagar parcelas em atraso</button> : null}

              <button type="button" className="btn btn-block btn-secondary mb-2" onClick={this.handleVoltar}>Voltar</button>
            </div>
          </Fragment> : null}
        </Fragment> : null}

        {this.state.telaAtual === 'CANCELAR_NEGOCIACAO' ? <FormCancelarNegociacao
          negociacao={this.state.negociacao}
          onClickCancelarNegociacao={this.handleCancelarNegociacao}
          onClickVoltar={() => this.handleVoltar('TELA_INICIAL')} /> : null}

        {this.state.telaAtual === 'NEGOCIAR' ? <ViewPagamento
          contrato={this.state.contrato}
          datasPagamento={this.state.datasPagamento}
          pagamento={this.state.pagamento}
          pagamentoExibicao={this.state.pagamentoExibicao}
          dtPagamento={this.state.dtPagamento}
          ofertas={this.state.ofertas}
          onClickDataSelecionada={this.handleDataSelecionada}
          onClickQuantidadeParcelasSelecionada={this.handleQuantidadeParcelasSelecionada}
          onClickVoltar={this.handleVoltar}
          onClickConfirmarNegociacao={this.handleConfirmarNegociacao}
          onClickReneg={this.handleReneg}
        /> : null}

        {this.state.telaAtual === 'AGRADECIMENTO' ? <ViewAgradecimento
          dsLinkBoleto={this.state.negociacao && this.state.negociacao.boletos && this.state.negociacao.boletos[0] ? this.state.negociacao.boletos[0].dsLinkBoleto : null}
          onClickVoltar={this.handleVoltar}
        /> : null}

      </div>
    </Fragment>
  }
}
export default CobrancaContrato;
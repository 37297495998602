import analyticsService from "../services/analytics.service";

class TextUtils {
  getFirstNameWithUpperCase(nome) {
    if (!nome) return "";
    nome = nome.split(" ");
    nome = this.firstToUpperCase(nome[0]);
    return nome;
  }

  firstToUpperCase(str) {
    if (!str) return "";

    str = str
      .toLowerCase()
      .replace(/[-_]+/g, " ")
      .replace(/[^\w\s]/g, "");

    str = str.split("");

    if (!str[0]) return "";
    str[0] = str[0].toUpperCase();
    str = str.join("");

    return str;
  }

  formatarCpf(texto) {
    if (!texto) return "000.000.000-00";
    while (texto.length < 11) texto = texto + "0";
    if (texto.length > 11) texto = texto.substring(texto.length - 11);
    return texto.substring(0, 3) + "." + texto.substring(3, 6) + "." + texto.substring(6, 9) + "-" + texto.substring(9);
  }

  gerarAleatorio(length = 16) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  copyTextToClipboard(text) {
    return new Promise((resolve, reject) => {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        resolve();
      } catch (err) {
        analyticsService.sendException(err, false);
        reject();
      }

      document.body.removeChild(textArea);
    });
  }

  formatarMoeda = (simbolo, valor) => {
    valor = parseInt(valor * 100);

    let inteiro = parseInt(valor / 100);
    let decimal = valor % 100;
    if (decimal < 10) decimal = "0" + decimal;

    let formatado = this.formatarMoedaV2(simbolo, inteiro) + "," + decimal;

    return formatado;
  }

  formatarMoedaV2 = (simbolo, valor = '') => {
    valor = String(valor).replace(/[^\d]+/g, "");
    if (isNaN(valor) || !valor)
      valor = 0;
    valor = parseInt(valor);
    let formatado = valor + "";
    formatado = formatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return simbolo + formatado;
  }


  retornarNumerosSemEspaco = (text) => {
    if (!text) return;
    return text.replace(/\D/g, '');
  }

  validarChassi = (chassi) => {

    let isInvalidChassi = chassi.match("[iIoOqQ]");
    let ultimosDigitos = chassi.match("[0-9]{6}$");
    let repeticao = chassi.match("^.{4,}([0-9A-Z])\\1{5,}");
    if ((!ultimosDigitos || ultimosDigitos.length === 0) || (isInvalidChassi && isInvalidChassi.length > 0) || (repeticao && repeticao.length > 0)) {
      return false
    }
    else if (chassi == '' || chassi.length != 17) {
      return false;
    }
    return true;
  }
  validarRenavam = (renavam) => {

    let repeticao = renavam.match("^.{1,}([0-9])\\1{5,}")
    if (!renavam || renavam.length !== 11 || (repeticao && repeticao.length > 0)) {
      return false;
    }
    return true;
  }

  validarEmail(email = "") {
    let rg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    return rg.test(String(email));
  }

  validarCelular(celular = "") {
    let tmpCelular = String(celular).replace(/[^\d]+/g, "");
    return tmpCelular.length === 11 && tmpCelular.charAt(2) === '9';
  }

  validarCPF(cpf) {
    cpf = String(cpf).replace(/[^\d]+/g, "");

    if (cpf === "") return false;

    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999" ||
      cpf === "01234567890"
    )
      return false;

    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);

    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9))) return false;

    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10))) return false;

    return true;
  }

  validarCNPJ(cnpj) {
    cnpj = String(cnpj).replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14)
      return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999")
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != digitos.charAt(0))
      return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
        pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
      return false;

    return true;
  }

}





export default new TextUtils();
import React, { Component } from "react";
import TextUtil from '../../../utils/text';

class FormCancelarNegociacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dsMotivoCancelamento: props.dsMotivoCancelamento || ''
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value || '';
        const name = target.name;
        this.setState({ [name]: value });
    }

    handleCancelarNegociacao = () => {
        if (this.props.onClickCancelarNegociacao)
            this.props.onClickCancelarNegociacao(this.state.dsMotivoCancelamento);
    }

    handleVoltar = () => {
        if (this.props.onClickVoltar)
            this.props.onClickVoltar();
    }

    render() {
        return <div className="container-fluid pt-3">
            <div className="form-group">
                <p>Deseja realmente cancelar a negociação no valor de <strong>{TextUtil.formatarMoeda('R$ ', this.props.negociacao.vlNegociacao)}</strong>? Não será possível reverter sua solicitação e os valores podem não estar mais disponíveis para negociar.</p>
                <label htmlFor="dsMotivoCancelamento">Por favor, informe o motivo do cancelamento.</label>
                <input className="form-control " id="dsMotivoCancelamento" name="dsMotivoCancelamento" value={this.state.dsMotivoCancelamento} onChange={this.handleInputChange} />
            </div>
            <button type="button" className="btn btn-block btn-primary mt-4" onClick={this.handleCancelarNegociacao}>Cancelar negociação</button>
            <button type="button" className="btn btn-block btn-secondary" onClick={this.handleVoltar}>Voltar</button>
        </div>
    }
}
export default FormCancelarNegociacao;

import React, { Component, Fragment } from 'react';

import PropostasService from '../../../../services/propostas.service';
import S3FileUpload from '../../../s3-file-upload';
import Loader from '../../../loader';
import Table from '../../../table-simples';
import Environment from '../../../../environment';
import analyticsService from '../../../../services/analytics.service';
import ModalComprovante from '../../../modais/modal-comprovante'

class CadastroComprovante extends Component {
  constructor(props = { proposta: {}, flPermiteEditar: true }) {
    super(props);
    this.state = {
      cdProposta: props.proposta.cdProposta,
      idComprovante: '',
      dsComprovante: '',
      dsArquivo: '',
      cdTipo: '',
      dsObservacoes: '',
      loader: {},
      modalComprovante: {}
    }
  }

  componentDidMount() {
    this.montarListaComprovantes();
  }

  montarListaComprovantes = () => {
    let listaComprovantes = [];
    for (let c of this.props.comprovantesEnviar) {
      listaComprovantes.push({
        idComprovante: c.idComprovante,
        dsComprovante: c.dsComprovante,
        cdComprovante: c.cdComprovante,
        dsObservacoes: c.dsObservacoes,
        dsInstrucoes: c.dsInstrucoes
      })
    }

    for (let c of this.props.comprovantes) {
      let comprovante = listaComprovantes.filter(comp => comp.cdComprovante === c.cdTipo)[0];
      if (comprovante) {

        // Validação para quando o cliente anexar mais de um comprovante do mesmo tipo
        if (comprovante.dsArquivo) {
          comprovante = JSON.parse(JSON.stringify(comprovante));
          listaComprovantes.push(comprovante);
        }

        comprovante.idComprovante = c.idComprovante;
        comprovante.cdStatus = c.cdStatus;
        comprovante.dsArquivo = c.dsArquivo;
        comprovante.cdProposta = c.cdProposta;
        comprovante.dsObservacoes = c.dsObservacoes;
        comprovante.dsInstrucoes = c.dsInstrucoes;
      }

      else {
        listaComprovantes.push({
          idComprovante: c.idComprovante,
          dsComprovante: c.dsComprovante,
          cdComprovante: c.cdTipo,
          dsObservacoes: c.dsObservacoes,
          dsInstrucoes: c.dsInstrucoes,
          cdStatus: c.cdStatus,
          dsArquivo: c.dsArquivo,
          cdProposta: c.cdProposta,
        })
      }
    }
    this.setState({ listaComprovantes })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value });


  }

  onClickInformacoesDocs = async (comprovante) => {
    
    this.setState({
      modalComprovante: {
        show: true,
        dsComprovante: comprovante.dsComprovante,
        dsInstrucoes: comprovante.dsInstrucoes,
        cancelCallback: () => this.setState({ modalComprovante: { show: false } })
      }
    });
  }

  handleSalvarComprovante = async () => {
    try {
      this.setState({ loader: { message: 'Gravando arquivo...', show: true }, error: null });
      let comprovante = this.state;
      await PropostasService.persistirComprovanteProposta({ ...comprovante, cdProposta: this.props.proposta.cdProposta });

      this.setState({
        loader: {},
        idComprovante: '',
        dsComprovante: '',
        dsArquivo: '',
        cdTipo: '',
        dsObservacoes: ''

      });
      await this.props.onRecuperarDadosProposta();
      this.montarListaComprovantes();

    } catch (err) {
      console.error(err);
      this.setState({ loader: {}, error: 'Ocorreu uma falha ao salvar os dados. Tente novamente.' });
      analyticsService.sendException(err, false);
    }
  }

  handleGetUploadLink = async (nomeArquivo) => {
    this.setState({ loader: { message: 'Enviando arquivo...', show: true }, error: null });
    let arquivo = await PropostasService.recuperarLinkUpload(nomeArquivo);
    let comprovante = this.state;
    comprovante.dsArquivo = arquivo.nomeArquivo;
    comprovante.dsComprovante = nomeArquivo;
    this.setState(comprovante)
    return arquivo.link;
  }

  render() {
    return <Fragment>


      <Loader message={this.state.loader.message} show={this.state.loader.show} />
      <div className="row justify-content-center animated fadeIn pb-5" >
        {this.state.listaComprovantes ? <div className="col-12">
          <div className="form-group">
            <Table onListItemClick={this.onListItemClick}
              legend="Lista de comprovantes (clique para mais informações):"
              rows={PropostasService.mapComprovantesToTable(this.state.listaComprovantes || [], this.onClickInformacoesDocs)}
            />
          </div>
        </div> : null}

        {this.state.modalComprovante && this.state.modalComprovante.show ? <ModalComprovante show={this.state.modalComprovante.show}
          cancelCallback={this.state.modalComprovante.cancelCallback}
          dsComprovante={this.state.modalComprovante.dsComprovante}
          dsInstrucoes={this.state.modalComprovante.dsInstrucoes}
          cdTipo={this.state.cdTipo}
        /> : null}

        {this.props.proposta.flPermiteEditar ? <Fragment>
          <div className="col-12">
            <div className="form-group">
              <label>Selecione o comprovante que deseja enviar:</label>
              <select id="cdTipo" name="cdTipo" placeholder="Tipo de comprovante" className="form-control" value={this.state.cdTipo} onChange={this.handleInputChange}>
                <option value={""} disabled>Tipo de comprovante</option>
                {(this.props.comprovantesProduto || []).map(c => <option key={c.cdComprovante} value={c.cdComprovante}>{c.dsComprovante}</option>)}
              </select>
            </div>
          </div>

          {this.state.cdTipo === 'OUTROS' ? <div className="col-12">
            <div className="form-group">
              <input className="form-control" id="dsObservacoes" name="dsObservacoes" placeholder="Observações" value={this.state.dsObservacoes} onChange={this.handleInputChange} />
            </div>
          </div> : null}

          {this.state.cdTipo ? <div className='col-12'>
            <div style={{ borderRadius: '7px', border: '1px solid #18BC9C', padding: 10 }}>
              <S3FileUpload
                btnClass="btn btn-primary btn-block"
                labelClass="text-md"
                filename="comprovante"
                directUpload={true}
                getUploadLink={this.handleGetUploadLink}
                onFileUploaded={this.handleSalvarComprovante}
              />
            </div>
          </div> : null}
        </Fragment> : null
        }
        {this.props.proposta.flPermiteEditar && (this.props.proposta.comprovantesEnviar || []).length === 0 ? <div className="col">
          <button className="btn btn-block btn-primary py-2" onClick={this.props.onClickEnviarPropostaAprovacao}>
            <img alt="Daycoval" src={`${Environment.get('ASSETS')}/img/check_white.svg`} style={{ width: 20, height: 'auto', marginRight: 10, marginTop: -3 }} />
            Enviar para aprovação
          </button>
        </div> : null}
      </div>
    </Fragment >
  }
}

export default CadastroComprovante;
import React, { Component, Fragment } from 'react';
import Environment from '../../environment';

class Cabecalho extends Component {

    render() {
        return <Fragment>
            <div className='navbar-cabecalho'>
                {this.props.btnEsq.length > 0 ? <div className='itens start'>
                    {this.props.btnEsq.map((bte, index) => {
                        return <a key={index} href={bte.redirecionar}>
                            <img className={bte.className} key={bte.alt} alt={bte.alt} src={Environment.get('ASSETS') + `/img/${bte.img}`} onClick={(e) => this.props.onEvento(bte.cdEvento)}></img>
                        </a>
                    })}
                </div> : null}

                <div className='itens center'>
                    {this.props.tipo == 'PEQUENO' && this.props.imagens ? <Fragment>
                        {this.props.imagens.map((i, index) => {
                            return <a key={index} href={i.redirecionar}>
                                <img className={i.className} style={i.style} alt={i.alt} src={Environment.get('ASSETS') + `/img/${i.img}`}></img>
                            </a>
                        })}
                    </Fragment> : null}
                </div>

                {this.props.btnDir.length > 0 ? <div className='itens end'>
                    {this.props.btnDir.map((btd, index) => {
                        return <a key={index} href={btd.redirecionar}>
                            <img className={btd.className} key={btd.alt} alt={btd.alt} src={Environment.get('ASSETS') + `/img/${btd.img}`} onClick={(e) => this.props.onEvento(btd.cdEvento)}></img>
                        </a>
                    })}
                </div> : null}
            </div>

            {this.props.tipo == 'GRANDE' && this.props.imagens ?
                <div className='group-itens' style={this.props.tamanho ? { height: `calc(${this.props.tamanho}% - 60px)` } : {}}>
                    {
                        this.props.imagens.map((i, index) => {
                            return <div key={index} className='item'>
                                <a href={i.redirecionar}>
                                    <img className={i.className} style={i.style} alt={i.alt} src={Environment.get('ASSETS') + `/img/${i.img}`}></img>
                                </a>
                            </div>
                        })
                    }
                </div>
                : null}

        </Fragment>
    }
}
export default Cabecalho;
import React, { Fragment } from "react";
import Page from '../../page';
import Loader from '../../../components/loader';
import Msgs from '../../../components/msgs';

import ViewListaOfertas from "../../../components/formularios/servicos/view-ofertas-reneg";
import FormConfirmaReneg from "../../../components/formularios/servicos/form-aceitar-ofertas-reneg";
import RenegService from "../../../services/reneg.service";

import AbaProposta from '../../../components/aba-proposta';
import ContratosService from "../../../services/contratos.service";
import Table from "../../../components/table";
import analyticsService from "../../../services/analytics.service";

class DetalheReneg extends Page {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: undefined,
      info: undefined,
      cdContrato: props.match.params.cdContrato,
      contrato: undefined,
      cliente: undefined,
      ofertaSelecionada: undefined,
      qtDiasCarencia: 45,
      reneg: {},
      telaAtual: 'TELA_INICIAL'
    }
  }

  componentDidMount() {
    window.location.hash = `servicos/contratos/${encodeURIComponent(this.state.cdContrato)}`;
    // this.loadData()
  }

  loadData = async () => {
    this.setState({ loader: { message: 'Iniciando aplicação...', show: true } });
    try {
      let carencias = await RenegService.listarCarenciasContrato(this.state.cdContrato);
      let vlCustasProcessuais = this.state.vlCustasProcessuais;
      let qtDiasCarencia = this.state.qtDiasCarencia;
      if (carencias && carencias.length > 0) {
        qtDiasCarencia = carencias[0];
      }
      let proximosDiasUteis = await RenegService.listarProximasDatasVencimento();
      let dtPagamento = proximosDiasUteis[0];

      let { contrato, ofertas } = await RenegService.calcularOfertasReneg(this.state.cdContrato, dtPagamento, qtDiasCarencia, vlCustasProcessuais);

      this.setState({
        contrato,
        cdContrato: contrato.cdContrato,
        dtPagamento: proximosDiasUteis[0],
        ofertas,
        carencias,
        qtDiasCarencia,

      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Contrato indisponível para emitir renegociação: fora das políticas.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loading: false });
  }

  handleOnClickConfirmarReneg = async (dadosReneg) => {
    this.setState({ loader: { message: 'Iniciando aplicação...', show: true } });
    try {
      let oferta = this.state.ofertaSelecionada
      oferta.qtDiasCarencia = this.state.qtDiasCarencia
      oferta.vlCustasProcessuais = 0
      oferta.flNovoEndereco = dadosReneg.flNovoEndereco
      oferta.dsMotivo = dadosReneg.dsMotivo

      let reneg = await RenegService.confirmarOfertaReneg(oferta);
      let link = await RenegService.recuperarLinkDownloadContratoReneg(reneg.cdReneg);
      window.open(link);

      this.setState({
        reneg
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Contrato indisponível para emitir renegociação: fora das políticas.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loading: false });
  }

  handleOnClickExibirOferta = (oferta) => {
    this.setState({ ofertaSelecionada: oferta });
  }
  handleOnClickConfirmarOferta = (oferta) => {
    this.setState({ ofertaSelecionada: oferta, telaAtual: 'CONFIRMAR_RENEG' });
  }

  handleOnClickVoltar = () => {
    window.location.hash = `servicos/contratos/${encodeURIComponent(this.state.cdContrato)}`;
  }

  render() {
    return <Fragment>
      <AbaProposta
        key={'reneg'}/*  icon={aba.icon}  */
        label={`Renegociação Contrato`}
        active={true}
      />
      <div className="container-flex">
        <div className="col-12 mt-2">
          {this.state.contrato ?
            <Table rows={ContratosService.mapContratosToTable([this.state.contrato], false || [])} /> : null}

          {this.state.loadingOfertas ? <Loader show={this.state.loadingOfertas} /> : <div className="row">
            <Msgs info={this.state.msg} error={this.state.error} />
            {this.state.ofertas && this.state.telaAtual === 'TELA_INICIAL' ?
              <ViewListaOfertas
                ofertas={this.state.ofertas || []}
                ofertaSelecionada={this.state.ofertaSelecionada}
                onClickExibirOferta={this.handleOnClickExibirOferta}
                onClickConfirmarOferta={this.handleOnClickConfirmarOferta}
                onClickVoltar={this.handleOnClickVoltar}
              />
              : null}
            {this.state.telaAtual === 'CONFIRMAR_RENEG' ?
              <FormConfirmaReneg
                contrato={this.state.contrato || {}}
                ofertaSelecionada={this.state.ofertaSelecionada}
                onClickConfirmarReneg={this.handleOnClickConfirmarReneg}
                onClickVoltar={this.handleOnClickVoltar}
              />


              : null}
          </div>}
        </div>
      </div>
    </Fragment>
  }
}
export default DetalheReneg;
class AnalyticsService {
  sendPageView(page, title) {
    window.gtag("event", "page_view", {
      page_location: window.location.origin + '/#/' + page,
      page_path: "/" + page,
      page_title: title || page
    });
  }

  sendEvent(eventName, data) {
    window.gtag('event', eventName, data);
  }

  sendException(err, fatal = false) {
    window.gtag('event', 'exception', {
      description: err,
      fatal
    });
  }
}

export default new AnalyticsService();
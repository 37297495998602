import React from 'react';
import ReactDOM from 'react-dom';

import Environment from './environment';
import App from './App';

(async () => {
  await Environment.init();
  console.log(`${Environment.get('ENV_NAME')} environment loaded.`)
  ReactDOM.render(<App />, document.getElementById('root'));
})();

import React from 'react';
import Environment from '../environment';

export default function Aviso({ show = false, title, messages, onClickOk = () => { } }) {
  return !show ? '' : <div style={{
    background: '#00000099',
    zIndex: 9999,
    width: '100%',
    height: '100%',
    position: 'fixed',
    padding: '40px 10px',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: 'block'
  }}>
    <div style={{
      width: '100%',
      maxWidth: '700px',
      display: 'block',
      margin: 'auto',
      background: "#fff",
      borderRadius: "20px",
      boxShadow: '3px 3px 5px #00000088',
      padding: '15px 10px'
    }}>
      <p style={{
        fontSize: '15px',
        fontWeight: 'bold',
        margin: 0,
        color: '#444',
        borderBottom: '1px solid #444'
      }}>{title || 'FIQUE ATENTO!'}</p>

      {(messages || [
        'Desconfie de qualquer prática incomum, especialmente em solicitações de pagamentos ou depósitos para outros favorecidos que não o Banco Daycoval.',
        'Para quitação de vencidos, todos os boletos são de emissão do Banco Daycoval e os primeiros números da linha digitável sempre serão 707.',
        'Em caso de dúvidas, ligue para nosso SAC:',
        '0800 775 0500',
      ]).map((txt, i) => <p key={i} style={{ fontSize: '14px', fontWeight: 'normal', margin: 0, padding: '5px 0', color: '#444' }}>{txt}</p>)}

      <img alt="Boleto Banco Daycoval" style={{ width: '100%', display: 'block' }} src={Environment.get('ASSETS') + `/img/img-boleto-707.png`} />

      <button className="btn btn-block btn-primary mt-3" onClick={onClickOk}>Ok, entendi!</button>
    </div>
  </div>
}
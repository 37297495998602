import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

import AutenticarCliente from "./autenticacao/autenticar"
import PerfilCliente from "./perfil";

class Autenticar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aba: 'Produtos'
        }
    }

    render() {
        return <Fragment>
           <Navbar/> 
            <Switch>
                <Route path="/clientes/auth" exact render={props => <AutenticarCliente {...props} />} />
                <PrivateRoute path="/clientes/perfil" exact component={PerfilCliente} />
            </Switch>
            <div className="col-12 px-0">
                <Footer />
            </div>
        </Fragment>
    }

}
const PrivateRoute = ({ component: Component, ...props }) => {
    return <Route {...props} render={(props) => {
        /*   if (AuthService.isAuthenticated()) */
        return <Component {...props} />

        /* return <Redirect to={{ pathname: "/clientes/auth", state: { from: props.location } }} /> */
    }} />
}

export default Autenticar;
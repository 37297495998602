import React, { Component } from 'react';
import TextUtil from '../../utils/text';

export default class ModalComprovante extends Component {

  render() {

    return <div className="modal" aria-hidden="false" style={{ backgroundColor: '#0000002f', display: this.props.show ? 'block' : 'none' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body" dangerouslySetInnerHTML={{ __html: this.props.dsInstrucoes || '' }} style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-pill btn-secondary" onClick={(e) => this.props.cancelCallback ? this.props.cancelCallback(e) : null}>Fechar</button>
          </div>

        </div>
      </div>
    </ div>
  }
}
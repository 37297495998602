import ApiService from './api.service';
import sessionStoreService from './session-store.service';

class FinanciamentosService {

  listarProdutos() {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (!cdOrigem || !['CRED_FACIL', 'COTAFACIL', 'DINDINCRED'].includes(cdOrigem)) {
      return [
        {
          cdProduto: 'FIN_VEIC',
          dsDestaque: 'FINANCIAMENTO AUTOMÓVEIS',
        },
        {
          cdProduto: 'CVQ',
          dsDestaque: 'EMPRÉSTIMO COM GARANTIA',
        },
        {
          cdProduto: 'FIN_PESADO',
          dsDestaque: 'FINANCIAMENTO PESADOS',
        },
        {
          cdProduto: 'FIN_MOTO',
          dsDestaque: 'FINANCIAMENTO MOTOS',
        },
        {
          cdProduto: 'ACC',
          dsDestaque: 'ABRA SUA CONTA CORRENTE',
        },
        {
          cdProduto: 'REFIN',
          dsDestaque: '+ CREDITO NO SEU CONTRATO',
        },
      ];
    } else {
      return [
        {
          cdProduto: 'FIN_VEIC',
          dsDestaque: 'FINANCIAMENTO AUTOMÓVEIS',
        },
        {
          cdProduto: 'CVQ',
          dsDestaque: 'EMPRÉSTIMO COM GARANTIA',
        },
      ];
    }
  }

  listarServicos() {
    return [
      {
        cdProduto: 'CONTRATO',
        dsDestaque: 'BAIXAR CONTRATO',
      },
      {
        cdProduto: 'CARNE',
        dsDestaque: 'BAIXAR CARNÊ',
      },
      {
        cdProduto: 'ATRASO',
        dsDestaque: 'PARCELA EM ATRASO',
      },
      {
        cdProduto: 'QUITAR',
        dsDestaque: 'QUITAR CONTRATO',
      },
      {
        cdProduto: 'RENEGOCIAR',
        dsDestaque: 'REFINANCIAR CONTRATO',
      },
      {
        cdProduto: 'PRORROGAR',
        dsDestaque: 'PRORROGAR PARCELAS',
      },
    ];
  }

  async recuperarProduto(cdProduto, cdCliente) {
    return ApiService.get(`financiamentos/recuperar-produto?cdProduto=${cdProduto}&cdCliente=${cdCliente || ''}`);
  }
  async recuperarOpcoesValores(cdProduto, cdCliente, vlAnoBem, idade, estado, idVeiculo) {
    return ApiService.get(`financiamentos/recuperar-opcoes-valores?cdProduto=${cdProduto}&cdCliente=${cdCliente || ''}&vlAnoBem=${vlAnoBem || ''}&idade=${idade}&estado=${estado}&idVeiculo=${idVeiculo || ''}`);
  }
  async recuperarOpcoesParcelas(cdProduto, vlSolicitado, vlEntrada, vlAnoBem, idade, estado, idVeiculo) {
    return ApiService.get(`financiamentos/recuperar-opcoes-parcelas?cdProduto=${cdProduto}&vlSolicitado=${vlSolicitado}&vlEntrada=${vlEntrada || ''}&vlAnoBem=${vlAnoBem || ''}&idade=${idade}&estado=${estado}&idVeiculo=${idVeiculo || ''}`);
  }
}

export default new FinanciamentosService();
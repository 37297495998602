import React, { Component, Fragment } from 'react';

import VeiculosService from '../../../../services/veiculos.service';

import Loader from '../../../../components/loader';
import CampoFormatado from '../../../campo-formatado';
import analyticsService from '../../../../services/analytics.service';

class SelecionarVeiculo extends Component {
  constructor(props) {
    super(props);

    let opcoesAnosVeiculos = [];
    for (let ano = new Date().getFullYear(); ano >= new Date().getFullYear() - 23; ano--)
      opcoesAnosVeiculos.push({ cdOpcao: ano, dsOpcao: String(ano) });

    this.state = {
      opcoesVeiculos: [],
      opcoesAnosVeiculos,

      veiculo: null,
      anoVeiculo: null,

      opcoesMarcas: [],
      marcaVeiculo: null,

      opcoesModelos: [],
      modeloVeiculo: null,

      opcoesVersoes: [],
      versaoVeiculo: null,

      loader: {}
    }
  }

   pesquisarVeiculo = async (pesquisa) => {
    try {
      this.setState({ opcoesVeiculos: [] });
      let opcoesVeiculos = await VeiculosService.pesquisarVeiculo(pesquisa, this.state.anoVeiculo);
      this.setState({ opcoesVeiculos });
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Nenhum veículo encontrado. Pesquise novamente.' });
      analyticsService.sendException(err, false);
    }
  } 

  avancar = async () => {
    if (this.state.veiculo) {
      if (this.props.onVeiculoSelecionado)
        this.props.onVeiculoSelecionado(this.state.veiculo);
    }
  }

  handleAnoVeiculoSelecionado = (anoVeiculo) => {
    this.setState({ anoVeiculo, opcoesMarcas: [], opcoesModelos: [], opcoesVersoes: [] }, this.listarMarcas);
  }

  handleMarcaVeiculoSelecionado = (marcaVeiculo) => {
    this.setState({ marcaVeiculo, opcoesModelos: [], opcoesVersoes: [] }, this.listarModelos);
  }

  handleModeloVeiculoSelecionado = (modeloVeiculo) => {
    this.setState({ modeloVeiculo, opcoesVersoes: [] }, this.listarVersoes);
  }

  handleVersaoVeiculoSelecionado = (idVeiculo) => {
    this.setState({ idVeiculo });
  }

  handleConfirmar = async () => {
    try {
      await this.recuperarVeiculo();
      this.avancar();
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Nenhum veículo encontrado. Pesquise novamente.', loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  listarMarcas = async () => {
    try {
      this.setState({ opcoesMarcas: [], loader: { show: true } });
      let opcoesMarcas = await VeiculosService.listarMarcasVeiculos(this.state.anoVeiculo);
      opcoesMarcas = opcoesMarcas.map(o => {
        return {
          cdOpcao: o.dsMarca,
          dsOpcao: o.dsMarca
        }
      });
      this.setState({ opcoesMarcas, loader: {} });
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Nenhum veículo encontrado. Pesquise novamente.', loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  listarModelos = async () => {
    try {
      this.setState({ opcoesModelos: [], loader: { show: true } });
      let opcoesModelos = await VeiculosService.listarModelosVeiculos(this.state.anoVeiculo, this.state.marcaVeiculo);
      opcoesModelos = opcoesModelos.map(o => {
        return {
          cdOpcao: o.dsModelo,
          dsOpcao: o.dsModelo
        }
      });
      this.setState({ opcoesModelos, loader: {} });
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Nenhum veículo encontrado. Pesquise novamente.', loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  listarVersoes = async () => {
    try {
      this.setState({ opcoesVersoes: [], loader: { show: true } });
      let opcoesVersoes = await VeiculosService.listarVersoesVeiculos(this.state.anoVeiculo, this.state.marcaVeiculo, this.state.modeloVeiculo);
      opcoesVersoes = opcoesVersoes.map(o => {
        return {
          cdOpcao: o.idVeiculo,
          dsOpcao: o.dsVeiculo
        }
      });
      this.setState({ opcoesVersoes, loader: {} });
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Nenhum veículo encontrado. Pesquise novamente.', loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  recuperarVeiculo = async () => {
    try {
      this.setState({ veiculo: null, loader: { show: true } });
      let veiculo = await VeiculosService.recuperarVeiculo(this.state.anoVeiculo, this.state.idVeiculo);
      this.setState({ veiculo, loader: {} });
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Nenhum veículo encontrado. Pesquise novamente.', loader: {} });
      analyticsService.sendException(err, false);
    }
  }

  handleVeiculoSelecionado = async (index) => {
    let veiculo = this.state.opcoesVeiculos[index];
    veiculo.vlAnoModelo = this.state.anoVeiculo;
    this.setState({ veiculo }, this.avancar);
  }

  render() {

    return <Fragment>
      {this.props.cdLandingPage ? <div className="col-12 col-sm-6 col-md-6 col-lg-5 form-landing-page">
        {this.state.loader.show ? <Loader message={this.state.loader.message} show={this.state.loader.show} /> :
          <Fragment>

            <div className="row justify-content-center pt-2">
              <h5 className="text-center">Qual o ano do modelo do veículo?</h5>
              <CampoFormatado
                tipo="opcoes"
                placeholder="selecione"
                valor={this.state.anoVeiculo}
                opcoes={this.state.opcoesAnosVeiculos}
                onChange={this.handleAnoVeiculoSelecionado} />
            </div>

            {this.state.opcoesMarcas && this.state.opcoesMarcas.length > 0 ? <div className="row justify-content-center pt-2">
              <h5 className="text-center">Qual a marca do veículo?</h5>
              <CampoFormatado
                tipo="opcoes"
                placeholder="selecione"
                valor={this.state.marcaVeiculo}
                opcoes={this.state.opcoesMarcas}
                onChange={this.handleMarcaVeiculoSelecionado} />
            </div> : null}

            {this.state.opcoesModelos && this.state.opcoesModelos.length > 0 ? <div className="row justify-content-center pt-2">
              <h5 className="text-center">Qual o modelo do veículo?</h5>
              <CampoFormatado
                tipo="opcoes"
                placeholder="selecione"
                valor={this.state.modeloVeiculo}
                opcoes={this.state.opcoesModelos}
                onChange={this.handleModeloVeiculoSelecionado} />
            </div> : null}

            {this.state.opcoesVersoes && this.state.opcoesVersoes.length > 0 ? <div className="row justify-content-center pt-2">
              <h5 className="text-center">Qual a versão do veículo?</h5>
              <CampoFormatado
                tipo="opcoes"
                placeholder="selecione"
                valor={this.state.versaoVeiculo}
                opcoes={this.state.opcoesVersoes}
                onChange={this.handleVersaoVeiculoSelecionado} />
            </div> : null}

            <div className="col-12 pt-2">
              <p className="text-center mb-0 pb-1" style={{ fontSize: '13px' }}>*O veículo deverá ser avaliado através de vistoria cautelar em empresa conveniada ao DETRAN.</p>
            </div>

            {this.state.idVeiculo ?
              <Fragment>
                <div className="col-12 pt-2">
                  <button className="btn btn-block btn-primary"
                    onClick={(e) => this.handleConfirmar()}
                  >Confirmar</button>
                </div>
              </Fragment> : null}

            {/* {this.state.anoVeiculo ? <SearchList
            label={'Qual a marca e modelo do veículo?'}
            placeholder={'Pesquise aqui (marca e modelo)'}
            mensagem={'Estas informações serão utilizadas para encontrar as taxas corretas do seu empréstimo ou financiamento.'}
            vazio={'Caso não tenha encontrado seu veículo significa que ele não é passível de financiamento.'}
            itens={this.state.opcoesVeiculos ? this.state.opcoesVeiculos.map(v => v.dsTrim) : []}
            onPesquisar={this.pesquisarVeiculo}
            onSelecionar={this.handleVeiculoSelecionado} /> : null} */}
          </Fragment>}
      </div> : <div className="col-12 col-sm-6 col-md-6 col-lg-5">
          {this.state.loader.show ? <Loader message={this.state.loader.message} show={this.state.loader.show} /> :
            <Fragment>

              <div className="row justify-content-center pt-2">
                <h5 className="text-center">Qual o ano do modelo do veículo?</h5>
                <CampoFormatado
                  tipo="opcoes"
                  placeholder="selecione"
                  valor={this.state.anoVeiculo}
                  opcoes={this.state.opcoesAnosVeiculos}
                  onChange={this.handleAnoVeiculoSelecionado} />
              </div>

              {this.state.opcoesMarcas && this.state.opcoesMarcas.length > 0 ? <div className="row justify-content-center pt-2">
                <h5 className="text-center">Qual a marca do veículo?</h5>
                <CampoFormatado
                  tipo="opcoes"
                  placeholder="selecione"
                  valor={this.state.marcaVeiculo}
                  opcoes={this.state.opcoesMarcas}
                  onChange={this.handleMarcaVeiculoSelecionado} />
              </div> : null}

              {this.state.opcoesModelos && this.state.opcoesModelos.length > 0 ? <div className="row justify-content-center pt-2">
                <h5 className="text-center">Qual o modelo do veículo?</h5>
                <CampoFormatado
                  tipo="opcoes"
                  placeholder="selecione"
                  valor={this.state.modeloVeiculo}
                  opcoes={this.state.opcoesModelos}
                  onChange={this.handleModeloVeiculoSelecionado} />
              </div> : null}

              {this.state.opcoesVersoes && this.state.opcoesVersoes.length > 0 ? <div className="row justify-content-center pt-2">
                <h5 className="text-center">Qual a versão do veículo?</h5>
                <CampoFormatado
                  tipo="opcoes"
                  placeholder="selecione"
                  valor={this.state.versaoVeiculo}
                  opcoes={this.state.opcoesVersoes}
                  onChange={this.handleVersaoVeiculoSelecionado} />
              </div> : null}

              <div className="col-12 pt-2">
                <p className="text-center mb-0 pb-1" style={{ fontSize: '13px' }}>*O veículo informado deve estar em nome do FINANCIADO e deverá ser aprovado em VISTORIA CAUTELAR sob sua responsabilidade nos custos.</p>
              </div>

              {this.state.idVeiculo ?
                <Fragment>
                  <div className="col-12 p-2">
                    <button className="btn btn-block btn-primary"
                      style={{ paddingBottom: '10px', paddingTop: '10px'}}
                      onClick={(e) => this.handleConfirmar()}
                    >Confirmar</button>
                  </div>
                </Fragment> : null}

              {/* {this.state.anoVeiculo ? <SearchList
            label={'Qual a marca e modelo do veículo?'}
            placeholder={'Pesquise aqui (marca e modelo)'}
            mensagem={'Estas informações serão utilizadas para encontrar as taxas corretas do seu empréstimo ou financiamento.'}
            vazio={'Caso não tenha encontrado seu veículo significa que ele não é passível de financiamento.'}
            itens={this.state.opcoesVeiculos ? this.state.opcoesVeiculos.map(v => v.dsTrim) : []}
            onPesquisar={this.pesquisarVeiculo}
            onSelecionar={this.handleVeiculoSelecionado} /> : null} */}
            </Fragment>}
        </div>}

    </Fragment>
  }
}

export default SelecionarVeiculo;
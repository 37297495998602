import React, { Component, Fragment } from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';

class TermosUsoTarionPage extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || false;
    const name = target.name;
    this.setState({ [name]: value });
  }

  render() {
    return <Fragment>
      <Navbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-4">
            <h5>Termos de uso dos serviços e produtos Tarion-Beck (v1.1)</h5>

            <p className="mb-4 mt-4"><small>Este documento é destinado aos utilizadores dos Serviços e Produtos do grupo Tarion-Beck</small></p>

            <p>Estes Termos de Uso (ou “Termos”) governam seu uso dos serviços e produtos do grupo Tarion-Beck, exceto quando afirmamos explicitamente que outros termos (e não estes) se aplicam, e fornecem informações sobre os serviços e produtos do grupo Tarion-Beck (o “Serviços e Produtos”), descritas abaixo. Quando você realiza o processo de autenticação ou utiliza os serviços e produtos do grupo Tarion-Beck, concorda com estes termos.</p>
            <p>A utilização dos serviços e produtos do grupo Tarion-Beck deve seguir todas as políticas que lhe são disponibilizadas em cada um dos serviços. Atualmente Fácil de Financiar, Fácil de Pagar e Contrato.srv.br fazem parte do grupo de serviços e produtos oferecidos pela Tarion-Beck através do Portal do Cliente e podem ser acessados exclusivamente através dos sites (domínios) facildepagar.com.br, facildefinanciar.com.br e contrato.srv.br. Qualquer outro domínio que se intitule ou se caracterize como um destes serviços e produtos não faz parte do grupo Tarion-Beck e não são nossa responsabilidade.</p>
            <p>Não utilize indevidamente nenhum dos nossos serviços e produtos. Não interfira com os nossos serviços e produtos, nem tente acessar os mesmos através de outros métodos que não a interface e as instruções que fornecemos. Pode utilizar os nossos serviços e produtos apenas nos termos permitidos por lei. Podemos suspender ou deixar de fornecer os serviços e produtos ao utilizador, caso este não respeite os nossos termos ou políticas de utilização ou se estivermos a investigar uma possível conduta imprópria. </p>
            <p>A utilização dos nossos serviços e produtos não confere ao utilizador quaisquer direitos de propriedade intelectual sobre os nossos serviços e produtos ou o conteúdo acedido. Não pode utilizar conteúdo dos nossos serviços e produtos, salvo mediante autorização do respectivo proprietário ou em circunstâncias permitidas por lei. Os presentes termos de utilização não concedem o direito de utilizar nenhuma das marcas ou logotipos usados nos nossos serviços e produtos. O utilizador não deverá remover, ocultar ou alterar qualquer aviso legal apresentado nos nossos serviços e produtos ou juntamente com eles. </p>
            <p>Os nossos serviços e produtos apresentam alguns conteúdos que não pertencem ao grupo Tarion-Beck. Estes conteúdos são da inteira responsabilidade de nossos clientes que os disponibilizam. Poderemos avaliar o conteúdo para determinar se é ilegal ou se viola as nossas políticas e poderemos remover ou recusar apresentar conteúdo que, de uma forma razoável, considerarmos que viola as nossas políticas ou a lei. Não obstante, isso não significa necessariamente que avaliamos todos os conteúdos, pelo que não deve partir do princípio de que o fazemos. </p>
            <p>Poderemos enviar anúncios de serviços e produtos, mensagens administrativas e outras informações relacionados com a sua utilização dos serviços e produtos. Pode optar por não receber algumas dessas comunicações através de notificação para o e-mail suporte@facildepagar.com.br. </p>
            <p>Alguns dos nossos serviços e produtos estão disponíveis em dispositivos móveis. Não utilize estes serviços de forma a que constituam uma distração e o impeçam de obedecer às leis rodoviárias ou de segurança.</p>
            
            <p><small>Data da última revisão: 28 de dezembro de 2021</small></p>
            <p className="mt-4"><small>“Este e qualquer outro documento oficial podem conter erros de digitação ou ambiguidade. Informe imediatamente sobre qualquer problema encontrado através do e-mail suporte@facildepagar.com.br”</small></p>

            <p className="mt-5">Leia os Termos e Políticas</p>
            <a href="#/termos-uso-tarion"><p className="mb-1">Termos de uso dos serviços e produtos Tarion-Beck</p></a>
            <a href="#/termos-uso"><p className="mb-1">Termos de uso do Portal do Cliente</p></a>
            <a href="#/politica-privacidade"><p className="mb-1">Política de Privacidade do Portal do Cliente</p></a>
            <a href="#/politica-dados"><p className="mb-1">Política de Dados do Portal do Cliente</p></a>
          </div>
          <div className="col-12 px-0">
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  }
}

export default TermosUsoTarionPage;
import React, { } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import Environment from '../../environment';

/** itens pode ser qualquer lista ex. cliente, veiculos etc..
 * itens = [{
 *  dsItem: 'Nome legível'
 *  dsInfo: 'Informação abaixo do nome do arquivo',
 *  dsLink: 'https://link.para.arquivo/',
 *  dsIcone: 'png|xls|pdf|etc',
 *  cdEventoSelecionar: 'ACAO',
 *  style: {} //Estilo do título
 *  styleInfo: {} //Estilo da info
 * }] 
 */
export default function Lista({ componente = {}, onEvento = () => { } }) {

    async function onClick(acao, item = {}) {
        if (!item) return;
        if (acao == 'EXCLUIR')
            return onEvento(item.cdEventoExcluir, { cdItem: item.cdItem });
        else if (acao == 'EDITAR')
            return onEvento(item.cdEventoEditar, { cdItem: item.cdItem });
        else if (acao == 'SELECIONAR')
        return onEvento(item.cdEventoSelecionar, { cdItem: item.cdItem });
           else if (item.dsLink)
               return window.open(item.dsLink, '_blank'); 

    }

    return (<Fragment>
        {(componente.itens || []).map((item, index) => {
            if (item.dsLink && item.downloadAutomatico)
                window.open(item.dsLink, '_blank');

            return <div key={index}
                onClick={() => onClick('SELECIONAR', item)}
                style={Object.assign({ display: 'flex', flexDirection: 'row', marginBottom: '20px', borderBottom: '1px solid #B2B2B2' })}>

                {item.dsIcone ? <img alt={item.dsTitulo}
                    src={Environment.get('ASSETS') + `/img/${item.dsIcone}.svg`}
                    style={Object.assign({ width: '45px' })} /> : null}

                <div style={Object.assign({
                    flexGrow: 3,
                    padding: '0 10px',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                })}>
                    <p style={Object.assign({
                        margin: 0,
                        fontSize: '17px',
                        fontWeight: 'bold',
                        overflowWrap: 'anywhere',
                    }, item.style)}>{item.dsTitulo}</p>

                    <p style={Object.assign({
                        margin: 0,
                        fontSize: '17px',
                        overflowWrap: 'anywhere',
                    }, item.styleInfo)}>{item.dsInfo}</p>
                </div>

                {item.cdEventoExcluir ? <img alt='Apagar'
                    src={Environment.get('ASSETS') + `/img/delete.svg`}
                    onClick={() => onClick('EXCLUIR', item)}
                    style={Object.assign({ width: '28px', marginRight: '10px' })} /> : null}

                {item.cdEventoEditar ? <img alt='Editar'
                    src={Environment.get('ASSETS') + `/img/edit_1.svg`}
                    onClick={() => onClick('EDITAR', item)}
                    style={Object.assign({ width: '28px', marginRight: '10px' })} /> : null}

                {item.dsLink ? <img alt='Download'
                    src={Environment.get('ASSETS') + `/img/download_3_dark.svg`}
                    style={Object.assign({ width: '25px' })} /> : null}

            </div>
        })}
    </Fragment>
    )
}
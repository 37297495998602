import React, { Component, Fragment } from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';

class PoliticaPrivacidadePage extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || false;
    const name = target.name;
    this.setState({ [name]: value });
  }

  render() {
    return <Fragment>
      <Navbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-4">
            <h5>Política de Privacidade do Portal do Cliente (v1.0)</h5>

            <p className="mb-4 mt-4"><small>Este documento é destinado aos utilizadores do Portal do Cliente.”</small></p>

            <p>Quando você usa algum produto ou serviço do grupo Tarion-Beck, está confiando a nós suas informações. Entendemos que isso é uma grande responsabilidade e trabalhamos duro para proteger essas informações e colocar você no controle.</p>
            <p>O Portal do Cliente é uma ferramenta desenvolvida para empresas clientes que oferecem algum tipo de crédito ao público em geral para que os consumidores tenham acesso às suas dívidas em atraso, às cópias de seus contratos e seus carnês, possam simular e contratar financiamentos.</p>
            <p>O Portal do Cliente é personalizado e totalmente customizado com as características das empresas clientes. Por este motivo, ao acessar o Portal do Cliente, poderão ser exibidos cores, imagens e logotipos de marcas registradas em nome dos clientes, que foram previamente autorizadas para utilização. Em caso de dúvidas sobre a autenticidade das informações, envie e-mail para suporte@facildepagar.com.br.</p>
            <p>No Portal do Cliente você irá fornecer alguns dados pessoais que são necessários para: personalizar o atendimento; consultar informações sobre os serviços e produtos disponíveis; garantir a segurança no acesso aos dados e; evitar fraudes.</p>

            <p className="mt-4 font-weight-bold">Dados solicitados</p>
            <p>Solicitamos dados em diferentes momentos para proporcionar a maior privacidade e qualidade possíveis durante a utilização de nossos serviços e produtos. Alguns dados são coletados e ficam armazenados e outros são descartados imediatamente após o uso.</p>
            <p>Para os serviços de entrega de Contratos, entrega de Carnês e Cobrança de vencidos, os dados cadastrais dos clientes são previamente fornecidos pelas empresas cliente, detentoras e responsáveis pelos direitos de uso, que concedem permissão para os devidos fins de disponibilização, notificação e negociação de dívidas.</p>
            <p>Para contratar um produto de crédito, os novos cliente deverão preencher as informações solicitadas em cada uma das etapas do processo para prosseguir com a inclusão de uma proposta de crédito. As etapas são Simulação e Inclusão da Proposta.</p>

            <p className="mt-4 font-weight-bold">Autenticação</p>
            <p>Para acessar recursos restritos, o utilizador do Portal do Cliente deve passar pelo processo de autenticação fornecendo alguns dados pessoais:</p>
            <p>	- CPF: é a chave principal de acesso. Através do CPF será possível validar dados pré-cadastrados e fornecidos pelas empresas clientes. Para financiamento, o CPF é solicitado apenas na solicitação do crédito, não sendo necessário para simular as condições.</p>
            <p>	- Data de nascimento: é a contra chave de acesso, que será utilizada em conjunto com o CPF para autenticar o cliente.</p>
            <p>	- Número de celular (WhatsApp): é necessário para envio do token de autenticação e notificações sobre os andamentos das solicitações realizadas no portal.</p>
            <p>	- E-mail: será utilizado para recuperar o acesso caso o utilizador não tenha mais acesso ao número de celular (WhatsApp) cadastrado anteriormente.</p>

            <p className="mt-4 font-weight-bold">Simulação de Crédito</p>
            <p>Para simular a contratação de crédito, são necessários os dados seguintes para localizar as políticas corretas, fornecidas pelas empresas clientes:</p>
            <p>	- Identificação do bem utilizado como garantia (Marca, Modelo, Versão e Ano do veículo que deseja financiar, por exemplo)</p>
            <p>	- Idade atual do utilizador</p>
            <p>	- Estado de residência atual do utilizador</p>

            <p className="mt-4 font-weight-bold">Inclusão de Proposta de Crédito</p>
            <p>Após a simulação, o cliente pode iniciar o cadastro de uma Proposta de Crédito. Cada empresa cliente possui as próprias regras e dados que são solicitados para análise e proteção ao crédito, podendo conter informações para comprovação de renda, comprovação de endereço, comprovação do bem envolvido e outras comprovações que podem ser necessárias para casos específicos. Todas as informações são solicitadas de forma transparente.</p>
            <p>O Portal do Cliente apenas reflete as regras definidas previamente pelas empresas cliente, portanto as regras são de total responsabilidade das empresas clientes.</p>
            <p>O utilizador pode optar por não fornecer os dados solicitados, assim interrompendo a solicitação da proposta de crédito.</p>

            <p className="mt-4 font-weight-bold">Compartilhamento e armazenamento de dados </p>
            <p>Todas as informações processadas e armazenadas pelo Portal do Cliente estão em ambientes seguros, seguindo padrões internacionais de segurança através do provedor de serviços de computação Amazon Web Services.</p>
            <p>Nenhum de seus dados será compartilhado, exposto ou vendido para terceiros, exceto fornecedores de serviços que viabilizam a infraestrutura necessária para a correta disponibilização dos serviços e produtos Tarion-Beck. Suas informações são sigilosas e devemos garantir sua integridade e segurança. As únicas partes que podem ter acesso aos dados são: você, os profissionais autorizados da Tarion-Beck, a empresa cliente ao qual estamos prestando os serviços e, com dados fragmentados, os fornecedores de serviços da Tarion-Beck.</p>

            <p className="mt-4 font-weight-bold">Exclusão de dados e dúvidas </p>
            <p>A qualquer tempo você pode questionar ou solicitar à nossa equipe a exclusão de seus dados através do e-mail suporte@facildepagar.com.br. Devido ao fato de que nenhum dado é passível de consulta ou exposição a terceiros, a única maneira de validar sua identidade, seja para exclusão ou questionamentos, será através do documento informado durante a utilização dos produtos e/ou serviços, seu nome, telefone celular e/ou e-mail. Nossa equipe irá solicitar estas informações sempre que julgar necessário para validar sua identidade. </p>

            <p className="mt-4 font-weight-bold">Atualizações e notificações </p>
            <p>Os produtos do grupo Tarion-Beck mantêm constante atualização. Isso pode exigir mudanças nos nossos termos e políticas de privacidade sem aviso prévio. Entendendo a importância em manter seus dados seguros, confidenciais e sempre alinhado com as regulamentações internacionais, a qualquer tempo você poderá ser notificado sobre qualquer atualização nos termos e políticas de privacidade, ficando a seu critério o aceite ou recusa das alterações. A recusa irá ocasionar a exclusão de qualquer dado de sua propriedade que esteja armazenado em nossa infraestrutura.</p>

            <p><small>Data da última revisão: 28 de dezembro de 2021</small></p>
            <p className="mt-4"><small>“Este e qualquer outro documento oficial podem conter erros de digitação ou ambiguidade. Informe imediatamente sobre qualquer problema encontrado através do e-mail suporte@facildepagar.com.br”</small></p>

            <p className="mt-5">Leia os Termos e Políticas</p>
            <a href="#/termos-uso-tarion"><p className="mb-1">Termos de uso dos serviços e produtos Tarion-Beck</p></a>
            <a href="#/termos-uso"><p className="mb-1">Termos de uso do Portal do Cliente</p></a>
            <a href="#/politica-privacidade"><p className="mb-1">Política de Privacidade do Portal do Cliente</p></a>
            <a href="#/politica-dados"><p className="mb-1">Política de Dados do Portal do Cliente</p></a>
          </div>
          <div className="col-12 px-0">
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  }
}

export default PoliticaPrivacidadePage;
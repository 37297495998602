import ApiService from './api.service';
import sessionStoreService from './session-store.service';

class ClientesService {

  async autenticar(cliente) {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (cdOrigem == 'undefined') cdOrigem = undefined;

    return ApiService.post('clientes/autenticar', { ...cliente, cdOrigem });
  }

  async solicitarTokenWhatsapp(cliente) {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (cdOrigem == 'undefined') cdOrigem = undefined;
    return ApiService.post('clientes/solicitar-token-whatsapp', { ...cliente, cdOrigem });
  }

  async recuperar() {
    return ApiService.get('clientes/recuperar');
  }

  async persistirPerfil(cliente) {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (cdOrigem == 'undefined') cdOrigem = undefined;
    return ApiService.post('clientes/persistir-perfil', { ...cliente, cdOrigem });
  }

  async verificarDadosAcesso(params) {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (cdOrigem == 'undefined') cdOrigem = undefined;
    return ApiService.post('clientes/verificar-dados-acesso', { ...params, cdOrigem });
  }
}

export default new ClientesService();
import React, { Component } from 'react';

class OpcoesEscolha extends Component {
  render() {
    return <div className="row justify-content-center">

      {this.props.label ? <h5 className="text-center">{this.props.label}</h5> : null}

      {this.props.opcoes.map((v, index) => {
        return <div className="col-6 py-2" key={index}>
          <button className="btn btn-block  btn-primary"
            style={{ paddingBottom: '20px', paddingTop: '20px' }}
            onClick={(e) => this.props.onClickOpcao(index)}
            dangerouslySetInnerHTML={{ __html: v }}
          ></button>
        </div>
      })}

      {this.props.opcaoExtra ?
        <div className="col-12 py-2">
          <button className="btn btn-block btn-primary"
            style={{ paddingBottom: '10px', paddingTop: '10px' }}
            onClick={(e) => this.props.onClickOpcaoExtra()}
            dangerouslySetInnerHTML={{ __html: this.props.opcaoExtra }}
          ></button>
        </div>
        : null}
    </div>
  }
}

export default OpcoesEscolha;
export default {
    OLX: {
        layout: {
            cores: ['#fff', '#fff'],
            logo: 'olx_fundo_branco.png',
            // corPrincipal: '#670ec5',
            corTextoPrincipal: '#011e60',
            fundoClaro: true,
            logo2: 'correspondente-autorizado-daycoval.png',
            alt: 'olx',
            alt2: 'Correspondente autorizado Daycoval',
            centralizarLogo: false,
            exibirSaudacoes: false,
            text: 'left',
        },
        parceiro: true
    },

    MERCADOLIVRE: {
        layout: {
            cores: ['#fff159', '#dfe063', '#dfe063'],
            logo: 'ml.png',
            fundoClaro: true,
            alt: 'MercadoLivre',
            centralizarLogo: true,
            exibirSaudacoes: false,
            text: 'right',
        },
        parceiro: true
    },

    default: {
        layout: {
            cores: ['#011e60', '#014280', '#038cc9'],
            fundoClaro: false,
            logo: 'logo1.png',
            alt: 'Daycoval',
            centralizarLogo: false,
            exibirSaudacoes: true,
            text: 'left',
        },
        parceiro: false
    }
}
import ApiService from './api.service';
import Environment from '../environment';
import moment from 'moment';

const STATUS_CADASTRO = ['RECEBIDA', 'CONFIRMADA', 'REPROVADA_PRE_ANALISE', 'PRE_APROVADA'];
const STATUS_DADOS_COMPRADOR = ['DADOS_COMPRADOR', 'ANALISE_DADOS_COMPRADOR', 'DADOS_COMPRADOR_APROVADO', 'DADOS_COMPRADOR_REPROVADO'];
const STATUS_DADOS_VENDEDOR = ['DADOS_VENDEDOR', 'ANALISE_DADOS_VENDEDOR', 'DADOS_VENDEDOR_APROVADO', 'DADOS_VENDEDOR_REPROVADO'];
const STATUS_CREDITO = ['ANALISE_CREDITO', 'CREDITO_REPROVADO', 'CREDITO_APROVADO'];
const STATUS_ENVIO_COMPROVANTES = ['ENVIO_COMPROVANTES', 'ANALISE_COMPROVANTES', 'COMPROVANTES_APROVADOS', 'COMPROVANTES_REPROVADOS'];
const STATUS_FORMALIZACAO = ['AGUARDANDO_ASSINATURA_DIGITAL', 'ANALISE_ASSINATURA_DIGITAL', 'ASSINATURA_DIGITAL_REPROVADA', 'ASSINATURA_DIGITAL_APROVADA', 'ANALISE_ASSINATURA_DIGITAL'];
const STATUS_LIBERACAO = ['LIBERACAO', 'AGUARDANDO_LIBERACAO', 'LIBERACAO_REPROVADA'];
const STATUS_FINALIZADO = ['FINALIZADA'];
const STATUS_CANCELADO = ['REPROVADA', 'CANCELADA'];

class PropostasService {

  async recuperarProposta(cdProposta) {
    let proposta = await ApiService.get(`financiamentos/recuperar-proposta?cdProposta=${cdProposta}`);
    if (proposta) {
      proposta.dtNascimento = proposta.dtNascimento ? moment.utc(proposta.dtNascimento).format('DD/MM/YYYY') : '';
      proposta.dtAdmissao = proposta.dtAdmissao ? moment.utc(proposta.dtAdmissao).format('DD/MM/YYYY') : '';
    }
    return proposta
  }

  async persistirDadosProposta(proposta) {
    if (proposta.dtNascimento)
      proposta.dtNascimento = moment.utc(proposta.dtNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
    if (proposta.dtAdmissao)
      proposta.dtAdmissao = moment.utc(proposta.dtAdmissao, 'DD/MM/YYYY').format('YYYY-MM-DD');
    return await ApiService.post(`financiamentos/persistir-proposta`, { ...proposta });
  }

  async enviarPropostaAprovacao(cdProposta) {
    return await ApiService.get(`financiamentos/enviar-proposta-aprovacao?cdProposta=${cdProposta}`, {});
  }

  async listarPropostas(params = {}) {
    return ApiService.get(`financiamentos/listar-propostas?${Object.keys(params).map(key => `${key}=${params[key] || ''}`).join('&')}`);
  }

  async recuperarLinkUpload(nomeArquivo) {
    return await ApiService.get(`financiamentos/recuperar-link-upload-comprovante?nomeArquivo=${nomeArquivo}`);
  }

  async persistirComprovanteProposta(comprovante) {
    return await ApiService.post(`financiamentos/persistir-comprovante-proposta`, { ...comprovante });
  }

  recuperarEtapa = (cdStatus) => {
    return !cdStatus ? ''
      : STATUS_CADASTRO.indexOf(cdStatus) > -1 ? 'CADASTRO'
        : STATUS_DADOS_COMPRADOR.indexOf(cdStatus) > -1 ? 'DADOS_COMPRADOR'
          : STATUS_DADOS_VENDEDOR.indexOf(cdStatus) > -1 ? 'DADOS_VENDEDOR'
            : STATUS_CREDITO.indexOf(cdStatus) > -1 ? 'CREDITO'
              : STATUS_ENVIO_COMPROVANTES.indexOf(cdStatus) > -1 ? 'ENVIO_COMPROVANTES'
                : STATUS_FORMALIZACAO.indexOf(cdStatus) > -1 ? 'FORMALIZACAO'
                  : STATUS_LIBERACAO.indexOf(cdStatus) > -1 ? 'LIBERACAO'
                    : STATUS_FINALIZADO.indexOf(cdStatus) > -1 ? 'FINALIZADO'
                      : STATUS_CANCELADO.indexOf(cdStatus) > -1 ? 'CANCELADO'
                        : '';
  }

  mapComprovantesToTable(comprovantes = [], onClickInformacoesDocs) {
    return comprovantes.map(c => {
      let mappedComprovantes = [
        {
          html: `<div class="row m-0">
            <div class="col p-0">
              <h6 class=data-bs-toggle="tooltip" data-bs-placement="top" title= "Ver informações">
                ${c.dsComprovante ? c.dsComprovante + `<img alt="Daycoval" src="${Environment.get('ASSETS')}/img/question.svg" style="margin-left: 2px; max-width: 15px"/> ` : ''}
              </h6>
              
              <p class="m-0 p-0" style="color: ${c.cdStatus === 'ERRO' ? '#ff0000' : c.cdStatus === 'OK' ? '#00aa00' : '#666'}">
                ${c.cdStatus === 'ERRO' ? 'Arquivo com problema. ' : c.cdStatus === 'OK' ? 'Arquivo verificado. ' : c.dsArquivo ? 'Arquivo enviado. ' : ''}
              </p>
              
              <p class="m-0 p-0" style="color: ${c.cdStatus === 'ERRO' ? '#ff0000' : '#666'}; font-size: 13px">
                ${c.dsObservacoes ? c.dsObservacoes : ''}
              </p>
            </div>
    
            <div class="col-1 p-0">
            ${c.dsArquivo && c.cdStatus !== 'ERRO' ? `<img alt="Daycoval" src="${Environment.get('ASSETS')}/img/check.svg" style="padding-top: 5px;  max-width: 35px"/>` : ''}
            </div>
          </div>`,
          onClick: () => onClickInformacoesDocs(c)
        }

      ]
      return mappedComprovantes;
    })
  }
  mapComprovantesTable(comprovantes = [], onClickExcluir, onClickDownload) {
    let dtHoje = moment().format('DD/MM/YYYY');

    return comprovantes.map(c => {
      let mappedComprovantes = [
        {
          html: `<div class="row m-0">
          <div class="col-1 p-0 mr-1">
          ${c.dsComprovante && c.dsComprovante.substr(c.dsComprovante.length - 3) == 'png' ? `<img alt="Daycoval" src="${Environment.get('ASSETS')}/img/file_png.svg" style="padding-top: 5px;  max-width: 35px"/>` : c.dsComprovante.substr(c.dsComprovante.length - 3) == 'pdf' ? `<img alt="Daycoval" src="${Environment.get('ASSETS')}/img/file_pdf.svg" style="padding-top: 5px;  max-width: 35px"/>` : ''}
          </div>
            <div class="col p-0 m-3">
              <h6 class=data-bs-toggle="tooltip" data-bs-placement="top" title= "Ver informações">
                ${c.dsComprovante ? c.dsComprovante : ''}<br>
               <p style="font-size: 11px">  ${c.dtEnvioComprovante ? 'Enviado em ' + moment.utc(c.dtEnvioComprovante).format('DD/MM/YYYY') : ''}</span>
              </h6>
              <p class="m-0 p-0" style="color: ${c.cdStatus === 'ERRO' ? '#ff0000' : c.cdStatus === 'OK' ? '#00aa00' : '#666'}">
                ${c.cdStatus === 'ERRO' ? 'Arquivo com problema. ' : c.cdStatus === 'OK' ? 'Arquivo verificado. ' : c.dsArquivo ? 'Arquivo enviado. ' : ''}
              </p>
              <p class="m-0 p-0" style="color: ${c.cdStatus === 'ERRO' ? '#ff0000' : '#666'}; font-size: 13px">
                ${c.dsObservacoes ? c.dsObservacoes : ''}
              </p>
            </div>
          </div>`,
        }, {
          html: `<p class="text-center mt-3">
          ${c.dsComprovante && moment.utc(c.dtEnvioComprovante).format('DD/MM/YYYY') == dtHoje ? `<img alt=${c.cdTipo} src="${Environment.get('ASSETS')}/img/delete.svg" style="padding-top: 5px;  max-width: 35px"/>` : `<img alt=${c.cdTipo} src="${Environment.get('ASSETS')}/img/download_dark.svg" style="padding-top: 5px;  max-width: 35px"/>`}
          </p>`,
          onClick: () => onClickExcluir(c)
        }

      ]
      return mappedComprovantes;
    })
  }

  mapPropostasToTable(propostas = []) {
    return propostas.map(p => {
      let mappedPropostas = [
        {
          html: `<span>
            ${moment.utc(p.dtProposta).format('DD/MM/YYYY')}
          </span>`
        },
        {
          html: `<span>
            ${p.qtParcelas}x de ${parseFloat(p.vlParcela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
          </span>`
        },
        {
          html: `<span>
          ${p.idProposta} - ${p.dsProduto === 'FIN_VEIC' ? 'Financiamento' : p.dsProduto === 'CVQ' ? 'Empréstimo' : p.dsProduto}
          <br>${p.dsStatus}
          </span>`
        }
      ]
      return mappedPropostas;
    })
  }
}



export default new PropostasService();

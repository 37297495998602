import React, { Fragment } from "react";
import Page from '../../page';

import PropostasService from "../../../services/propostas.service";
import AbaProposta from '../../../components/aba-proposta';
import SessionStore from '../../../services/session-store.service';
import moment from "moment";
import environment from "../../../environment";
import authService from "../../../services/auth.service";
import TextUtils from '../../../utils/text';
import Loader from '../../../components/loader';
import sessionStoreService from "../../../services/session-store.service";
import analyticsService from "../../../services/analytics.service";

class ListarProposta extends Page {
    constructor(props) {

        let cliente = SessionStore.getGenericData('cliente');
        let dsCliente = cliente && cliente.dsCliente ? TextUtils.getFirstNameWithUpperCase(cliente.dsCliente) : '';

        super(props);
        this.state = {
            loader: false,
            error: undefined,
            dsCliente,
            propostas: [],
            cliente: {},
        }
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loader: { message: 'Iniciando aplicação...', show: true } });

        try {
            let propostas = await PropostasService.listarPropostas();
            this.setState({ propostas });
        } catch (err) {
            console.error(err);
            this.setState({ error: err.msg || 'Falha ao listar os Propostas. Tente novamente ou contate nosso suporte.' });
            analyticsService.sendException(err, false);
        }

        this.setState({ loader: {} });
        if (this.state.propostas.length === 1) {
            window.location.hash = `/produtos/veiculos/cp/${encodeURIComponent(this.state.propostas[0].cdProposta)}`;
        }
    }

    onListItemClick = async (cdProposta) => {
        window.location.hash = `/produtos/veiculos/cp/${encodeURIComponent(cdProposta)}`;
    }

    handleSair = () => {
        authService.clearAuthData();
        sessionStoreService.clearSession();
        window.location.hash = `/inicio`;
    }

    render() {
        return <Fragment>
            <AbaProposta
                key={'Propostas'}/*  icon={aba.icon}  */
                label={'Lista de Propostas'}
                active={true}
            />
            {this.state.loader.show ? <Loader message='Consultando propostas' show={this.state.loader.show} /> :
                <div className="container-fluid">
                    {!this.state.propostas || this.state.propostas.length === 0 ? <Fragment>
                        <div className="row py-2">
                            <div className="col">
                                <h6>Olá{this.state.cliente ? ' ' + this.state.dsCliente : ''}!</h6>
                                <h6>Você ainda não possui propostas de financiamento ou empréstimo cadastradas aqui no aplicativo.</h6>
                                <h6 className="pb-3">Para solicitar uma nova proposta, é só escolher uma das opções abaixo:</h6>
                                <a href="#/produtos/veiculos/simular/FIN_VEIC" className="btn btn-block btn-primary">Simular um financiamento</a>
                                <a href="#/produtos/veiculos/simular/CVQ" className="btn btn-block btn-primary">Simular um empréstimo</a>
                                <button className="btn btn-block btn-secondary" onClick={this.handleSair}>Sair</button>
                            </div>
                        </div>
                    </Fragment> : null}

                    {(this.state.propostas || []).map(p => {
                        return <div key={p.cdProposta} className="row py-2 pl-2" style={{ borderBottom: '1px solid #ddd' }} onClick={() => this.onListItemClick(p.cdProposta)}>
                            <div className="col-1 p-0 my-1">
                                <img alt="Daycoval" style={{ width: 35, height: 35, marginTop: 5 }} src={environment.get('ASSETS') + `/img/${p.dsProduto}.svg`} />
                            </div>
                            <div className="col my-1">
                                <span style={{ display: 'block', width: '100%', fontSize: 14, lineHeight: '15px' }}>
                                    {p.dsProduto === 'FIN_VEIC' ? 'Financiamento' : p.dsProduto === 'CVQ' ? 'Empréstimo' : p.dsProduto} - {p.qtParcelas}x de {parseFloat(p.vlParcela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                                <span style={{ display: 'block', width: '100%', fontSize: 14, lineHeight: '15px' }}>
                                    <small>Enviada em </small>
                                    {moment.utc(p.dtProposta).format('DD/MM/YYYY')}&nbsp;&nbsp;&nbsp;<small>Cód.</small> {p.idProposta}
                                </span>
                                <span style={{ display: 'block', width: '100%', fontSize: 14, lineHeight: '15px' }}>
                                    <small>Situação: </small>
                                    {p.cdStatus}
                                </span>
                            </div>
                            <div className="col-1 p-0 my-1">
                                <img alt="Daycoval" style={{ width: 20, paddingTop: 15 }} src={environment.get('ASSETS') + `/img/iconfinder_forward.svg`} />
                            </div>
                        </div>
                    })}
                </div>
            }
        </Fragment>
    }
}
export default ListarProposta;
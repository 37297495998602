import React, { Component, Fragment } from 'react';
import SessionStore from '../services/session-store.service';

import Environment from '../environment';
import BtnWhatsApp from '../components/btn-whatsapp';
import ProdutosService from '../services/produtos.service';

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: ProdutosService.listarProdutos(),
      servicos: ProdutosService.listarServicos(),
      aba: this.props.aba
    }
  }

  handleProsseguir = (produto) => {
    window.gtag('event', 'opcao_escolhida_menu_inicial', { produto });

    if (['FIN_VEIC', 'CVQ'].indexOf(produto.cdProduto) > -1) {
      window.location.hash = `produtos/veiculos/simular/${produto.cdProduto}`;
    }

    else if (['ACC'].indexOf(produto.cdProduto) > -1) {
      let ref = window.open('https://daycovaldigital.com.br/');
      if (ref && ref.focus) ref.focus();
    }

    else if (['REFIN'].indexOf(produto.cdProduto) > -1) {
      let ref = window.open('https://daycoval.com.br/');
      if (ref && ref.focus) ref.focus();
    }

    else if (['FIN_PESADO'].indexOf(produto.cdProduto) > -1) {
      let ref = window.open('https://www.daycoval.com.br/para-voce/creditos-financiamentos/cdc-veiculos-pesados');
      if (ref && ref.focus) ref.focus();
    }

    else if (['FIN_MOTO'].indexOf(produto.cdProduto) > -1) {
      let ref = window.open('https://www.daycoval.com.br/para-voce/creditos-financiamentos/cdc-veiculos');
      if (ref && ref.focus) ref.focus();
    }

    else if (['CONTRATO'].indexOf(produto.cdProduto) > -1) {
      SessionStore.saveGenericData('redirecionamentoAutenticacao', '#/servicos/contratos')
      window.location.hash = `#/clientes/auth`;
    }

    else if (['CARNE'].indexOf(produto.cdProduto) > -1) {
      SessionStore.saveGenericData('redirecionamentoAutenticacao', '#/servicos/contratos')
      window.location.hash = `#/clientes/auth`;
    }

    else if (['ATRASO'].indexOf(produto.cdProduto) > -1) {
      SessionStore.saveGenericData('redirecionamentoAutenticacao', '#/servicos/contratos')
      window.location.hash = `#/clientes/auth`;
    }

    else if (['QUITAR'].indexOf(produto.cdProduto) > -1) {
      let ref = window.open('https://facilderesponder.wufoo.com/forms/sx17gsf0kyneac/');
      if (ref && ref.focus) ref.focus();
    }

    else if (['RENEGOCIAR'].indexOf(produto.cdProduto) > -1) {
      SessionStore.saveGenericData('redirecionamentoAutenticacao', '#/servicos/reneg/renegociacao')
      window.location.hash = `#/clientes/auth`;
    }

    else if (['PRORROGAR'].indexOf(produto.cdProduto) > -1) {
      let ref = window.open('https://facilderesponder.wufoo.com/forms/s1a1iecb0jt6x1s/');
      if (ref && ref.focus) ref.focus();
    }
  }

  handleJaTemProposta = () => {
    SessionStore.saveGenericData('redirecionamentoAutenticacao', '#/produtos/veiculos/lista/propostas')
    window.location.hash = `#/clientes/auth`;
  }



  render() {
    let cdOrigem = SessionStore.getGenericData('cdOrigem');

    return <div className="animated fadeIn" >

      {this.props.aba === 'PRODUTOS' ? <div className="container">
        <img alt="Daycoval" className="img-carro" src={Environment.get('ASSETS') + `/img/img_carro2_sm.png`} />
        <h6 className="texto-img mt-3">Simule e encontre as melhores condições para financiar seu veículo ou fazer um empréstimo com garantia.</h6>
      </div> : null}

      {this.props.aba === 'SERVICOS' ? <div className="container">
        <img alt="Daycoval" className="img-carro" src={Environment.get('ASSETS') + `/img/img_carro_sm.png`} />
        <h6 className="texto-img mt-3">Através deste portal você terá acesso às melhores condições disponíveis para pagamento de valores vencidos.</h6>
      </div> : null}

      <div className="row row-botoes justify-content-center align-items-center py-4">
        {this.props.aba === 'PRODUTOS' ?
          this.state.produtos.map(p => {
            return <button key={p.cdProduto} className="btn m-2 p-0 botoes-group" onClick={e => this.handleProsseguir(p)} style={{ boxShadow: '3px 3px 2px #ccc', width: '100px', height: '100px', textAlign: 'center', borderRadius: '20px', backgroundColor: '#ffff' }}>
              <div className="row justify-content-center">
                <div className="col-8 p-0">
                  <img alt="Daycoval" style={{ width: '38px', height: '38px', paddingTop: '4px' }} src={Environment.get('ASSETS') + `/img/${p.cdProduto}.svg`} />
                  <span style={{ display: 'block', fontSize: '10px', lineHeight: '12px', fontWeight: 'bold', paddingBottom: '3px', textAlign: ' center', paddingTop: '10px' }}>{p.dsDestaque}</span>
                </div>
              </div>
            </button>
          })
          : this.state.servicos.map(s => {
            return <button key={s.cdProduto} className="btn m-2 p-0 botoes-group" onClick={e => this.handleProsseguir(s)} style={{ boxShadow: '3px 3px 2px #ccc', width: '100px', height: '100px', textAlign: 'center', borderRadius: '20px', backgroundColor: '#ffff' }}>
              <div className="row justify-content-center">
                <div className="col-8 p-0">
                  <img alt="Daycoval" style={{ width: '38px', height: '38px', paddingTop: '4px' }} src={Environment.get('ASSETS') + `/img/${s.cdProduto}.svg`} />
                  <span style={{ display: 'block', fontSize: '10px', lineHeight: '12px', fontWeight: 'bold', paddingBottom: '3px', textAlign: ' center', paddingTop: '10px' }}>{s.dsDestaque}</span>
                </div>
              </div>
            </button>
          })}
      </div>
      {this.props.aba === 'PRODUTOS' ?
        <Fragment>
          <div className="row my-4 justify-content-center">
            <div className="col-6 pr-1 m-0">
              <button className="btn btn-block botoes" onClick={this.handleJaTemProposta} style={{ backgroundColor: '#0373b0', borderRadius: '15px' }}>
                <span className="span-texto" style={{ color: '#fff', textAlign: "center", fontSize: '15px', lineHeight: '12px' }}>Acessar proposta<br />em andamento</span>
              </button>
            </div>
            <div className="col-6 pl-1 m-0">
              {cdOrigem && ['CRED_FACIL', 'VELOX', 'COTAFACIL', 'DINDINCRED'].indexOf(cdOrigem) > -1 ?
                <BtnWhatsApp numero={'5511943424207'} />
                : <BtnWhatsApp numero={'5511997985305'} />}
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <button className="btn btn-block" onClick={this.souLojista} style={{ backgroundColor: '#B0C4DE', borderRadius: '15px', height: '45px' }}>
                <span className="span-texto" style={{ color: '#fff', textAlign: "center", fontSize: '16px', lineHeight: '12px' }}>Portal Lojista</span>
              </button>

            </div>
          </div>
        </Fragment> :
        <Fragment>
          <div className="row my-4 justify-content-center">
            <div className="col-12 m-0">
              {cdOrigem && ['CRED_FACIL', 'VELOX', 'COTAFACIL', 'DINDINCRED'].indexOf(cdOrigem) > -1 ?
                <BtnWhatsApp numero={'5511943424207'} />
                : <BtnWhatsApp numero={'5511997985305'} />}
            </div>
          </div>
        </Fragment>
      }
    </div>
  }
}

export default Intro;
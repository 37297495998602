import React, { Fragment } from 'react';

/**
 * Tabela em estilo padronizado.
 * 
 * @param columnsNames Lista com nomes das colunas a serem exibidas. 
 *    Ex: ['Nome', 'E-mail', 'Acesso', 'Ativo', 'Idade']
 * 
 * @param rows Lista com os valores de cadas linha da tabela. 
 *    Cada linha deve ser um objeto contendo o valor e a classe CSS a ser utilizada. 
 *    Ex: [ 
 *          {
 *            valor: 'abc'
 *          }, 
 *          {
 *            valor:'def', 
 *            className:'badge badge-success'
 *          }
 *        ]
 *  
 * @param onListItemClick Função que será executada sempre que um item da lista for clicado.
 */
function Table({headerColor, legend, columnsNames = [], rows = [], actions = [], onListItemClick, noRowsMsg = '' }) {
  return <Fragment>

    {actions && actions.length > 0 ? <div className="col-12 text-right pb-2" style={{ marginTop: '-2.3rem' }}>
      {actions.map((a, index) => <button key={index} className="btn btn-outline-primary" onClick={a.callback}><i className="material-icons">{a.icon}</i> <span>{a.label}</span></button>)}
    </div> : null}

    {rows && rows.length > 0 ?

      <div>
        <p className="pt-4 m-0 mb-2"><strong>{legend}</strong></p>
        <table className="table table-sm table-hover">
          <thead>
            <tr className="table-success" >
              {columnsNames.map((c, index) => <th key={index} scope="col" className="border-0" style={headerColor ? { backgroundColor: headerColor } : {}}>{c} </th>)}
            </tr>
          </thead>
          <tbody >
            {rows.map((row, rowIndex) => {
              return <tr key={rowIndex} onClick={() => onListItemClick ? onListItemClick(rowIndex, row) : null} className="cursor-pointer">
                {row.map((column, columnIndex) => {
                  return <td key={columnIndex}>
                    {column.html ?
                      <div className={column.className} dangerouslySetInnerHTML={{ __html: column.html }} onClick={() => column.onClick ? column.onClick(column) : null}></div> :
                      <span className={column.className} onClick={() => column.onClick ? column.onClick(column) : null}>{column.value}</span>}
                  </td>
                })}
              </tr>
            })}
          </tbody>
        </table>
      </div>
      : <p className="text-center">{noRowsMsg}</p>}
  </Fragment>
}

export default Table;
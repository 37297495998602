import React, { Component } from 'react';

import OpcoesEscolha from './opcoes-escolha';

class SelecionarValor extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return <div className="row justify-content-center">

      {this.props.label ? <h5 className="text-center">{this.props.label}</h5> : null}

      {this.props.opcoesValores ?
        <div className="col-12">
          <OpcoesEscolha
            opcoes={this.props.opcoesValores}
            opcaoExtra={this.props.opcaoValorExtra ? this.props.opcaoValorExtra.dsLabel : null}
            onClickOpcao={this.props.onClickOpcao}
            onClickOpcaoExtra={this.props.onClickOpcaoExtra}
          />
        </div> : null}

      {this.state.err ? <p className="text-center text-muted m-2">{this.state.err}</p> : null}
      {this.props.mensagem ? <p className="text-center text-muted m-2">{this.props.mensagem}</p> : null}

      {this.props.onClickCancelar ? <div className="col-12 py-2">
        <button className="btn btn-block btn-secondary"
          style={{ paddingBottom: '10px', paddingTop: '10px' }}
          onClick={this.props.onClickCancelar}
        >Recomeçar</button>
      </div> : null}
    </div>
  }
}

export default SelecionarValor;
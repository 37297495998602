import React, { Fragment, useState } from 'react';
import TextUtil from '../../../utils/text';
import Table from "../../table";
import ContratosService from "../../../services/contratos.service";
import environment from '../../../environment';

export default function ViewPagamento({ contrato = {}, datasPagamento = [], pagamento, pagamentoExibicao, dtPagamento, ofertas = [], onClickVoltar = () => { }, onClickDataSelecionada = () => { }, onClickQuantidadeParcelasSelecionada = () => { }, onClickReneg = () => { }, onClickConfirmarNegociacao = () => { } }) {

  const [tela, setTela] = useState('RESUMO');
  // const [tela, setTela] = useState('AGRADECIMENTO');
  let parcelas = contrato.parcelas || [];

  function handleSelecionarDataPagamento(d) {
    setTela('RESUMO');
    onClickDataSelecionada(d);
  }

  function handleSelecionarQuantidadeParcelas(qtd) {
    setTela('RESUMO');
    onClickQuantidadeParcelasSelecionada(qtd);
  }

  async function handleConfirmarNegociacao() {
    await onClickConfirmarNegociacao();
  }

  return <Fragment>
    {tela === 'RESUMO' ? <Fragment>
      <Table rows={ContratosService.mapContratosToTable([contrato], [])} />
      <div className="container-fluid pt-3">
        <div className="col-12 px-0">
          {/* <h6 className="pl-1 mb-0 pb-3">Seu contrato possui <strong>{(parcelas || []).length} parcela{(parcelas || []).length > 1 ? 's' : ''}</strong> em aberto no valor total de <strong>{TextUtil.formatarMoeda('R$ ', (parcelas || []).reduce((p, c) => p + c.vlParcela, 0))}</strong></h6> */}
          <h6 className="pl-1 mb-0 pb-3">Seu contrato possui <strong>{(parcelas || []).length} parcela{(parcelas || []).length > 1 ? 's' : ''}</strong> em aberto no valor total de <strong>{TextUtil.formatarMoeda('R$ ', pagamentoExibicao.vlNegociacao)}</strong></h6>

          {pagamento.cdTipoNegociacao === 'QUITACAO' ?
            <h6 className="pl-1 pb-2">Você pode pagar {(parcelas || []).length > 1 ? 'todas as' : 'a'} parcela{(parcelas || []).length > 1 ? 's' : ''} em atraso na seguinte condição:</h6>
            : <h6 className="pl-1 pb-2">Você escolheu pagar somente <strong>{pagamento.qtParcelasNegociacao} parcela{pagamento.qtParcelasNegociacao > 1 ? 's' : ''}</strong> em atraso. Esta é a condição de pagamento:</h6>
          }

          <h6 className="pl-1 pb-2">Valor atualizado:
            <br /> <strong><span style={{ color: '#0373b0', fontSize: 22 }}>{TextUtil.formatarMoeda("R$", pagamento.vlNegociacao)}</span></strong>
          </h6>
          <h6 className="pl-1 pb-2">Vencimento:
            <br /><strong><span style={{ color: '#0373b0', fontSize: 20 }}>{dtPagamento}</span></strong>
          </h6>

          {pagamento.cdTipoNegociacao === 'QUITACAO' ?
            <h6 className="pl-1 pb-2">Desconto aproximado:
              <br /><strong><span style={{ color: '#0373b0', fontSize: 20 }}>{calcularDesconto(pagamentoExibicao.vlNegociacao, pagamento.vlNegociacao)}%</span></strong>
              <br /><small>*Você atingiu o maior desconto possível para pagar em {dtPagamento}. Não perca esta oportunidade!</small>
            </h6>
            : <h6 className="pl-1 pb-2">Parcelas selecionadas:
              <br /><strong><span style={{ color: '#0373b0', fontSize: 20 }}>{pagamento.qtParcelasNegociacao}</span></strong> de {parcelas.length}
              <br /><small>*Para ter acesso ao maior desconto possível, você precisa pagar todas as parcelas em atraso!</small>
            </h6>
          }

          <button type="button" className="btn btn-block btn-success mb-2" onClick={() => handleConfirmarNegociacao()}>
            <img alt="Daycoval" src={environment.get('ASSETS') + `/img/barcode_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
            Emitir boleto
          </button>

          <button type="button" className="btn btn-block btn-primary mb-2" onClick={() => setTela('DATA_PAGAMENTO')}>
            <img alt="Daycoval" src={environment.get('ASSETS') + `/img/calendar_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
            Mudar data de Vencimento
          </button>
          {(parcelas || []).length > 1 ?
            <button type="button" className="btn btn-block btn-primary mb-2" onClick={() => setTela('PARCELAS')}>
              <img alt="Daycoval" src={environment.get('ASSETS') + `/img/check_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
              Escolher parcelas para pagar
            </button>
            : null}
          {/* {ofertas && ofertas.length > 0 ? <button type="button" className="btn btn-block btn-success mb-2" onClick={() => onClickReneg()} >Renegociar meu contrato</button> : null} */}
          <button type="button" className="btn btn-block btn-secondary mb-2" onClick={() => onClickVoltar()} >Voltar</button>
        </div>
      </div>
    </Fragment> : null}

    {tela === 'DATA_PAGAMENTO' ? <div className="container-fluid pt-3">
      <h6 className="m-0 pb-3">Estas são as datas de vencimento disponíveis para pagar a{(parcelas || []).length > 1 ? 's' : ''} parcela{(parcelas || []).length > 1 ? 's' : ''} em atraso:</h6>
      {(datasPagamento || []).map((d) =>
        <button key={d} value={d} type="button" className="btn btn-block btn-primary mb-2 " onClick={() => handleSelecionarDataPagamento(d)}><strong>{d}</strong></button>)}
    </div> : null}

    {tela === 'PARCELAS' ? <div className="container-fluid pt-3">
      <h6 className="m-0 pb-3">Escolha quantas parcelas deseja pagar:</h6>
      {(parcelas || []).map((d, index) =>
        <button key={index + 1} value={index + 1} type="button" className="btn btn-block btn-primary mb-2 " onClick={() => handleSelecionarQuantidadeParcelas(index + 1)}><strong>{index + 1} parcela{(index + 1) > 1 ? 's' : ''}</strong></button>)}
    </div> : null}

  </Fragment>
}

function calcularDesconto(valorReferencia, valorCorrigido) {
  let desconto = 1 - valorCorrigido / valorReferencia;
  desconto = parseInt((Math.round(desconto * 10000) / 100) + "");
  return desconto;
}
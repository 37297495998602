import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Environment from '../environment';
import CoreService from '../services/core.service';
import SessionStore from '../services/session-store.service';
import TextUtils from '../utils/text';
import Estilos from './estilos-parceiros';

class Navbar extends Component {

  constructor(props) {
    super(props);
    let cliente = SessionStore.getGenericData('cliente');
    let dsCliente = cliente && cliente.dsCliente ? TextUtils.getFirstNameWithUpperCase(cliente.dsCliente) : '';
    let cdOrigem = SessionStore.getGenericData('cdOrigem') || '';
    let estilo = (Estilos[cdOrigem] || Estilos.default);

    this.state = {
      dsCliente,
      idCliente: cliente ? cliente.idCliente : undefined,
      url: this.props.url,
      layout: estilo.layout,
      parceiro: estilo.parceiro,
      cdOrigem,
    };

  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ permiteSalvarApp: CoreService.canInstallApp() })
    }, 2000);
  }

  instalarApp = async () => {
    await CoreService.installApp();
  }

  render() {

    if (this.state.isLoggedOut)
      return <Redirect to="/login" />

    return <nav className={`navbar navbar-expand-lg py-4 px-4`}
      style={{
        backgroundImage: `linear-gradient(to right, ${this.state.layout.cores.join(',')})`,
        justifyContent: this.state.layout.centralizarLogo ? 'center' : 'space-between',
      }}>
      <a className="pl-3" href="/">
        <img src={Environment.get('ASSETS') + `/img/${this.state.layout.logo}`} alt={`${this.state.layout.alt}`} style={{ height: '30px' }} ></img>
      </a>
      {this.state.layout.logo2 ? <a className="mt-2" href="/">
        <img alt={`${this.state.layout.alt}`} src={Environment.get('ASSETS') + `/img/${this.state.layout.logo2}`} className="" style={{ height: '40px' }}></img>
      </a> : null}
      {this.state.layout.exibirSaudacoes ? <div className={`col text-${this.state.layout.text}`}>
        <h6 className="m-0 text-light">
          {this.state.dsCliente ? `Olá ${this.state.dsCliente}!` : 'Bem-vindo ao'}
          <br />Banco Daycoval S.A.
        </h6>
      </div> : null}

      {this.state.permiteSalvarApp || CoreService.canInstallApp() ?
        <button className="btn btn-outline-light pb-2 pt-1 px-2" type="button" onClick={this.instalarApp} style={{ borderColor: this.state.layout.fundoClaro ? '#011e60' : '#fff' }}>
          <img src={Environment.get('ASSETS') + `/img/download_2${this.state.layout.fundoClaro ? '_dark' : ''}.svg`} style={{ width: 19 }} />
        </button> : null}

      {this.state.idCliente ?
        <a className="btn btn-outline-light pb-2 pt-1 px-2 ml-2 mr-left" type="button" href="#/clientes/perfil" style={{ backgroundColor: '#00000000', borderColor: this.state.layout.fundoClaro ? '#011e60' : '#fff' }}>
          <img alt="Daycoval" src={Environment.get('ASSETS') + `/img/profile${this.state.layout.fundoClaro ? '_dark' : ''}.svg`} style={{ width: 19 }} />
        </a> : null}
    </nav >
  }
}

export default Navbar;
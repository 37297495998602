import analyticsService from "./analytics.service";

class CoreService {

  canInstallApp() {
    return window.installAppPrompt && window.installAppPrompt.prompt
  }

  async installApp() {
    if (window.installAppPrompt) {
      try {
        await window.installAppPrompt.prompt();
        window.installAppPrompt.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('Aplcativo será instalado.');
            } else {
              console.log('Aplicativo não será instalado.');
            }
            window.installAppPrompt = null;
          });
      } catch (err) {
        console.error(err);
        analyticsService.sendException(err, false);
      }
    }
  }
}

export default new CoreService();
import React, { Component, Fragment } from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';

class TermosUsoPage extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || false;
    const name = target.name;
    this.setState({ [name]: value });
  }

  render() {
    return <Fragment>
      <Navbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-4">
            <h5>Termos de uso do Portal do Cliente (v1.0)</h5>

            <p className="mb-0 mt-4"><small>“Este documento é destinado aos utilizadores do Portal do Cliente”</small></p>
            <p className="mb-4"><small>“Este documento é complementar ao Termos de uso dos serviços e produtos Tarion-Beck”</small></p>

            <p>A utilização dos serviços e produtos do grupo Tarion-Beck deve seguir todas as políticas que lhe são disponibilizadas em cada um dos serviços. Atualmente Fácil de Financiar, Fácil de Pagar e Contrato.srv.br fazem parte do grupo de serviços e produtos oferecidos pela Tarion-Beck através do Portal do Cliente e podem ser acessados exclusivamente através dos sites (domínios) facildepagar.com.br, facildefinanciar.com.br e contrato.srv.br. Qualquer outro domínio que se intitule ou se caracterize como um destes serviços e produtos não faz parte do grupo Tarion-Beck e não são nossa responsabilidade.</p>
            <p>O Portal do Cliente é uma ferramenta desenvolvida para disponibilizar serviços e produtos das empresas clientes da Tarion-Beck aos consumidores de maneira personalizada e totalmente customizada. Por este motivo, ao acessar o Portal do Cliente, poderão ser exibidos cores, imagens e logotipos de marcas registradas em nome dos clientes, que foram previamente autorizadas para utilização. Em caso de dúvidas sobre a autenticidade das informações, envie e-mail para suporte@facildepagar.com.br.</p>
            <p>Os serviços e produtos disponíveis no Portal do Cliente apresentam alguns conteúdos que não pertencem ao grupo Tarion-Beck. Estes conteúdos são da inteira responsabilidade de nossos clientes que os disponibilizam. Poderemos avaliar o conteúdo para determinar se é ilegal ou se viola as nossas políticas e poderemos remover ou recusar apresentar conteúdo que, de uma forma razoável, considerarmos que viola as nossas políticas ou a lei. Não obstante, isso não significa necessariamente que avaliamos todos os conteúdos, pelo que não deve partir do princípio de que o fazemos.</p>
            <p>Os serviços e produtos oferecidos pelas empresas clientes aos seus consumidores podem estar em constante atualização, fazendo com que nova opções sejam adicionadas ou removidas sem aviso prévio.</p>
            <p>Os serviços e produtos oferecidos pelas empresas clientes aos seus consumidores podem envolver transações financeiras reais. Não nos responsabilizamos por decisões tomadas por você, por isso tenha consciência das suas decisões. Em caso de qualquer dúvida, pode ser enviado e-mail para suporte@facildepagar.com.br para maiores esclarecimentos.</p>
            <p>A utilização dos serviços e produtos disponíveis está sujeito a regras definidas previamente pelas empresas clientes, que podem permitir, negar ou restringir o acesso a qualquer recurso da maneira que julgarem mais adequada. Estarão disponíveis dados para contato direto com as empresas clientes que estão utilizando o Portal do Cliente para oferecer seus serviços e produtos.</p>
            <p>Não utilize indevidamente nenhum dos serviços e produtos disponíveis no Portal do Cliente. Não interfira com os nossos serviços e produtos, nem tente acessar os mesmos através de outros métodos que não a interface e as instruções claramente fornecidas nas interfaces. Pode utilizar os nossos serviços e produtos apenas nos termos permitidos por lei. Podemos suspender ou deixar de fornecer os serviços e produtos ao utilizador, caso este não respeite os nossos termos ou políticas de utilização ou se estivermos a investigar uma possível conduta imprópria.</p>
            <p>A utilização dos serviços e produtos disponíveis no Portal do Cliente não confere ao utilizador quaisquer direitos de propriedade intelectual sobre os serviços e produtos ou o conteúdo acedido. Não pode utilizar conteúdo dos serviços e produtos, salvo mediante autorização do respectivo proprietário ou em circunstâncias permitidas por lei. Os presentes termos de utilização não concedem o direito de utilizar nenhuma das marcas ou logotipos usados nos serviços e produtos. O utilizador não deverá remover, ocultar ou alterar qualquer aviso legal apresentado nos serviços e produtos ou juntamente com eles.</p>
            <p>Poderemos enviar anúncios relacionados aos serviços e produtos disponíveis no Portal do Cliente, mensagens administrativas e outras informações relacionados com a sua utilização dos serviços e produtos. Pode optar por não receber algumas dessas comunicações através de notificação para o e-mail suporte@facildepagar.com.br.</p>
            <p>Alguns dos serviços e produtos disponíveis no Portal do Cliente estão disponíveis em dispositivos móveis. Não utilize estes serviços de forma a que constituam uma distração e o impeçam de obedecer às leis rodoviárias ou de segurança.</p>
            
            <p><small>Data da última revisão: 28 de dezembro de 2021</small></p>
            <p className="mt-4"><small>“Este e qualquer outro documento oficial podem conter erros de digitação ou ambiguidade. Informe imediatamente sobre qualquer problema encontrado através do e-mail suporte@facildepagar.com.br”</small></p>

            <p className="mt-5">Leia os Termos e Políticas</p>
            <a href="#/termos-uso-tarion"><p className="mb-1">Termos de uso dos serviços e produtos Tarion-Beck</p></a>
            <a href="#/termos-uso"><p className="mb-1">Termos de uso do Portal do Cliente</p></a>
            <a href="#/politica-privacidade"><p className="mb-1">Política de Privacidade do Portal do Cliente</p></a>
            <a href="#/politica-dados"><p className="mb-1">Política de Dados do Portal do Cliente</p></a>
          </div>
          <div className="col-12 px-0">
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  }
}

export default TermosUsoPage;
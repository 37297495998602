import React, { Fragment } from 'react';

import Paragrafo from './paragrafo';
import Progresso from './progresso';
import ParagrafoEditavel from './paragrafo-editavel';
import AdicionarArquivo from './adicionar-arquivo';
import ListaArquivos from './lista-arquivos';
import ListaSimples from './lista-simples';
import Camera from './modal-camera' 
import Localizacao from './localizacao' 

export default function Componentes({ componentes, input, onDadosAlterados = async() => { }, onEvento = async () => { } }) {
  return <Fragment>
    {componentes.map((componente, index) => {
      if (componente.tipo == 'paragrafo') return <Paragrafo key={index} paragrafo={componente} />
      else if (componente.tipo == 'progresso') return <Progresso key={index} progresso={componente} />
      else if (componente.tipo == 'paragrafo-editavel') return <ParagrafoEditavel key={index} componente={componente} input={input} onDadosAlterados={onDadosAlterados} onEvento={onEvento} />
      else if (componente.tipo == 'lista-arquivos') return <ListaArquivos key={index} componente={componente} onEvento={onEvento} />
      else if (componente.tipo == 'adicionar-arquivo') return <AdicionarArquivo key={index} componente={componente} onEvento={onEvento} />
      else if (componente.tipo == 'lista-simples') return <ListaSimples key={index} componente={componente} onEvento={onEvento} />
      else if (componente.tipo == 'camera') return <Camera key={index} componente={componente} onEvento={onEvento} input={input} /> 
      else if (componente.tipo == 'localizacao') return <Localizacao key={index} componente={componente} onEvento={onEvento} input={input} /> 
      else return <Fragment key={index}></Fragment>;
    })}
  </Fragment >
}
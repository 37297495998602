import React from 'react';

import AbaProposta from './aba-proposta';

const ABAS = [
  {
    id: 'PRODUTOS',
    label: 'Produtos',
    //icon: 'info.svg'
  },
  {
    id: 'SERVICOS',
    label: 'Serviços',
    //icon: 'picture.svg',
  },
  
]

function AbaInicial({ abaAtiva = '', onClickAba = () => { } }) {
  return <div className="row justify-content-center" style={{ background: '#B0C4DE' }}>
    {ABAS.map(aba => 
      <AbaProposta 
      key={aba.id}/*  icon={aba.icon}  */
      label={aba.label} 
      active={abaAtiva === aba.id} onClickAba={e => onClickAba(aba.id)} />)}
  </div>
}

export default AbaInicial;
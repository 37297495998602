import React, { Component } from 'react';

export class ModalEnviarProposta extends Component {
  render() {
    return <div className="modal" aria-hidden="false" style={{ backgroundColor: '#0000002f', display: this.props.show ? 'block' : 'none' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{this.props.title}</h5>
            <button type="button" className="close" aria-label="Close" onClick={(e) => this.props.cancelCallback ? this.props.cancelCallback(e) : null}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{this.props.message}</p>
          </div>
          <div className="modal-footer">
            {this.props.confirmText ? <button type="button" className="btn btn-primary" onClick={(e) => this.props.confirmCallback ? this.props.confirmCallback(e) : null}>{this.props.confirmText}</button> : null}
            {this.props.cancelText ? <button type="button" className="btn btn-secondary" onClick={(e) => this.props.cancelCallback ? this.props.cancelCallback(e) : null}>{this.props.cancelText}</button> : null}
          </div>
        </div>
      </div>
    </ div>
  }
}
import React, { Component, Fragment } from 'react';
import SessionStore from '../../../services/session-store.service';
import SimulacoesService from '../../../services/simulacoes.service';
import Environment from '../../../environment';
import Loader from '../../../components/loader';
import Msgs from '../../../components/msgs';
import analyticsService from '../../../services/analytics.service';

class ConfirmarPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loader: {},    
      proposta: null,
      etapa : 'CADASTRAR_PROPOSTA',
    };
  }

  async componentDidMount() {
    this.cadastrarSimulacao();
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  cadastrarSimulacao = async () => {
    try {
      let { cdProduto } = SessionStore.getGenericData('produtoSelecionado') || {};
      let { vlAnoModelo, idVeiculo } = SessionStore.getGenericData('garantia') || {};
      let { estado: cdUf } = SessionStore.getGenericData('clienteSimulacao') || {};
      let { qtParcelas } = SessionStore.getGenericData('parcelasSelecionado') || {};
      let vlSolicitado = SessionStore.getGenericData('valorSelecionado');
      let vlEntrada = SessionStore.getGenericData('entradaSelecionado') || 0;
      let cdOrigem = SessionStore.getGenericData('cdOrigem') || undefined;

      let simulacao = await SimulacoesService.cadastrarSimulacao(cdProduto, vlSolicitado, qtParcelas, vlEntrada, vlAnoModelo, idVeiculo, cdUf, cdOrigem);
      if (!simulacao.opcoes || simulacao.opcoes.length < 1)
        return this.setState({
          cliente: {
            digitosCpf: '',
            dataNascimento: '',
            numeroCelular: '',
          }, error: simulacao.err || 'Ocorreu algum problema. Por favor, tente novamente.'
        });

      let opcao = simulacao.opcoes.filter(o => o.qtParcelas === qtParcelas);
      if (!opcao || opcao.length < 1) {
        return this.setState({
          cliente: {
            digitosCpf: '',
            dataNascimento: '',
            numeroCelular: '',
          }, error: opcao.err || 'Ocorreu algum problema. Por favor, tente novamente.'
        });
      } else {
        opcao = opcao[0];
      }

      let result = await SimulacoesService.confirmarSimulacao(opcao.cdSimulacao, opcao.cdOpcao);

      if (!result || !result.proposta || !result.proposta.cdProposta)
        return this.setState({
          cliente: {
            digitosCpf: '',
            dataNascimento: '',
            numeroCelular: '',
          }, error: result.err || 'Ocorreu algum problema. Por favor, tente novamente.'
        });

      SessionStore.clearGenericData('produtoSelecionado');
      SessionStore.clearGenericData('garantia');
      SessionStore.clearGenericData('parcelasSelecionado');
      SessionStore.clearGenericData('valorSelecionado');
      SessionStore.clearGenericData('entradaSelecionado');

      this.setState({ proposta: result.proposta });
      if (this.state.proposta.cdProposta) {
        return window.location.hash = `/produtos/veiculos/cp/${this.state.proposta.cdProposta}`;
      } else {
        return window.location.hash = '/'
      }

    } catch (err) {
      console.error(err);
      analyticsService.sendException(err, false);
    }

  }


  render() {
    return <Fragment>

      <Loader message={this.state.loader.message} show={this.state.loader.show} />

      <div className="container-fluid">
        <div className="row justify-content-center" >
          {this.state.tokenInvalido ? <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <Msgs error="Você não possui permissão para visualizar esta proposta." />
          </div> :
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <div className="animated fadeIn py-4" >

                <Msgs info={this.state.msg} error={this.state.error} />

                {this.state.etapa === 'CADASTRAR_PROPOSTA' ? <Fragment>
                  <h5 className="text-center">Estamos cadastrando a proposta.</h5>
                  <h5 className="text-center">Por favor, aguarde...</h5>
                  <p className="text-center"><img alt="Daycoval" src={Environment.get('ASSETS') + '/img/search.svg'} style={{ height: '90px', paddingTop: '5px' }} className="animated pulse infinite slow" /></p>
                </Fragment> : null}

              </div>
            </div>
          }
        </div>
      </div>
    </Fragment>
  }
}

export default ConfirmarPage;
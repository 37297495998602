import React, { Fragment } from 'react';

/**
 * Tabela em estilo padronizado.
 * 
 * @param columnsNames Lista com nomes das colunas a serem exibidas. 
 *    Ex: ['Nome', 'E-mail', 'Acesso', 'Ativo', 'Idade']
 * 
 * @param rows Lista com os valores de cadas linha da tabela. 
 *    Cada linha deve ser um objeto contendo o valor e a classe CSS a ser utilizada. 
 *    Ex: [ 
 *          {
 *            valor: 'abc'
 *          }, 
 *          {
 *            valor:'def', 
 *            className:'badge badge-success'
 *          }
 *        ]
 *  
 * @param onListItemClick Função que será executada sempre que um item da lista for clicado.
 */
function Table({ columnsNames = [], rows = [], actions = [], onListItemClick, noRowsMsg = '', qtPaginas = 1, paginaAtual = 1, onClickPagina, maxHeight, small = false }) {
  return <Fragment>

    {actions && actions.length > 0 ? <div className="col-12 text-right" style={{ marginTop: '-2.3rem' }}>
      {actions.map((a, index) => <button key={index} className="btn btn-outline-primary" onClick={a.callback}><i className="material-icons">{a.icon}</i> <span>{a.label}</span></button>)}
    </div> : null}

    {rows && rows.length > 0 ?
      <div className=" card card-small">
        <div className=" p-0 card-body" style={maxHeight ? { maxHeight, overflowY: 'scroll', scrollBehavior: 'smooth' } : {}}>
          <table className={`table table-responsive-sm mb-0 table-hover ${small ? 'table-sm' : ''}`}  >
            
            {columnsNames && columnsNames.length > 0 ? <thead>
              <tr>
                {columnsNames.map((c, index) => <th key={index} scope="col" className="border-0">{c}</th>)}
              </tr>
            </thead> : null}

            <tbody>
              {rows.map((row, rowIndex) => {
                return <tr key={rowIndex} onClick={() => onListItemClick ? onListItemClick(rowIndex, row) : null} className="cursor-pointer">
                  {row.map((column, columnIndex) => {
                    return <td key={columnIndex}>
                      {column.html ?
                        <div className={column.className} dangerouslySetInnerHTML={{ __html: column.html }} onClick={() => column.onClick ? column.onClick(column) : null}></div> :
                        <span className={column.className} onClick={() => column.onClick ? column.onClick(column) : null}>{column.value}</span>}
                    </td>
                  })}
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
      : <p className="text-center">{noRowsMsg}</p>}
    {qtPaginas > 1 ?
      <div className="mb-4 mt-2 row mx-0 justify-content-end">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            {paginaAtual > 1 ? <li className="page-item" onClick={() => onClickPagina(paginaAtual - 1)}><span className="page-link cursor-pointer">Anterior</span></li> : null}
            {paginaAtual > 3 ? <li className="page-item" onClick={() => onClickPagina(paginaAtual - 3)}><span className="page-link cursor-pointer">{paginaAtual - 3}</span></li> : null}
            {paginaAtual > 2 ? <li className="page-item" onClick={() => onClickPagina(paginaAtual - 2)}><span className="page-link cursor-pointer">{paginaAtual - 2}</span></li> : null}
            {paginaAtual > 1 ? <li className="page-item" onClick={() => onClickPagina(paginaAtual - 1)}><span className="page-link cursor-pointer">{paginaAtual - 1}</span></li> : null}
            <li className="page-item active"><span className="page-link">{paginaAtual}</span></li>
            {(paginaAtual + 1) <= qtPaginas ? <li className="page-item" onClick={() => onClickPagina(paginaAtual + 1)}><span className="page-link cursor-pointer">{paginaAtual + 1}</span></li> : null}
            {(paginaAtual + 2) <= qtPaginas ? <li className="page-item" onClick={() => onClickPagina(paginaAtual + 2)}><span className="page-link cursor-pointer">{paginaAtual + 2}</span></li> : null}
            {(paginaAtual + 3) <= qtPaginas ? <li className="page-item" onClick={() => onClickPagina(paginaAtual + 3)}><span className="page-link cursor-pointer">{paginaAtual + 3}</span></li> : null}
            {paginaAtual < qtPaginas ? <li className="page-item" onClick={() => onClickPagina(paginaAtual + 1)}><span className="page-link cursor-pointer">Próxima</span></li> : null}
          </ul>
        </nav>
      </div> : null}
  </Fragment>
}

export default Table;
import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from '../../components/navbar';
// import withTracker from "../../withTracker";

import Simular from "./veiculos/simular"
import CadastroPage from "./veiculos/cadastro"
import ConfirmarPage from "./veiculos/confirmar";
import ListaPropostas from "./veiculos/list";

class Produtos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aba: 'Produtos'
        }
    }

    render() {
        return <Fragment>
            <Navbar aba={this.state.aba} />
            <Switch>
                <Route path="/produtos/veiculos/simular/:acao" exact render={props => <Simular {...props} />} />
                <Route path="/produtos/veiculos/confirmar" exact render={props => <ConfirmarPage {...props} />} />
                <Route path="/produtos/veiculos/lista/propostas" exact render={props => <ListaPropostas {...props} />} />
                <Route path="/produtos/veiculos/cp/:cdProposta" exact render={props => <CadastroPage {...props} />} />
            </Switch>
        </Fragment>
    }
}

export default Produtos;
import React, {Fragment} from 'react';


export default function Progresso({ progresso }) {
    return (
        <div className="container mb-2">
            {progresso.props.steps > 0 ? <Fragment>
                <div className={progresso.props.steps >= 1 ? 'progressBar filled' : progresso.props.totalSteps >= 1 ? 'progressBar' : ''}></div>
                <div className={progresso.props.steps >= 2 ? 'progressBar filled' : progresso.props.totalSteps >= 2 ? 'progressBar' : ''}></div>
                <div className={progresso.props.steps >= 3 ? 'progressBar filled' : progresso.props.totalSteps >= 3 ? 'progressBar' : ''}></div>
                <div className={progresso.props.steps >= 4 ? 'progressBar filled' : progresso.props.totalSteps >= 4 ? 'progressBar' : ''}></div>
                <div className={progresso.props.steps >= 5 ? 'progressBar filled' : progresso.props.totalSteps >= 5 ? 'progressBar' : ''}></div>
                <div className={progresso.props.steps >= 6 ? 'progressBar filled' : progresso.props.totalSteps >= 6 ? 'progressBar' : ''}></div>     
            </Fragment> : null}
        </div>
    )
}
import React, { Component, Fragment } from 'react';

import AbaFormulario from '../../../../components/aba-formulario';
import CadastroProposta from '../../../../components/formularios/produtos/veiculos/cadastro-proposta';
import CadastroComprovantes from '../../../../components/formularios/produtos/veiculos/cadastro-comprovantes';

const CADASTRO = [
  {
    id: 'CADASTRO',
    label: 'Cadastro da proposta'
  },
];

const COMPRADOR = [
  {
    id: 'CLIENTE',
    label: 'Dados Pessoais'
  },
  {
    id: 'PROFISSIONAIS',
    label: 'Dados Profissionais'
  },
  {
    id: 'VENDEDOR',
    label: 'Dados vendedor'
  },
]

const COMPRADOR_CVQ = [
  {
    id: 'CLIENTE',
    label: 'Dados Pessoais'
  },
  {
    id: 'PROFISSIONAIS',
    label: 'Dados Profissionais'
  },
  {
    id: 'VEICULO',
    label: 'Dados do Veículo'
  },
]

const VENDEDOR = [
  {
    id: 'VEICULO',
    label: 'Dados do Veículo'
  },
  {
    id: 'PAGAMENTO',
    label: 'Dados de pagamento'
  },
]

const COMPROVANTES = [
  {
    id: 'ENVIO_COMPROVANTES',
    label: 'Envio de Comprovantes'
  }
]

class CadastroNovo extends Component {
  constructor(props) {
    super(props);
    let formulariosEtapa = []
    let formularioAtivo =
      props.etapaProcesso === 'CADASTRO' ? CADASTRO[0] :
        props.etapaProcesso === 'DADOS_COMPRADOR' ? props.produto == 'CVQ' ? COMPRADOR_CVQ[0] : COMPRADOR[0] :
          props.etapaProcesso === 'DADOS_VENDEDOR' ? VENDEDOR[0] :
            props.etapaProcesso === 'ENVIO_COMPROVANTES' ? COMPROVANTES[0] :
              formulariosEtapa[0];

    this.state = {
      formulariosEtapa,
      formularioAtivo,
      forceFileUpload: false,
    }
  }

  handleClickFormulario = (formulario) => {
    this.setState({ formularioAtivo: formulario });
  }

  handleDadosPropostaAlterados = (proposta) => {
    if (this.props.onDadosPropostaAlterados)
      this.props.onDadosPropostaAlterados(proposta);
  }

  handleEnviarPropostaAprovacao = () => {
    if (this.props.onClickEnviarPropostaAprovacao)
      this.props.onClickEnviarPropostaAprovacao();
  }

  handleRecuperarDadosProposta = async () => {
    if (this.props.onRecuperarDadosProposta)
      await this.props.onRecuperarDadosProposta(this.props.proposta.cdProposta);
  }

  handleCampoAlterado = (campos, callback = () => { }) => {
    let formularioAtivo = this.state.formularioAtivo;
    formularioAtivo.campos = campos;
    this.setState({ formularioAtivo }, callback);
  }

  handleProximoFormulario = () => {
    let formularioAtivo;

    // CVQ
    if (this.props.produto == 'CVQ') {
      if (this.props.etapaProcesso === 'DADOS_COMPRADOR') {
        if (this.state.formularioAtivo.id === 'CLIENTE')
          formularioAtivo = COMPRADOR_CVQ[1];
        else if (this.state.formularioAtivo.id === 'PROFISSIONAIS')
          formularioAtivo = COMPRADOR_CVQ[2];
        else if (this.state.formularioAtivo.id === 'VEICULO')
          formularioAtivo = COMPRADOR_CVQ[2];
      }
    }

    // Financiamentos
    else {
      if (this.props.etapaProcesso === 'DADOS_COMPRADOR') {
        if (this.state.formularioAtivo.id === 'CLIENTE')
          formularioAtivo = COMPRADOR[1];
        else if (this.state.formularioAtivo.id === 'PROFISSIONAIS')
          formularioAtivo = COMPRADOR[2];
        else if (this.state.formularioAtivo.id === 'VENDEDOR')
          formularioAtivo = COMPRADOR[2];
        else if (this.state.formularioAtivo.id === 'VEICULO')
          formularioAtivo = VENDEDOR[2];
        else if (this.state.formularioAtivo.id === 'PAGAMENTO')
          formularioAtivo = VENDEDOR[2];
      }
      else if (this.props.etapaProcesso === 'DADOS_VENDEDOR') {
        if (this.state.formularioAtivo.id === 'VEICULO')
          formularioAtivo = VENDEDOR[1];
        else if (this.state.formularioAtivo.id === 'PAGAMENTO')
          formularioAtivo = VENDEDOR[1];
      }
    }

    this.setState({ formularioAtivo }, () => {
      window.scrollTo({ top: 0, left: 0 })
    });
  }

  render() {

    return <Fragment>

      <div className="row justify-content-center mb-2" style={{ boxShadow: '0px 1px 4px rgba(0,0,0,0.4)' }}>

        {/* Formulários de cadastro fixos */}
        {this.props.etapaProcesso === 'DADOS_COMPRADOR' ?
          this.props.produto == 'CVQ' ?
            COMPRADOR_CVQ.map(aba => <AbaFormulario
              key={aba.id}
              id={aba.id}
              label={aba.label}
              active={this.state.formularioAtivo === aba}
              onClickAba={() => this.handleClickFormulario(aba)}
            />)
            : COMPRADOR.map(aba => <AbaFormulario
              key={aba.id}
              id={aba.id}
              label={aba.label}
              active={this.state.formularioAtivo === aba}
              onClickAba={() => this.handleClickFormulario(aba)}
            />) : null}
      </div>

      <div className="row justify-content-center mb-2" >
        {this.props.etapaProcesso === 'DADOS_VENDEDOR' ? VENDEDOR.map(aba => <AbaFormulario
          key={aba.id}
          id={aba.id}
          label={aba.label}
          active={this.state.formularioAtivo === aba}
          onClickAba={() => this.handleClickFormulario(aba)}
        />) : null}
      </div>

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'CADASTRO' ? <CadastroProposta
        flPermiteEditar={this.props.flPermiteEditar}
        proposta={this.props.proposta}
        campos={this.props.camposFormularios.PRECADASTRO}
        onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
      /> : null}

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'CLIENTE' ? <CadastroProposta
        flPermiteEditar={this.props.flPermiteEditar}
        proposta={this.props.proposta}
        campos={this.props.camposFormularios.COMPRADOR}
        onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
      /> : null}

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'PROFISSIONAIS' ? <CadastroProposta
        flPermiteEditar={this.props.flPermiteEditar}
        proposta={this.props.proposta}
        campos={this.props.camposFormularios.PROFISSIONAIS}
        onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
      /> : null}

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'VENDEDOR' ? <Fragment>
        <h6>ATENÇÃO: informe abaixo os dados de contato do Vendedor (Nome e Número de WhatsApp) para que possamos solicitar a ele os dados do veículo.<br />Sem esses dados não poderemos prosseguir com a aprovação da proposta.</h6>
        <CadastroProposta
          flPermiteEditar={this.props.flPermiteEditar}
          proposta={this.props.proposta}
          campos={this.props.camposFormularios.VENDEDOR}
          flAutorizarContato={true}
          onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
        />
      </Fragment> : null}

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'VEICULO' ? <CadastroProposta
        flPermiteEditar={this.props.flPermiteEditar}
        proposta={this.props.proposta}
        campos={this.props.camposFormularios.VEICULO}
        onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
      /> : null}

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'PAGAMENTO' ? <CadastroProposta
        flPermiteEditar={this.props.flPermiteEditar}
        flAvisoPagamento={true}
        proposta={this.props.proposta}
        campos={this.props.camposFormularios.PAGAMENTO}
        onDadosPropostaAlterados={this.handleDadosPropostaAlterados}
      /> : null}

      {this.state.formularioAtivo && this.state.formularioAtivo.id === 'ENVIO_COMPROVANTES' ? <CadastroComprovantes
        flPermiteEditar={this.props.flPermiteEditar}
        comprovantes={this.props.proposta.comprovantesProposta}
        proposta={this.props.proposta}
        comprovantesProduto={this.props.proposta.comprovantesProduto}
        comprovantesEnviar={this.props.proposta.comprovantesEnviar}
        onClickEnviarPropostaAprovacao={this.handleEnviarPropostaAprovacao}
        onRecuperarDadosProposta={this.handleRecuperarDadosProposta}
      /> : null}


      {this.props.proposta && this.props.flPermiteEditar && (['RECEBIDA', 'CADASTRO'].indexOf(this.props.proposta.cdStatus) > -1) ?
        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <button className="btn btn-block btn-primary py-2" onClick={this.props.onSalvarDados}>Salvar dados</button>
        </div> : null}

      {this.props.proposta && this.props.flPermiteEditar && ['DADOS_COMPRADOR', 'DADOS_VENDEDOR'].includes(this.props.proposta.cdStatus) ?
        <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          {
            (this.props.produto == 'CVQ' && this.state.formularioAtivo.id === 'VEICULO') ||
              (this.state.formularioAtivo.id === 'VENDEDOR' || this.state.formularioAtivo.id === 'PAGAMENTO') ?
              <button className="btn btn-block btn-primary py-2" onClick={this.props.onSalvarDados}>Salvar Dados</button>
              : <button className="btn btn-block btn-primary py-2" onClick={this.handleProximoFormulario}>Próximo</button>
          }
        </div> : null}
    </Fragment >
  }
}

export default CadastroNovo;
import React, { Component, Fragment } from 'react';
import SessionStore from '../../services/session-store.service';
import ClientesService from '../../services/clientes.service';

import CampoFormatado from '../../components/campo-formatado';

import AbaProposta from '../../components/aba-proposta';
import Loader from '../../components/loader';
import Msgs from '../../components/msgs';
import authService from '../../services/auth.service';
import sessionStoreService from '../../services/session-store.service';
import moment from 'moment';
import analyticsService from '../../services/analytics.service';

class PerfilCliente extends Component {

  constructor(props) {
    super(props);

    analyticsService.sendPageView('clientes/perfil', 'Perfil do Cliente');

    this.state = {
      loader: {},
      cliente: SessionStore.getGenericData('cliente') || {},
      error: undefined,
      aba: 'DADOS_CADASTRAIS'
      // tela: 'AUTORIZACOES'
    };
  }

  componentDidMount = async () => {
    if (!this.state.cliente || !this.state.cliente.idCliente)
      return window.location.hash = '/inicio';

    await this.loadData();
  }

  handleVoltarAoMenu = () => {
    window.location.hash = `/inicio`;
  }

  handleSair = () => {
    analyticsService.sendEvent('logout');
    authService.clearAuthData();
    sessionStoreService.clearSession();
    window.location.hash = `/inicio`;
  }

  loadData = async () => {
    this.setState({ loader: { show: true, message: 'Recuperando dados...' } });
    try {
      let cliente = await ClientesService.recuperar();
      sessionStoreService.saveGenericData('cliente', cliente);
      this.setState({ cliente });
    } catch (err) {
      console.error(err);
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  handleAlterarTela = (aba) => {
    this.setState({ aba: aba })
  }

  handleSalvarDados = async () => {
    analyticsService.sendEvent('salvou_dados_perfil');
    this.setState({ loader: { show: true, message: 'Salvando dados...' } });
    try {
      await ClientesService.persistirPerfil(this.state.cliente);
      await this.loadData();
    } catch (err) {
      console.error(err);
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  handleInputChange = (campo, valor) => {
    let cliente = this.state.cliente;
    cliente[campo] = valor;
    this.setState({ cliente });
  }

  handleAutorizacaoChange = (event) => {
    const target = event.target;
    const name = target.name;
    let cliente = this.state.cliente;
    cliente[name] = target.checked ? moment.utc().format() : null;
    this.setState({ cliente });
  }

  render() {
    return <Fragment>
      <AbaProposta
        key={this.state.aba === 'DADOS_CADASTRAIS' ? 'Meus dados'
          : this.state.aba === 'AUTORIZACOES' ? 'Minhas autorizações'
            : ''}
        /*  icon={aba.icon}  */
        label={this.state.aba === 'DADOS_CADASTRAIS' ? 'Meus dados'
          : this.state.aba === 'AUTORIZACOES' ? 'Minhas autorizações'
            : ''}
        active={true}
      />
      <Loader message={this.state.loader.message} show={this.state.loader.show} />
      <div className="container-fluid">
        <div className="row justify-content-center" >

          {this.state.aba === 'DADOS_CADASTRAIS' ? <Fragment>
            <div className="col-12 py-2">
              <p className="mb-0 pb-1 text-muted">Este são seus dados cadastrados no aplicativo.</p>
              <p className="mb-0 pb-1 text-muted">O CPF não pode ser alterado, pois é sua chave de acesso.</p>
              <p className="mb-0 pb-1 text-muted">Mantenha seu WhatsApp e e-mail atualizados, pois é para eles que enviamos notificações importantes.</p>
              <Msgs info={this.state.msg} error={this.state.error} />
            </div>

            <div className="col-12 px-0 pb-2">
              <CampoFormatado
                key="cdCliente"
                id="cdCliente"
                valor={this.state.cliente.cdCliente}
                label="CPF"
                placeholder="CPF"
                formatacao={'mascara'}
                mascara={'999.999.999-99'}
                tipo="cpf"
                desabilitado={true}
                obrigatorio={true}
                onChange={(valor) => { this.handleInputChange('cdCliente', valor) }}
              />

              <CampoFormatado
                key="dsCliente"
                id="dsCliente"
                valor={this.state.cliente.dsCliente}
                label="Nome completo"
                placeholder="Nome completo"
                obrigatorio={true}
                onChange={(valor) => { this.handleInputChange('dsCliente', valor) }}
              />

              <CampoFormatado
                key="cdCelular"
                id="cdCelular"
                valor={this.state.cliente.cdCelular}
                label="WhatsApp"
                placeholder="WhatsApp"
                formatacao={'mascara'}
                mascara={'(99) 99999-9999'}
                tipo="telefone"
                obrigatorio={true}
                onChange={(valor) => { this.handleInputChange('cdCelular', valor) }}
              />

              <CampoFormatado
                key="dsEmail"
                id="dsEmail"
                valor={this.state.cliente.dsEmail}
                label="E-mail"
                placeholder="E-mail"
                tipo="email"
                obrigatorio={true}
                onChange={(valor) => { this.handleInputChange('dsEmail', valor) }}
              />
            </div>
          </Fragment> : null}

          {this.state.aba === 'AUTORIZACOES' ? <Fragment>
            <div className="col-12 py-2">
              <p className="mb-0 pb-1 text-muted">Estas são as autorizações que você concedeu ao sistema.</p>
              <p className="mb-0 pb-1 text-muted">Você pode revogar as autorizações há qualquer momento.</p>
              <p className="mb-0 pb-1 text-muted">Lembre-se de que sem estas autorizações, nós não podemos enviar informações importantes sobre os assuntos.</p>
              <Msgs info={this.state.msg} error={this.state.error} />
            </div>

            <div className="col-12 pb-2">
              <div className="form-check pt-2">
                <input type="checkbox" className="form-check-input" id="dtAutNotificarFinanciamento" name="dtAutNotificarFinanciamento" checked={this.state.cliente.dtAutNotificarFinanciamento ? true : false} onChange={this.handleAutorizacaoChange} />
                <label className="form-check-label-center" htmlFor="dtAutNotificarFinanciamento">
                  Notificações sobre minhas propostas de financiamento.
                  <small style={{ display: 'block', color: '#99C' }}>{this.state.cliente.dtAutNotificarFinanciamento ? `Autorizado em ${moment.utc(this.state.cliente.dtAutNotificarFinanciamento).format('DD/MM/YYYY HH:mm')}` : ''}</small>
                </label>
              </div>
              <div className="form-check pt-2">
                <input type="checkbox" className="form-check-input" id="dtAutNotificarCobranca" name="dtAutNotificarCobranca" checked={this.state.cliente.dtAutNotificarCobranca ? true : false} onChange={this.handleAutorizacaoChange} />
                <label className="form-check-label-center" htmlFor="dtAutNotificarCobranca">
                  Notificações sobre minhas parcelas em atraso.
                  <small style={{ display: 'block', color: '#99C' }}>{this.state.cliente.dtAutNotificarCobranca ? `Autorizado em ${moment.utc(this.state.cliente.dtAutNotificarCobranca).format('DD/MM/YYYY HH:mm')}` : ''}</small>
                </label>
              </div>
              <div className="form-check pt-2">
                <input type="checkbox" className="form-check-input" id="dtAutEnvioContrato" name="dtAutEnvioContrato" checked={this.state.cliente.dtAutEnvioContrato ? true : false} onChange={this.handleAutorizacaoChange} />
                <label className="form-check-label-center" htmlFor="dtAutEnvioContrato">
                  Notificações sobre meus contratos disponíveis para baixar.
                  <small style={{ display: 'block', color: '#99C' }}>{this.state.cliente.dtAutEnvioContrato ? `Autorizado em ${moment.utc(this.state.cliente.dtAutEnvioContrato).format('DD/MM/YYYY HH:mm')}` : ''}</small>
                </label>
              </div>
              <div className="form-check pt-2">
                <input type="checkbox" className="form-check-input" id="dtAutEnvioCarne" name="dtAutEnvioCarne" checked={this.state.cliente.dtAutEnvioCarne ? true : false} onChange={this.handleAutorizacaoChange} />
                <label className="form-check-label-center" htmlFor="dtAutEnvioCarne">
                  Notificações sobre meus carnês disponíveis para baixar.
                  <small style={{ display: 'block', color: '#99C' }}>{this.state.cliente.dtAutEnvioCarne ? `Autorizado em ${moment.utc(this.state.cliente.dtAutEnvioCarne).format('DD/MM/YYYY HH:mm')}` : ''}</small>
                </label>
              </div>
            </div>
          </Fragment> : null}

          <div className="col-12 py-2">
            <button className="btn btn-block btn-success" onClick={this.handleSalvarDados}>Salvar dados</button>
            {this.state.aba !== 'DADOS_CADASTRAIS' ? <button className="btn btn-block btn-primary" onClick={() => this.handleAlterarTela('DADOS_CADASTRAIS')}>Ver meus dados cadastrais</button> : null}
            {this.state.aba !== 'AUTORIZACOES' ? <button className="btn btn-block btn-primary" onClick={() => this.handleAlterarTela('AUTORIZACOES')}>Ver minhas autorizações</button> : null}
            <button className="btn btn-block btn-primary" onClick={this.handleVoltarAoMenu}>Voltar ao menu inicial</button>
            <button className="btn btn-block btn-link" onClick={this.handleSair}>Sair do aplicativo (Logout)</button>
          </div>
        </div>
      </div>
    </Fragment >
  }
}

export default PerfilCliente;
import React, { Component } from "react";

class FormCancelarNegociacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dsMotivo: props.dsMotivo || '',
            flNovoEndereco: props.flNovoEndereco || '',
            flAceito: props.flAceito || '',
        }
    }

    handleSelectChange = (event) => {
        const target = event.target;
        const value = target.value || '';
        const name = target.name;
        this.setState({ [name]: value });
    }

    handleCheckboxChange = (event) => {
        const target = event.target;
        const value = target.checked || '';
        const name = target.name;
        this.setState({ [name]: value });
    }

    permiteConcluir = () => {
        if (!this.state.flAceito || !this.state.dsMotivo) {
            return false;
        }

        return true;
    }

    handleConfirmarReneg = () => {
        if (this.props.onClickConfirmarReneg)
            this.props.onClickConfirmarReneg(this.state);
    }
    handleVoltar = () => {
        if (this.props.onClickVoltar)
            this.props.onClickVoltar('TELA_INICIAL');
    }

    render() {
        return <div className="container-fluid mt-2">
            <div className="form-group">

                <p>
                    Você está solicitando a emissão do contrato de renegociação para a operação: <strong>{this.props.contrato.cdContrato}</strong> na seguinte condição:<strong>{this.props.ofertaSelecionada.dsTitulo}</strong>`
                </p>
                <label htmlFor="dsMotivo">Qual o motivo da renegociação? <small>(obrigatório)</small></label>
                <select className="form-control" id="dsMotivo" name="dsMotivo" placeholder="Qual o principal motivo para solicitar esta renegociação?" value={this.state.dsMotivo} onChange={this.handleSelectChange}>
                    <option value={''} disabled>Selecione</option>
                    <option value={'Desemprego'}>Dificuldades financeiras</option>
                    <option value={'Dificuldades financeiras'}>Desemprego</option>
                    <option value={'Redução da renda familiar'}>Redução da renda familiar</option>
                    <option value={'Perda do contrato de frete'}>Perda do contrato de frete</option>
                    <option value={'Problemas de saúde - Família'}>Problemas de saúde - Família</option>
                </select>

                {this.state.dsMotivo ? <div className="col-12 pt-2">
                    <p className="mb-0"><b>IMPORTANTE:</b></p>
                    <div className="form-check pt-2">
                        <input type="checkbox" className="form-check-input" id="flAceito" name="flAceito" checked={this.state.flAceito} onChange={this.handleCheckboxChange} />
                        <label className="form-check-label" htmlFor="flAceito">Estou de acordo com as instruções e tenho ciência dos próximos passos.</label>
                    </div>
                    <h6 className="mt-2 mb-0"><b>Após confirmar a solicitação não será possível cancelar.</b></h6>
                </div> : null}
                <button type="button" className="btn btn-block btn-primary mt-4" onClick={this.handleConfirmarReneg}> Confirmar</button>
                <button type="button" className="btn btn-block btn-secondary" onClick={this.handleVoltar}>Voltar</button>
            </div>
        </div>

    }
}
export default FormCancelarNegociacao;

import React, { Component, Fragment } from 'react';
import environment from '../environment';
import sessionStoreService from '../services/session-store.service';
import Estilos from './estilos-parceiros';

class BannerCampanha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: Estilos.default.layout,
            cdOrigem: sessionStoreService.getGenericData('cdOrigem') || '',
        }
    }

    componentDidMount() {
        let layout = (Estilos[this.state.cdOrigem] || Estilos.default).layout;
        this.setState({ layout })
    }

    render() {
        return <Fragment>
            {['MUTIRAO_FEBRABAN'].includes(this.props.campanha) ? <div className="row p-4 m-0" style={{ background: '#00427c' }}>
                <a href="https://mutirao.febraban.org.br/" target="_blank" style={{ textAlign: 'center', width: '80%', maxWidth: '350px', height: 'auto', display: 'block', margin: 'auto' }}>
                    <img src={environment.get('ASSETS') + `/img/mutirao_febraban.png`} alt="Mutirão Febraban" style={{ width: '100%', height: 'auto' }} />
                </a>
            </div> : null}

            {/* {['OLX'].includes(this.state.cdOrigem) ? <div className="row p-4 m-0" style={{ background: '#670ec5' }}>
                <a href="" target="_blank" style={{ textAlign: 'center', width: '80%', maxWidth: '350px', height: 'auto', display: 'block', margin: 'auto' }}>
                    <img src={environment.get('ASSETS') + `/img/olx.png`} alt="Parceiria Daycoval + OLX" style={{ height: '50px' }} />
                </a>
            </div> : null} */}
        </Fragment>
    }
}

export default BannerCampanha;
import React, { Component, Fragment } from 'react';

import Utils from '../../../../utils/text';

class ResumoProposta extends Component {
  render() {
    return !this.props.proposta ? null : <div className="row animated fadeIn pb-5">
      <div className="col-12 py-2 px-2">
        <p className="text-center m-0 py-2" style={{ fontSize: '12px' }}>Proposta: {this.props.proposta.idProposta ? String(this.props.proposta.idProposta).padStart(5, '0') : null}</p>
        <table className="table">
          <tbody>
            {this.props.proposta.vlFinanciamento ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">
                {this.props.proposta.dsProduto === 'FIN_VEIC' ? 'Valor de compra do veículo' : 'Valor solicitado para empréstimo'}
              </td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.proposta.vlFinanciamento)}</td>
            </tr> : null}

            {this.props.proposta.vlEntrada ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">Valor da entrada</td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.proposta.vlEntrada)}</td>
            </tr> : null}

            {this.props.proposta.vlFinanciamento && this.props.proposta.vlEntrada && this.props.proposta.dsProduto === 'FIN_VEIC' ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">Valor que será pago ao vendedor</td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.proposta.vlFinanciamento - this.props.proposta.vlEntrada)}</td>
            </tr> : null}

            {this.props.proposta.qtParcelas ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">Qtd. Parcelas</td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{this.props.proposta.qtParcelas} vezes</td>
            </tr> : null}

            {this.props.proposta.vlParcela ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">Valor Parcelas</td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.proposta.vlParcela)}</td>
            </tr> : null}

            {this.props.proposta.vlTaxa ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }} width="40%">Taxa Juros</td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('', this.props.proposta.vlTaxa)}% a.m.</td>
            </tr> : null}

            {this.props.proposta.qtParcelas && this.props.proposta.vlParcela ? <tr>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>Total a pagar</td>
              <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{Utils.formatarMoeda('R$', this.props.proposta.qtParcelas * this.props.proposta.vlParcela)}</td>
            </tr>
              : null}
          </tbody>
        </table>

        {this.props.proposta.item ? <Fragment>
          <p className="text-center m-0 py-2" style={{ fontSize: '12px' }}>Garantia:</p>
          <table className="table">
            <tbody>
              <tr>
                <td className="text-center align-middle p-1" style={{ fontSize: '13px' }}>{this.props.proposta.item.dsItem} {this.props.proposta.item.vlItem ? '(' + Utils.formatarMoeda('R$', this.props.proposta.item.vlItem) + ')' : ''}</td>
              </tr>
            </tbody>
          </table>
        </Fragment> : null}

        <p className="text-center m-0 p-1" style={{ fontSize: '12px' }}>As condições aqui apresentadas estão sujeitas à análise documental e de crédito, não constituindo, portanto, garantia da aprovação desta proposta.</p>
        <p className="text-center m-0 p-1" style={{ fontSize: '12px' }}>Além disso, os valores ora informados poderão sofrer pequenas variações, já que serão recalculados no momento da análise e aprovação do crédito</p>
      </div>
    </div>
  }
}

export default ResumoProposta;
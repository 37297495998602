import React, { } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import Environment from '../../environment';

/**
 * arquivos = [{
 *  dsArquivo: 'Nome legível'
 *  dsInfo: 'Informação abaixo do nome do arquivo',
 *  dsLink: 'https://link.para.arquivo/',
 *  dsIcone: 'png|xls|pdf|etc',
 *  cdEventoExcluir: 'ACAO',
 *  style: {} //Estilo do título
 *  styleInfo: {} //Estilo da info
 * }] 
 */
export default function ListaArquivos({ componente = {}, onEvento = () => { } }) {

    async function onClickArquivo(arquivo = {}) {
        if (!arquivo) return;

        if (arquivo.cdEventoExcluir)
            return onEvento(arquivo.cdEventoExcluir, { cdArquivo: arquivo.cdArquivo, dsArquivo: arquivo.dsArquivo });
        else if (arquivo.dsLink)
            return window.open(arquivo.dsLink, '_blank');
    }

    async function onClickExcluirArquivo(arquivo = {}) {
        if (!arquivo) return;
        if (arquivo.cdEventoExcluir)
            return onEvento(arquivo.cdEventoExcluir, { cdArquivo: arquivo.cdArquivo, dsArquivo: arquivo.dsArquivo });
    }

    async function onClickBaixarArquivo(arquivo = {}) {
        if (!arquivo) return;
        if (arquivo.dsLink)
            return window.open(arquivo.dsLink, '_blank');
    }



    return (<Fragment>
        {(componente.arquivos || []).map((arquivo, index) => {
            if (arquivo.dsLink && arquivo.downloadAutomatico)
                window.open(arquivo.dsLink, '_blank');

            return <div key={index}
                style={Object.assign({ display: 'flex', flexDirection: 'row', marginBottom: '20px' })}>

                <img alt={arquivo.dsArquivo}
                    src={Environment.get('ASSETS') + `/img/${arquivo.dsIcone}.svg`}
                    style={Object.assign({ width: '45px' })} />

                <div style={Object.assign({
                    flexGrow: 3,
                    padding: '0 10px',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                })}>

                    <p style={Object.assign({
                        margin: 0,
                        fontSize: '17px',
                        fontWeight: 'bold',
                        overflowWrap: 'anywhere',
                    }, arquivo.style)}>{arquivo.dsArquivo}</p>

                    <p style={Object.assign({
                        margin: 0,
                        fontSize: '17px',
                        overflowWrap: 'anywhere',
                    }, arquivo.styleInfo)}>{arquivo.dsInfo}<br />{arquivo.dsObservacoes}</p>


                </div>

                {arquivo.cdEventoExcluir ? <img alt='Apagar' onClick={() => onClickExcluirArquivo(arquivo)}
                    src={Environment.get('ASSETS') + `/img/delete.svg`}
                    style={Object.assign({ width: '35px' })} /> : null}

                {arquivo.dsLink ? <img alt='Download' onClick={() => onClickBaixarArquivo(arquivo)}
                    src={Environment.get('ASSETS') + `/img/download_3_dark.svg`}
                    style={Object.assign({ width: '35px' })} /> : null}
            </div>
        })}
    </Fragment>
    )
}
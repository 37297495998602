import React, { Component } from 'react';

const SIMBOLO = 'R$ ';
const SEPARADOR_DECIMAL = ',';
const SEPARADOR_MILHAR = '.';
const CASAS_DECIMAIS = 2;

class CampoMoeda extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: props.value || 0,
      valorFormatado: ''
    }
  }

  componentDidMount() {
    let valor = 0;
    if (this.state.valor && !isNaN(this.state.valor))
      valor = parseFloat(this.state.valor);

    valor = String(parseInt(valor * Math.pow(10, parseInt(this.props.casasDecimais) || CASAS_DECIMAIS)));

    let valorMoeda = this.formatarMoeda(valor);
    this.setState({
      valor: valorMoeda.valor,
      valorFormatado: valorMoeda.valorFormatado,
    });
  }

  formatarMoeda = (text = '') => {
    if (!text) return {
      valor: 0.0,
      valorFormatado: (this.props.simbolo || SIMBOLO) + '0' + (this.props.separadorDecimal || SEPARADOR_DECIMAL) + ''.padStart((parseInt(this.props.casasDecimais) || CASAS_DECIMAIS), '0')
    }

    let valor = text.replace(/\D/g, '');
    valor = valor.padStart(1 + (parseInt(this.props.casasDecimais) || CASAS_DECIMAIS), '0');

    if (parseInt(valor) > 999999999)
      valor = valor.substr(0, 9);

    let decimal = valor.substr(valor.length - (parseInt(this.props.casasDecimais) || CASAS_DECIMAIS));
    let inteiro = parseInt(valor.substr(0, valor.length - (parseInt(this.props.casasDecimais) || CASAS_DECIMAIS)));

    valor = parseFloat(inteiro + (decimal / (Math.pow(10, parseInt(this.props.casasDecimais) || CASAS_DECIMAIS)))).toFixed((parseInt(this.props.casasDecimais) || CASAS_DECIMAIS));

    if (inteiro >= 1000000) {
      inteiro = String(inteiro);
      inteiro = inteiro.substr(0, inteiro.length - 6) + (this.props.separadorMilhar || SEPARADOR_MILHAR) + inteiro.substr(inteiro.length - 6, inteiro.length - 4) + (this.props.separadorMilhar || SEPARADOR_MILHAR) + inteiro.substr(inteiro.length - 3);
    } else if (inteiro >= 1000) {
      inteiro = String(inteiro);
      inteiro = inteiro.substr(0, inteiro.length - 3) + (this.props.separadorMilhar || SEPARADOR_MILHAR) + inteiro.substr(inteiro.length - 3);
    }
    let valorFormatado = (this.props.simbolo || SIMBOLO) + inteiro + (this.props.separadorDecimal || SEPARADOR_DECIMAL) + decimal;

    return {
      valor,
      valorFormatado
    };
  }

  render() {
    return <input {...this.props} value={this.state.valorFormatado} onChange={(event) => {
      const target = event.target;
      let value = this.formatarMoeda(target.value || '');
      this.setState({
        valor: value.valor,
        valorFormatado: value.valorFormatado,
      }, () => {
        if (this.props.onChange)
          this.props.onChange(value.valor);
      });
    }} />
  }
}

export default CampoMoeda;
import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';


import ListarContratos from "./contratos/list";
import DetalheContrato from "./contratos/detail";
import ListarContratosReneg from "./reneg/list";
import DetalheReneg from "./reneg/detail";
import CobrancaContrato from "./contratos/cobranca";
import AuthService from "../../services/auth.service";
import SessionStoreService from "../../services/session-store.service";


class Servicos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aba: 'SERVICOS'
        }
    }

    render() {
        if (!AuthService.isAuthenticated()) {
            SessionStoreService.saveGenericData('redirecionamentoAutenticacao', window.location.hash);
            window.location.hash = `#/clientes/auth`;
        }
        
        return <Fragment>
            <Navbar aba={this.state.aba} />
            <div className="container-flex">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <Switch>
                            <Route path="/servicos/contratos" exact render={props => <ListarContratos {...props} />} />
                            <Route path="/servicos/contratos/:cdContrato" exact render={props => <DetalheContrato {...props} />} />
                            <Route path="/servicos/contratos/atraso/:cdContrato" exact render={props => <CobrancaContrato {...props} />} />
                            <Route path="/servicos/reneg/:acao" exact render={props => <ListarContratosReneg {...props} />} />
                            <Route path="/servicos/reneg/:acao/:cdContrato" exact render={props => <DetalheReneg {...props} />} />
                        </Switch>
                    </div>
                    <div className="col-12 px-0 pt-4">
                        <Footer />
                    </div>
                </div>
            </div>
        </Fragment>
    }
}

export default Servicos;
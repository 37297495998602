class ParamsUtils {
    getUrlParams(hash) {
        try {
            let queryString = (hash || '?').split('?');
            let paramsArray = (queryString[1] || '').split('&').map(p => p.split('='));
            let params = {};
            for (let p of paramsArray) {
                if (p[0]) params[p[0]] = decodeURIComponent(p[1]);
            }
            return params;
        } catch (err) {
            console.error(err)
        }
    }
}

export default new ParamsUtils();
import React from 'react';

function LoaderCircular(props) {
    return props.show ? <div className={`loader ${props.small ? 'small' : ''} ${props.inputLoader ? 'input-loader' : ''}`}>
        <div className="loader-container animated fadeIn">
            <div className="loader-externo"></div>
            <div className="loader-interno"></div>
        </div>
        {props.msg ? <p className="text-center pt-2 text-muted">{props.msg}</p> : null}
    </div> : null;
}

export default LoaderCircular;
import React, { Fragment } from 'react';
import environment from '../../../environment';

export default function ViewAgradecimento({ dsLinkBoleto, onClickVoltar = () => { } }) {

  async function handleConferirNumeroWhatsApp() {
    window.location.hash = '/clientes/perfil'
  }

  function baixarBoleto() {
    window.open(dsLinkBoleto);
  }

  return <Fragment>
    <div className="container-fluid pt-3">
      <img alt="Daycoval" src={environment.get('ASSETS') + `/img/check.svg`} style={{ maxWidth: '200px', height: 'auto', margin: 'auto', display: 'block' }} />
      <h5 className="m-0 pb-3 text-center">Obrigado!</h5>
      
      {!dsLinkBoleto ? <Fragment>
        <h6 className="m-0 pb-3 text-center">Nós já recebemos sua solicitação.</h6>
        <h6 className="m-0 pb-3 text-center">Assim que aprovado, o boleto será emitido e enviado diretamente ao seu WhatsApp.</h6>
        <h6 className="m-0 pb-3 text-center">Então fique atento e lembre-se de manter seu número WhatsApp sempre atualizado!</h6>
      </Fragment> : <Fragment>
        <h6 className="m-0 pb-3 text-center">Recebemos sua solicitação e seu boleto já foi emitido.</h6>
        <h6 className="m-0 pb-3 text-center">Nós também enviamos o boleto para o seu WhatsApp.</h6>
        <h6 className="m-0 pb-3 text-center">Lembre-se de manter seu número WhatsApp sempre atualizado!</h6>
      </Fragment>}

      <h6 className="m-0 pb-3 text-center">Obrigado por escolher o Banco Daycoval para financiar seu veículo.</h6>

      {dsLinkBoleto ? <button type="button" className="btn btn-block btn-success mb-2" onClick={baixarBoleto}>Baixar Boleto</button> : null}
      <button type="button" className="btn btn-block btn-primary mb-2" onClick={handleConferirNumeroWhatsApp}>Conferir o número WhatsApp</button>
      <button type="button" className="btn btn-block btn-primary mb-2" onClick={onClickVoltar}>Voltar ao menu</button>
    </div>
  </Fragment>
}
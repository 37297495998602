import React from 'react';
import Environment from '../../environment';

export default function Paragrafo({ paragrafo, icone, onClick = () => { } }) {
    return (
        paragrafo.props.html ?
            <p style={Object.assign({ color: '#666666', fontSize: '19px', lineHeight: '24px' }, paragrafo.props.style)}
                dangerouslySetInnerHTML={{ __html: paragrafo.props.html }} onClick={onClick}>
                {icone ? <span><img alt={icone.alt} src={Environment.get('ASSETS') + '/img/' + icone.url} style={icone.style} /></span> : null}
            </p>
            :
            <p style={Object.assign({ color: '#666666', fontSize: '19px', lineHeight: '24px' }, paragrafo.props.style || {})} onClick={onClick}>
                {paragrafo.props.label}
                {icone ? <span><img alt={icone.alt} src={Environment.get('ASSETS') + '/img/' + icone.url} style={icone.style} /></span> : null}
            </p>
    )
}
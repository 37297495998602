
import ApiService from './api.service';
class VeiculosService {

    async listarMarcasVeiculos(vlAnoModelo) {
        return ApiService.get(`financiamentos/listar-marcas-veiculos?vlAnoModelo=${vlAnoModelo || ''}`);
    }
    async listarModelosVeiculos(vlAnoModelo, dsMarca) {
        return ApiService.get(`financiamentos/listar-modelos-veiculos?vlAnoModelo=${vlAnoModelo || ''}&dsMarca=${dsMarca || ''}`);
    }
    async listarVersoesVeiculos(vlAnoModelo, dsMarca, dsModelo) {
        return ApiService.get(`financiamentos/listar-versoes-veiculos?vlAnoModelo=${vlAnoModelo || ''}&dsMarca=${dsMarca || ''}&dsModelo=${dsModelo || ''}`);
    }
    async pesquisarVeiculo(pesquisa, vlAnoModelo) {
        return ApiService.get(`financiamentos/pesquisar-veiculos?pesquisa=${pesquisa || ''}&${vlAnoModelo}`);
    }
    async recuperarVeiculo(vlAnoModelo, idVeiculo) {
        return ApiService.get(`financiamentos/recuperar-veiculo?vlAnoModelo=${vlAnoModelo}&idVeiculo=${idVeiculo || ''}`);
    }


}
export default new VeiculosService();
import React, { Component, Fragment } from 'react';
import Footer from '../components/footer';
import Navbar from '../components/navbar';

class PoliticaDadosPage extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || false;
    const name = target.name;
    this.setState({ [name]: value });
  }

  render() {
    return <Fragment>
      <Navbar />
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 py-4">
            <h5>Política de Dados do Portal do Cliente (v1.0)</h5>

            <p className="mb-4 mt-4"><small>Este documento é destinado aos utilizadores do Portal do Cliente.”</small></p>

            <p>Esta política descreve as informações que processamos para viabilizar a operação dos serviços e produtos disponíveis no Portal do Cliente oferecidos pelas empresas clientes da Tarion-Beck.</p>

            <p className="mt-4 font-weight-bold">Quais tipos de informações coletamos?</p>
            <p>Para fornecer os serviços e produtos disponíveis no Portal do Cliente, precisamos processar informações sobre você. Os tipos de informações que coletamos dependem de quais Produtos ou Serviços disponíveis você utiliza. </p>
            
            <p className="mt-4 font-weight-bold">Dados que você fornece</p>
            <p>Coletamos os dados que você fornece quando usa os serviços e produtos, inclusive quando você se autentica para acessar recursos restritos disponibilizados pelas empresas clientes da Tarion-Beck ou se cadastra para solicitar uma Proposta de Crédito. Isso inclui dados de e-mail e telefone pessoal de contato para ser avisado sobre alterações nos serviços e produtos utilizados, data de nascimento e CPF para autenticação e dados anônimos de navegação para melhoria na experiência de uso e tratamento de erros. </p>
            <p>Os serviços e produtos oferecidos pelas empresas clientes podem exigir dados adicionais para análise e proteção ao crédito. Os dados solicitados variam para cada processo e são descritos nas interfaces onde são solicitados. Os dados incluem informações sobre renda, profissão, endereço, o bem financiado, referências bancárias e dados para pagamento.</p>
            <p>Não solicitamos senhas ou qualquer outro dado sobre cartão de crédito.</p>
            <p>Nossos sistemas processam automaticamente os dados informados para as finalidades descritas abaixo. Alguns serviços e produtos exigem revisão individual e não automatizada para análise e proteção ao crédito.</p>
            <p>Também coletamos informações sobre como você usa serviços e produtos disponíveis, como quais arquivos foram baixados e quais contratos foram acessados.</p>
            
            <p className="mt-4 font-weight-bold">Informações de dispositivo</p>
            <p>Coletamos informações de forma anônima sobre os computadores e os telefones que você usa para acessar os serviços e produtos, permitindo sejam analisadas mais informações sobre os tipos de Hardware e Software utilizados e assim corrigir erros e melhorar a experiência de utilização dos serviços e produtos disponíveis no Portal do Cliente.</p>
            <p>As informações que obtemos desses dispositivos incluem: o sistema operacional, as versões do hardware e software, tipo de navegador, informações sobre operações e comportamentos realizados no dispositivo, tais como se uma janela está em primeiro ou segundo plano, endereço IP, dados de cookies produzidos e armazenados em seu dispositivo através do Portal do Cliente sobre configurações e IDs de cookies.</p>
            
            <p className="mt-4 font-weight-bold">Informações de empresas clientes</p>
            <p>As empresas clientes do grupo Tarion-Beck podem enviar informações sobre você para que sejam disponibilizadas de forma restrita e segura, incluindo dados pessoais, carnês, cópias de contratos, dados sobre dívidas e dados sobre análise e proteção ao crédito. Estas informações trafegam em ambiente seguro e restrito, além de serem autorizadas pelos detentores das informações a serem disponibilizadas e utilizadas para os devidos fins.</p>
            <p>Exigimos que cada uma das empresas clientes tenha autorização legal para coletar, usar e compartilhar seus dados antes de fornecê-los para nós.</p>
            <p>As empresas clientes também enviam informações para customização do Portal do Cliente, como cores, textos, imagens e logomarcas registradas. Estas informações são autorizadas para uso no Portal do Cliente, permitindo uma experiência melhor de uso.</p>

            <p className="mt-4 font-weight-bold">Como usamos estas informações?</p>
            <p>Usamos as informações que temos (em consonância com as escolhas feitas por você) conforme descrito abaixo para fornecer e viabilizar a operação dos Serviços e produtos disponíveis no Portal do Cliente. Veja como:</p>
            
            <p className="mt-4 font-weight-bold">Disponibilizar documentos</p>
            <p>Usamos os dados de autenticação para disponibilizar cópias de contratos e carnês em formato digital aos consumidores em nome das empresas clientes. Para estes Serviços, os dados utilizados são fornecidos pelas empresas clientes, que são detentoras dos direitos de uso, e validados pelos consumidores no momento da autenticação.</p>
            
            <p className="mt-4 font-weight-bold">Disponibilizar e negociar dívidas</p>
            <p>Usamos os dados de autenticação para disponibilizar as parcelas em atraso dos contratos firmados entre as empresas clientes e os consumidores. No ato da disponibilização, são efetuados cálculos para atualização dos valores devidos e permitir a negociação do valor da dívida de acordo com as regras estabelecidas pelas próprias empresas clientes.</p>
            <p>A negociação efetuada pode gerar novos documentos que estarão disponíveis ao consumidor que solicitou e à empresa cliente detentora dos direitos sobre os dados dos contratos e parcelas utilizadas para cálculo da dívida.</p>
            
            <p className="mt-4 font-weight-bold">Solicitar Crédito</p>
            <p>Utilizamos os dados solicitados nas interfaces de simulação de crédito para calcular as condições adequadas a cada cenário de acordo com as regras pré-estabelecidas pelas empresas clientes.</p>
            <p>Para a simulação solicitamos dados sobre idade do consumidor, estado de residência e dados do bem a ser financiado para apresentar as condições disponíveis no momento para que o consumidor escolha a que julgar ideal.</p>
            <p>Para a contratação do crédito, são solicitadas informações relativas à análise e proteção ao crédito que podem variar a cada processo definido pelas empresas clientes. Os dados são referentes a profissão, endereço, renda, bem envolvido e pagamento do crédito. Poderão ser solicitadas imagens para comprovar as informações cadastradas pelos consumidores.</p>
            <p>Também podemos tornar a experiência mais integrada, por exemplo, preenchendo automaticamente informações de registro (como número de telefone) a partir de um Produto ou Serviço do Portal do Cliente que já tenha utilizado anteriormente.</p>
            
            <p className="mt-4 font-weight-bold">Pesquisa e desenvolvimento de produtos</p>
            <p>Usamos as informações que temos para desenvolver, testar e aprimorar nossos serviços e produtos, inclusive realizando enquetes e pesquisas, e testando e resolvendo problemas relativos a novos produtos e recursos.</p>
            
            <p className="mt-4 font-weight-bold">Promover segurança e integridade</p>
            <p>Usamos as informações que temos para verificar o uso e as atividades, combater condutas danosas, detectar e prevenir experiências negativas, manter a integridade de nossos serviços e produtos e promover a segurança dentro dos serviços e produtos oferecidos pelo Portal do Cliente. Por exemplo, usamos os dados que temos para investigar atividades suspeitas ou violações de nossos termos ou políticas.</p>
            
            <p className="mt-4 font-weight-bold">Comunicar com você</p>
            <p>Usamos as informações que temos para enviar a você notificações sobre os Serviços e produtos e para informar você sobre nossas políticas e termos. Também usamos suas informações para responder quando você entra em contato conosco.</p>

            <p className="mt-4 font-weight-bold">Como estas informações são compartilhadas?</p>
            <p>Suas informações são compartilhadas com as empresas clientes envolvidas no Produto ou Serviço:</p>
            <p>Quando você acessa cópia de contratos ou carnês, enviamos os dados de quando o acesso foi realizado, dados que possam lhe identificar e qual documento foi acessado;</p>
            <p>Quando você realiza a negociação de uma dívida, enviamos todos os dados gerados na negociação, incluindo valores, documentos gerados e dados que possam lhe identificar;</p>
            <p>Quando você solicita crédito, enviamos todos os dados cadastrados para prosseguir com os processos da empresa cliente;</p>
            <p>Trabalhamos com fornecedores externos que nos ajudam a disponibilizar nossos serviços e produtos, o que possibilita a operação de nossas empresas. Não vendemos nenhuma de suas informações para ninguém e jamais o faremos. Também impomos fortes restrições sobre como nossos parceiros podem usar e divulgar os dados que fornecemos. Aqui estão os tipos de terceiros com os quais compartilhamos informações:</p>
            
            <p className="mt-4 font-weight-bold">Fornecedores e provedores de serviços</p>
            <p>Fornecemos informações e conteúdo para fornecedores e provedores de serviços que viabilizam a operação de nosso negócio, seja fornecendo serviços de infraestrutura técnica, analisando como nossos serviços e produtos são usados, oferecendo atendimento ao cliente, facilitando pagamentos ou realizando pesquisas.</p>
            
            <p className="mt-4 font-weight-bold">Aplicação da lei ou solicitações legais</p>
            <p>Compartilhamos informações com autoridades responsáveis pela aplicação da lei ou em resposta a solicitações legais.</p>

            <p className="mt-4 font-weight-bold">Como faço para gerenciar ou excluir informações sobre mim?</p>
            <p>Concedemos a você a capacidade de acessar, retificar, portar e apagar seus dados através do Portal do Cliente nos serviços e produtos disponíveis. Para poder ter acesso a todos os dados e/ou solicitar a exclusão, deverá ser enviado e-mail para suporte@facildepagar.com.br descrevendo sua solicitação. Nossa equipe irá solicitar documentos pessoais que possam comprovar sua identidade e permissão para acesso aos dados.</p>
            <p>Armazenamos dados até que eles não sejam mais necessários para fornecermos nossos serviços e produtos do Portal do Cliente, ou até que sua conta seja excluída — o que ocorrer primeiro. Esta é uma determinação que varia de acordo com cada caso e depende de fatores como a natureza dos dados, o motivo pelo qual são coletados e processados, e necessidades de retenção operacional ou legal relevantes.</p>
            <p>Se você nos enviar uma cópia de seu documento de identificação emitido pelo governo para fins de verificação da conta, excluiremos essa cópia 30 dias após a análise, a menos que haja estipulação em contrário. </p>
            <p>Quando seus dados são excluídos, não será possível recuperar esses dados. As informações que empresas clientes compartilharam sobre você não fazem parte de seus dados e não serão excluídos. Para excluir seus dados a qualquer momento, envie e-mail para suporte@facildepagar.com.br com a descrição da sua solicitação.</p>

            <p className="mt-4 font-weight-bold">Como respondemos a solicitações legais ou prevenimos danos?</p>
            <p>Acessamos, preservamos e compartilhamos suas informações com reguladores, autoridades ou outros:</p>
            <p>Em resposta a uma requisição legal (como um mandado de busca, uma ordem judicial ou intimação) se nós acreditarmos de boa-fé que a lei exige que assim façamos. Isso pode incluir a resposta a solicitações legais de jurisdições fora do Brasil quando acreditarmos de boa-fé que a resposta é exigida por lei na jurisdição em questão, afeta usuários na jurisdição em questão e é consistente com padrões reconhecidos internacionalmente.</p>
            <p>Quando acreditamos de boa-fé que elas são necessárias para: detectar, prevenir e resolver questões de fraude, uso não autorizado dos Produtos, violações de nossos termos ou políticas ou outra atividade ilegal ou prejudicial; para nos proteger (e proteger nossos direitos, propriedades ou Produtos), a você ou a outras pessoas, inclusive como parte de investigações ou inquéritos regulatórios, ou para evitar morte ou danos corporais iminentes.</p>
            <p>As informações que recebemos sobre você podem ser acessadas e preservadas por um período maior quando forem objeto de uma requisição ou obrigação legal, investigação governamental, investigações de possíveis violações de nossos termos ou políticas, ou para de outra forma impedir danos. Também retemos informações de contas desativadas por violação de nossos termos por, no mínimo, um ano, a fim de prevenir repetição de abuso ou outras violações dos termos.</p>

            <p className="mt-4 font-weight-bold">Como notificaremos você sobre alterações nesta política?</p>
            <p>Notificaremos você antes de fazer alterações nesta política e daremos a você a oportunidade de analisar a política revisada antes de decidir continuar usando nossos serviços e produtos.</p>

            <p className="mt-4 font-weight-bold">Como entrar em contato com a Tarion-Beck em caso de dúvidas</p>
            <p>Você pode entrar em contato conosco enviando e-mail para suporte@facildepagar.com.br.</p>

            <p className="mt-4 font-weight-bold">Aviso de Privacidade</p>
            <p>Esta seção se aplica a atividades de tratamento de dados pessoais de acordo com as leis brasileiras e complementa esta Política de Dados.</p>
            <p>De acordo com a Lei Geral de Proteção de Dados Pessoais do Brasil (“LGPD”), você tem direito de acessar, retificar e apagar seus dados, além de autorizar nosso tratamento deles e solicitar sua portabilidade. Em determinadas circunstâncias, você também tem o direito de contestar e restringir o tratamento dos seus dados pessoais ou de revogar seu consentimento quando tratamos dados fornecidos com base nesse consentimento. Esta Política de Dados fornece informações sobre como compartilhamos dados com terceiros. Caso queira solicitar mais informações sobre as nossas práticas de dados, envie e-mail para suporte@facildepagar.com.br com os detalhes da sua solicitação.</p>
            <p>Os controladores de dados responsáveis pelas suas informações são a Tarion-Beck e empresas clientes descritas no Portal do Cliente. Você pode entrar em contato com o encarregado da proteção dos dados da Tarion-Beck através do e-mail suporte@facildepagar.com.br. Você também tem o direito de peticionar à Autoridade Nacional de Proteção de Dados (“ANPD”). Para isso, entre em contato diretamente com ela.</p>
            <p>Esta seção passa a valer na data de entrada de vigência da LGPD.</p>

            <p><small>Data da última revisão: 28 de dezembro de 2021</small></p>
            <p className="mt-4"><small>“Este e qualquer outro documento oficial podem conter erros de digitação ou ambiguidade. Informe imediatamente sobre qualquer problema encontrado através do e-mail suporte@facildepagar.com.br”</small></p>

            <p className="mt-5">Leia os Termos e Políticas</p>
            <a href="#/termos-uso-tarion"><p className="mb-1">Termos de uso dos serviços e produtos Tarion-Beck</p></a>
            <a href="#/termos-uso"><p className="mb-1">Termos de uso do Portal do Cliente</p></a>
            <a href="#/politica-privacidade"><p className="mb-1">Política de Privacidade do Portal do Cliente</p></a>
            <a href="#/politica-dados"><p className="mb-1">Política de Dados do Portal do Cliente</p></a>

          </div>
          <div className="col-12 px-0">
            <Footer />
          </div>
        </div>
      </div>
    </Fragment>
  }
}

export default PoliticaDadosPage;
import React, { Component, Fragment } from 'react';
import LoaderCircular from '../../components/loader/loader-circular';
import BackgroundJornada from '../../components/jornadas/background-jornada';
import Cabecalho from '../../components/jornadas/cabecalho';
import CardJornada from '../../components/jornadas/card-jornada';
import JornadaService from '../../services/jornada.service';
import ParamsUtil from "../../utils/params";
import AuthService from '../../services/auth.service';
import SessionStoreService from '../../services/session-store.service';

class DetalheJornada extends Component {

  constructor(props) {
    super(props);

    let params = ParamsUtil.getUrlParams(window.location.hash);

    this.state = {
      cdJornada: this.props.match.params.cdJornada,
      params,
      input: params || {},
      loading: false,
      error: undefined,
      msg: undefined,
    };
  }

  async componentDidMount() {
    await this.loadData();
    if (this.state.params.error) this.setState({ error: this.state.params.error });
    if (this.state.params.msg) this.setState({ msg: this.state.params.msg });
  }

  loadData = async () => {
    this.setState({ loading: true, error: undefined, msg: undefined });
    try {
      let result = await JornadaService.recuperarJornada(this.state.cdJornada, this.state.input);

      // Tratamento para redirecionar para outra URL
      // Interrompe o fluxo quando houver
      if (result.redirecionarUrl) {
        return window.location = result.redirecionarUrl;
      }
      if (result.redirecionarHash) {
        window.location.hash = result.redirecionarHash;
        return window.location.reload();
      }
      if (result.abrirUrl) {
        window.open(result.abrirUrl, '_blank');
      }

      // Caso não tenha redirecionamento e não tenha tela atual, temos um erro.
      if (!result.telaAtual) return this.setState({ loading: false, error: 'Ocorreu um problema ao recuperar a jornada', msg: undefined });

      // Tratamento para mensagens de erro.
      if (result.error) {
        this.setState({ error: result.error });
      }

      let input = this.state.input;

      // Caso tenha retorno de dados da api, armazenamos no input.
      if (result.input) input = Object.assign(input, result.input);

      // Campos para remover so state (esquecer valores)
      if (result.removerInput) {
        for (let campo of result.removerInput) {
          if (input[campo]) delete input[campo];
        }
      }

      this.setState({ telaAtual: result.telaAtual, input });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar as telas. Tente novamente ou contate nosso suporte.' });

    }

    // Delay para sair loader proposital.
    // Melhora a experiência de uso.
    this.setState({ loading: false });
  }

  handleDadosAlterados = async (dados) => {
    return new Promise(resolve => {
      let dadosInput = Object.assign(this.state.input, dados);
      this.setState({ input: dadosInput }, resolve);
    })
  }

  handleEvento = async (cdEvento, inputEvento) => {
    if (!cdEvento) return;
    this.setState({ loading: true, error: undefined, msg: undefined });

    let result;

    try {
      let dados = {
        cdJornada: this.state.cdJornada,
        cdTela: this.state.telaAtual.cdTela,
        cdTelaAnterior: this.state.cdTelaAnterior,
        cdEvento,
        input: this.state.input,
      }

      if (inputEvento) dados.input = Object.assign(dados.input, inputEvento);

      result = await JornadaService.processarEvento(dados);

      // Tratamento para redirecionar para outra URL
      // Interrompe o fluxo quando houver
      if (result.redirecionarUrl) {
        return window.location = result.redirecionarUrl;
      }
      if (result.redirecionarHash) {
        window.location.hash = result.redirecionarHash;
        return window.location.reload();
      }
      if (result.abrirUrl) {
        window.open(result.abrirUrl, '_blank');
      }

      // Tratamento para mensagens de erro.
      if (result.error) {
        this.setState({ error: result.error });
      }

      // Tratamento para quando houver dados de autenticação.
      if (result.auth) {
        AuthService.clearAuthData();
        AuthService.setAuthData(result.auth);
        if (result.auth.cliente)
          SessionStoreService.saveGenericData('cliente', result.auth.cliente);
      }

      let input = this.state.input;

      // Caso tenha retorno de dados da api, armazenamos no input.
      if (result.input) input = Object.assign(input, result.input);

      // Campos para remover so state (esquecer valores)
      if (result.removerInput) {
        for (let campo of result.removerInput) {
          if (input[campo]) delete input[campo];
        }
      }

      if (result.proximaTela && result.proximaTela.conteudo) {
        this.setState({ telaAtual: result.proximaTela });
      }

      this.setState({ input, cdTelaAnterior: result.cdTelaAnterior || this.state.cdTelaAnterior });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar as telas. Tente novamente ou contate nosso suporte.' });
    }

    // Delay para sair loader proposital.
    // Melhora a experiência de uso.
    setTimeout(() => { this.setState({ loading: false }) }, 600);

    return result ? result.input : undefined;
  }

  render() {
    return <Fragment>
      {!this.state.telaAtual ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><LoaderCircular show={true}></LoaderCircular></div> : null}
      {this.state.telaAtual ?
        this.state.telaAtual.background ?
          <BackgroundJornada
            tela={this.state.telaAtual}
            style={this.state.telaAtual.background.style}
            input={this.state.input}>

            {this.state.telaAtual.cabecalho && this.state.telaAtual.cabecalho.tipo ? <Cabecalho
              tipo={this.state.telaAtual.cabecalho.tipo}
              tamanho={this.state.telaAtual.cabecalho.tamanho}
              btnEsq={this.state.telaAtual.cabecalho.btnEsq || []}
              btnDir={this.state.telaAtual.cabecalho.btnDir || []}
              imagens={this.state.telaAtual.cabecalho.imagens || []}
              imagensMaiores={this.state.telaAtual.cabecalho.imagensMaiores || []}
              onEvento={this.handleEvento} /> : null}

            <CardJornada
              tipoCabecalho={this.state.telaAtual.cabecalho.tipo}
              conteudo={this.state.telaAtual.conteudo || []}
              acoes={this.state.telaAtual.acoes || []}
              input={this.state.input}
              loading={this.state.loading}
              error={this.state.error}
              onDadosAlterados={this.handleDadosAlterados}
              onEvento={this.handleEvento} />

          </BackgroundJornada> : null : null}
    </Fragment>
  }
}

export default DetalheJornada;
import React, { Fragment } from "react";
import Page from '../../page';
import Table from "../../../components/table";
import ContratosService from "../../../services/contratos.service";
import AbaProposta from '../../../components/aba-proposta';
import analyticsService from "../../../services/analytics.service";
/* import Loader from '../components/loader'; */


class ListarContratos extends Page {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            error: undefined,
            cdCliente: props.match.params.cdCliente,
            contratos: [],
            cliente: {}
        }
    }
    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loader: { message: 'Iniciando aplicação...', show: true } });

        try {
            let contratos = await ContratosService.listarContratos();
            this.setState({ contratos });
        } catch (err) {
            console.error(err);
            this.setState({ error: err.msg || 'Falha ao listar os contratos. Tente novamente ou contate nosso suporte.' });
            analyticsService.sendException(err, false);
        }

        this.setState({ loading: false });
        if(this.state.contratos.length === 1){
            window.location.hash = `servicos/reneg/${this.props.match.params.acao}/${encodeURIComponent(this.state.contratos[0].cdContrato)}`;
        }
    }
    onListItemClick = async (index, values) => {
       
        window.location.hash = `servicos/reneg/${this.props.match.params.acao}/${encodeURIComponent(this.state.contratos[index].cdContrato)}`;
    }

    render() {
        return <Fragment>
            <AbaProposta
                key={'Contrato'}/*  icon={aba.icon}  */
                label={'Contrato'}
                active={true}
            />
            <div className="container-fluid">
                    
                <div className="mt-1">
                    <Table
                        
                        onListItemClick={this.onListItemClick}
                        rows={ContratosService.mapContratosToTable(this.state.contratos || [])}
                    />
                </div>
            </div>
        </Fragment>
    }
}
export default ListarContratos;
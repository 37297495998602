import React, { Component, Fragment } from 'react';

import Environment from '../../../../environment';

const styles = {
  disabled: {
    number: {
      color: '#ccc',
      fontSize: '25px',
      fontWeight: 'bold'
    },
    separator: {
      borderLeft: '1px solid #ccc'
    },
    title: {
      color: '#ccc'
    },
    description: {
      display: 'none'
    }
  },
  current: {
    number: {
      color: '#B0C4DE',
      fontSize: '30px',
      fontWeight: 'bold'
    },
    separator: {
      borderLeft: '2px solid #B0C4DE'
    },
    title: {
      color: '#444'
    },
    description: {
      color: '#777',
      fontSize: '13px'
    }
  },
  error: {
    number: {
      color: '#bf3333',
      fontSize: '30px',
      fontWeight: 'bold'
    },
    separator: {
      borderLeft: '2px solid #bf3333'
    },
    title: {
      color: '#444'
    },
    description: {
      color: '#777',
      fontSize: '13px'
    }
  },
  complete: {
    number: {
      color: '#0373b0',
      fontSize: '25px',
      fontWeight: 'bold'
    },
    separator: {
      borderLeft: '1px solid #0373b0'
    },
    title: {
      color: '#aaa'
    },
    description: {
      display: 'none'
    }
  }
}

class ChecklistItem extends Component {
  render() {
    let currentStyles = this.props.error ? styles.error : this.props.complete ? styles.complete : this.props.current ? styles.current : styles.disabled;
    return <Fragment>
      <div className="col-2 py-2 px-0 m-0">
        <h3 className="text-center m-0" style={currentStyles.number}>{this.props.number}</h3>
      </div>
      <div className="col py-2 pl-3 m-0" style={currentStyles.separator}>
        <h5 className="m-0 p-0" style={currentStyles.title}>{this.props.title}</h5>
        {this.props.current ? this.props.html ? <p className="m-0 py-1" style={currentStyles.description} dangerouslySetInnerHTML={{ __html: this.props.html }}></p>
          : <p className="m-0 py-1" style={currentStyles.description}>{this.props.description}</p>
          : null}
        {this.props.action && this.props.onClickAction && this.props.current ? <button className="btn btn-block btn-primary" onClick={this.props.onClickAction}>{this.props.action}</button> : null}
      </div>
      {this.props.complete ? <div className="col-2 py-2 pr-2 m-0">
        <img alt="Daycoval" src={Environment.get('ASSETS') + '/img/check.svg'} style={{ height: '29px', float: 'right' }}></img>
      </div> : null}
    </Fragment>
  }
}

export default ChecklistItem;
const STAGE = String(process.env.REACT_APP_STAGE || "").trim();

class Environment {
  async init() {
    return new Promise((resolve, reject) => {
      import(`./${STAGE}`).then(module => {
        this.ENVIRONMENT = module.ENVIRONMENT;
        return resolve();
      }).catch(err => {
        console.error(err);
        return reject(err);
      })
    });
  }

  get(variable) {
    if (this.ENVIRONMENT)
      return this.ENVIRONMENT[variable];
    return undefined;
  }
}

export default new Environment();
import React, { Fragment } from "react";
import Page from '../../page';
import Table from "../../../components/table";
import AbaProposta from '../../../components/aba-proposta';
import ContratosService from "../../../services/contratos.service";
import EnviosDigitaisService from "../../../services/envios-digitais.service";
import Msgs from '../../../components/msgs';
import Loader from '../../../components/loader';
import environment from "../../../environment";
import analyticsService from "../../../services/analytics.service";
import sessionStoreService from "../../../services/session-store.service";
import BannerCampanha from "../../../components/banner-campanha";


class DetalheContrato extends Page {
  constructor(props) {
    super(props);

    analyticsService.sendPageView('servicos/contratos/detalhe', 'Serviços - Contratos - Detalhe');

    this.state = {
      loader: false,
      error: undefined,
      cdContrato: props.match.params.cdContrato,
      contrato: {},
      cliente: {},
      negociacao: {},
      ofertas: {},
      aba: undefined,
      telaAtual: 'TELA_INICIAL',
    }

    this.cdOrigem = sessionStoreService.getGenericData('cdOrigem');
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    this.setState({ loader: { message: 'Iniciando aplicação...', show: true } });
    try {
      let { contrato, cliente, ofertas, negociacao } = await ContratosService.recuperarPorContrato(this.state.cdContrato);
      this.setState({ contrato, cliente, ofertas, negociacao });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar o contrato. Tente novamente ou contate nosso suporte.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  handleTrocaTela = async (tela) => {
    let telaAtual = tela
    this.setState({
      telaAtual
    })
  }

  handleVoltar = (tela) => {
    try {
      if (tela) {
        let telaAtual = tela
        this.setState({ telaAtual });
      } else {
        window.location.hash = `/inicio`;
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao voltar para menu. Tente novamente ou contate nosso suporte.' });
      analyticsService.sendException(err, false);
    }
  }

  handleVoltar = () => {
    window.location.hash = `/inicio`;
  }

  handleAtraso = async () => {
    window.location.hash = `servicos/contratos/atraso/${encodeURIComponent(this.state.contrato.cdContrato)}`;
  }

  handleBaixarContrato = async () => {
    analyticsService.sendEvent('baixou_contrato_assinado');
    this.setState({ loader: { message: 'Baixando contrato...', show: true }, error: null });
    try {
      await EnviosDigitaisService.registrarLog('BAIXOU_CONTRATO', this.state.contrato.cdContrato);
    } catch (err) {
      console.error(err);
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
    window.open(this.state.contrato.dsLink);
  }

  handleBaixarCarne = async () => {
    analyticsService.sendEvent('baixou_carne');
    this.setState({ loader: { message: 'Baixando carnê...', show: true }, error: null });
    try {
      await EnviosDigitaisService.registrarLog('BAIXOU_CARNE', this.state.contrato.cdContrato);
    } catch (err) {
      console.error(err);
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
    window.open(this.state.contrato.dsLinkCarne);
  }

  render() {

    return <Fragment>
      {this.state.contrato ? <AbaProposta
        key={'Contrato'}/*  icon={aba.icon}  */
        label={`Contrato ${this.state.contrato.cdContrato}`}
        active={true}
      /> : null}

      <div className="container-flex">
        <Loader message={this.state.loader.message} show={this.state.loader.show} />
        <Msgs info={this.state.msg} error={this.state.error} />
        <BannerCampanha campanha={this.cdOrigem} />

        {this.state.contrato && this.state.contrato.cdContrato ? <Fragment>
          <Table rows={ContratosService.mapContratosToTable([this.state.contrato], [])} />

          <div className="container-fluid pt-3">

            {[0, 1, 2, 3, 4, 5, 6].indexOf(this.state.contrato.cdStatus) > -1 ? <div className="col-12 pt-2">
              <h6 className="text-center" style={{ color: '#0373b0' }}>
                {[0, 1].indexOf(this.state.contrato.cdStatus) > -1 ? 'Seu contrato possui parcelas em atraso. Você pode negociar agora mesmo.' : null}
                {[2, 4].indexOf(this.state.contrato.cdStatus) > -1 ? 'Você solicitou um boleto para pagar as parcelas em atraso do seu contrato.' : null}
                {[3].indexOf(this.state.contrato.cdStatus) > -1 ? 'O pagamento das parcelas em atraso que você negociou não foi localizada até o momento. Caso tenha pago há mais de 2 dias, por favor fale com nossos atendentes.' : null}
                {[5].indexOf(this.state.contrato.cdStatus) > -1 ? 'Já recebemos o pagamento das parcelas em atraso que você negociou. Logo o sistema estará atualizado.' : null}
                {[7].indexOf(this.state.contrato.cdStatus) > -1 ? 'Este contrato não está mais disponível para negociar em nosso sistema.' : null}
              </h6>
            </div> : null}

            <div className="col-12 pt-3 px-0">
              {this.state.negociacao && this.state.negociacao.boletos && this.state.negociacao.boletos[0] && this.state.negociacao.boletos[0].dsLinkBoleto ?
                <a href={this.state.negociacao.boletos[0].dsLinkBoleto} className="btn btn-block btn-success mb-2">
                  <img alt="Daycoval" src={environment.get('ASSETS') + `/img/barcode_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
                  Baixar boleto
                </a>
                : null}

              {this.state.negociacao ? <button type="button" className="btn btn-block btn-primary mb-2" onClick={this.handleAtraso}>
                <img alt="Daycoval" src={environment.get('ASSETS') + `/img/search_simple_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
                Ver detalhes da negociação
              </button> : null}

              {[0, 1].indexOf(this.state.contrato.cdStatus) > -1 ? <button type="button" className="btn btn-block btn-success mb-2" onClick={this.handleAtraso}>
                <img alt="Daycoval" src={environment.get('ASSETS') + `/img/barcode_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
                Pagar parcelas em atraso
              </button> : null}

              {this.state.contrato.dsLink ? <button type="button" className="btn btn-block btn-primary mb-2" onClick={this.handleBaixarContrato}>
                <img alt="Daycoval" src={environment.get('ASSETS') + `/img/CONTRATO_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
                Baixar cópia assinada do contrato
              </button> : null}

              {this.state.contrato.dsLinkCarne ? <button type="button" className="btn btn-block btn-primary mb-2" onClick={this.handleBaixarCarne}>
                <img alt="Daycoval" src={environment.get('ASSETS') + `/img/CARNE_white.svg`} style={{ width: 20, height: 'auto', paddingBottom: '3px', paddingRight: '2px' }} />
                Baixar meu carnê
              </button> : null}
              
              <button type="button" className="btn btn-block btn-secondary mb-2" onClick={this.handleVoltar}>Voltar ao menu</button>
            </div>
          </div>
        </Fragment> : null}
      </div>
    </Fragment>
  }
}
export default DetalheContrato;
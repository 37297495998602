import React, { Fragment } from 'react';
import moment from 'moment';
import TextUtil from '../../../utils/text';

export default function ViewNegociacaoEfetuada({ negociacao = {}, onClickCancelarNegociacao = () => { } }) {
  return <Fragment>
    {negociacao && [0, 1, 3, 4].indexOf(negociacao.cdStatusNegociacao) > -1 ? <div className="row">
      <div className="col-12 pt-2">
        <h6 className="text-center" style={{ color: '#0373b0' }}>
          {[0, 4].indexOf(negociacao.cdStatusNegociacao) > -1 ? 'A negociação deste contrato está sendo processada. Você receberá o boleto em seu WhatsApp assim que ele for emitido.' : null}
          {[1].indexOf(negociacao.cdStatusNegociacao) > -1 ? 'A negociação deste contrato foi processada com sucesso e o boleto foi enviado para seu WhatsApp. Você pode baixar o boleto clicando logo abaixo.' : null}
          {[2].indexOf(negociacao.cdStatusNegociacao) > -1 ? 'A negociação deste contrato foi cancelada.' : null}
          {[3].indexOf(negociacao.cdStatusNegociacao) > -1 ? 'O pagamento da negociação deste contrato não foi localizada até o momento. Caso tenha pago há mais de 2 dias, por favor fale com nossos atendentes.' : null}
          {[5].indexOf(negociacao.cdStatusNegociacao) > -1 ? 'O boleto da negociação deste contrato já foi pago. Estamos aguardando a atualização do sistema.' : null}
          {[7].indexOf(negociacao.cdStatusNegociacao) > -1 ? 'Este contrato não está mais disponível para negociar em nosso sistema.' : null}
        </h6>
      </div>
      <div className="col-6">
        <h6 className="text-center"><small>Data Negociação</small>
          <br /><strong>{moment.utc(negociacao.dtNegociacao).format("DD/MM/YYYY")}</strong>
        </h6>
      </div>
      <div className="col-6">
        <h6 className="text-center"><small>Forma de Pagamento</small>
          <br /><strong>{negociacao.cdTipoNegociacao !== 9 ? 'À VISTA' : null}</strong>
        </h6>
      </div>

      {negociacao.boletos && negociacao.boletos.length > 0 ? <Fragment>
        <div className="col-6">
          <h6 className="text-center"><small>Vencimento:</small>
            <br /><strong>{moment(negociacao.boletos[0].dtVencimentoBoleto).format('DD/MM/YYYY')}</strong>
          </h6>
        </div>
        <div className="col-6">
          <h6 className="text-center"><small>Valor:</small>
            <br /><strong>{TextUtil.formatarMoeda('R$', negociacao.boletos[0].vlBoleto)}</strong>
          </h6>
        </div>
      </Fragment> : null}
    </div> : null}

    {negociacao && negociacao.boletos && negociacao.boletos[0] && negociacao.boletos[0].dsLinkBoleto ?
      <a href={negociacao.boletos[0].dsLinkBoleto} className="btn btn-block btn-primary mb-2">Baixar boleto</a>
      : null}

    {negociacao && [0, 1, 3, 4].indexOf(negociacao.cdStatusNegociacao) > -1 ? <button type="button" className="btn btn-block btn-secondary mb-2" onClick={onClickCancelarNegociacao}>Cancelar Negociação</button> : null}
  </Fragment >
}
import React, { Component, Fragment } from 'react';
import ChecklistItem from './checklist-item';

class ChecklistFormalizacao extends Component {
  handlePreencherCadastro = () => {
    if (this.props.onClickPreencherCadastro)
      this.props.onClickPreencherCadastro();
  }

  handlePreencherDados = () => {
    if (this.props.onClickPreencherDados)
      this.props.onClickPreencherDados();
  }
  handleNotificarVendedor = () => {
    if (this.props.onClickNotificarVendedor)
      this.props.onClickNotificarVendedor();
  }

  handleEnviarProposta = () => {
    if (this.props.onClickEnviarProposta)
      this.props.onClickEnviarProposta();
  }

  handleFalarWhatsApp = () => {
    if (this.props.onClickFalarWhatsApp)
      this.props.onClickFalarWhatsApp();
  }

  handleSalvarProposta = () => {
    if (this.props.onClickSalvarProposta)
      this.props.onClickSalvarProposta();
  }

  handleEnviarComprovantes = () => {
    if (this.props.onClickEnviarComprovantes)
      this.props.onClickEnviarComprovantes();
  }
  handleEnviarLaudoVistoria = () => {
    if (this.props.onClickEnviarLaudoVistoria)
      this.props.onClickEnviarLaudoVistoria();
  }

  handleAssinarContrato = () => {
    if (this.props.onClickAssinarContrato)
      this.props.onClickAssinarContrato();
  }

  render() {
    let stepNumber = 0;
    let textoPendencias = '';
    if (this.props.pendencias && this.props.pendencias.length > 0) {
      for (let x = 0; x < Math.min(this.props.pendencias.length, 5); x++)
        textoPendencias += '<br /> <b>- ' + this.props.pendencias[x].msgReduzida + '</b>';
    }

    if (['RECEBIDA', 'CONFIRMADA', 'DADOS_COMPRADOR', 'ANALISE_DADOS_COMPRADOR', 'DADOS_COMPRADOR_APROVADO', 'DADOS_COMPRADOR_REPROVADO'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 1;// COMPLEMENTO DE DADOS PARA FORMALIZAÇÃO
    else if (['DADOS_VENDEDOR', 'ANALISE_DADOS_VENDEDOR', 'DADOS_VENDEDOR_APROVADO', 'DADOS_VENDEDOR_REPROVADO'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 2;// COMPLEMENTO DE DADOS PARA FORMALIZAÇÃO
    else if (['ANALISE_CREDITO', 'CREDITO_APROVADO', 'CREDITO_REPROVADO'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 3; //ANALISE_CREDITO
    else if (['ENVIO_COMPROVANTES', 'ANALISE_COMPROVANTES', 'COMPROVANTES_REPROVADOS', 'COMPROVANTES_APROVADOS'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 4; //ENVIO DE COMPROVANTES
    else if (['AGUARDANDO_ASSINATURA_DIGITAL', 'ANALISE_ASSINATURA_DIGITAL', 'ASSINATURA_DIGITAL_REPROVADA', 'ASSINATURA_DIGITAL_APROVADA'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 5; //FORMALIZAÇÃO
    else if (['LIBERACAO', 'AGUARDANDO_LIBERACAO', 'LIBERACAO_REPROVADA'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 6;//  LIBERACAO
    else if (['FINALIZADA'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 7;//  PAGAMENTO EFETUADO
    else if (['REPROVADA', 'CANCELADA', 'EXPIRADA'].indexOf(this.props.proposta.cdStatus) > -1)
      stepNumber = 99; //REPROVADA E CANCELADA


    let possuiPendencias = this.props.pendencias && this.props.pendencias.length > 0;

    return !this.props.proposta ? null : <Fragment>
      {/* REPROVADA */}
      {stepNumber === 99 ? <div className="row animated fadeIn py-1">
        <ChecklistItem
          number={'1'}
          title={
            ['REPROVADA'].indexOf(this.props.proposta.cdStatus) > -1 ? 'Proposta reprovada!'
              : ['CANCELADA'].indexOf(this.props.proposta.cdStatus) > -1 ? 'Proposta cancelada!'
                : ['EXPIRADA'].indexOf(this.props.proposta.cdStatus) > -1 ? 'Proposta expirada!'
                  : ''}
          description={
            ['REPROVADA'].indexOf(this.props.proposta.cdStatus) > -1 ? 'Esta proposta foi reprovada!'
              : ['CANCELADA'].indexOf(this.props.proposta.cdStatus) > -1 ? 'Esta proposta foi cancelada!'
                : ['EXPIRADA'].indexOf(this.props.proposta.cdStatus) > -1 ? 'Esta proposta está expirada!'
                  : ''}
          error={true}
          current={true} />
      </div> : null}

      {stepNumber !== 99 && stepNumber !== 98 ? <Fragment>
        {stepNumber <= 1 ?
          <div className="row animated fadeIn py-1">
            <ChecklistItem number={'1'}
              title={['RECEBIDA', 'DADOS_COMPRADOR'].includes(this.props.proposta.cdStatus) ? 'Preencha seus dados' : 'Em análise'}
              html={['RECEBIDA', 'DADOS_COMPRADOR'].includes(this.props.proposta.cdStatus) ? possuiPendencias ? 'Preencha seu cadastro corretamente. Existem pendências: ' + textoPendencias
                : 'Verifique os dados preenchidos para confirmar se está tudo correto. Logo após, você pode enviar para análise do crédito.'
                : 'Nossa equipe está analisando os dados enviados. Fique atento, você receberá uma mensagem com as próximas instruções. Você também pode consultar esta proposta através deste aplicativo.'}
              action={['RECEBIDA', 'DADOS_COMPRADOR'].includes(this.props.proposta.cdStatus) ? possuiPendencias ? 'Preencher cadastro' : 'Verificar e encaminhar proposta' : null}
              onClickAction={['RECEBIDA', 'DADOS_COMPRADOR'].includes(this.props.proposta.cdStatus) ? possuiPendencias ? this.handlePreencherDados : this.handleEnviarProposta : null}
              complete={stepNumber > 1}
              current={stepNumber === 1} />
          </div> : <div className="row animated fadeIn py-1">
            <ChecklistItem number={'1'}
              title={'Cadastro'}
              complete={stepNumber > 1}
              current={stepNumber === 1} />
          </div>}
        {this.props.proposta.cdOrigem == 'OLX' ?
          stepNumber <= 2 ?
            <div className="row animated fadeIn py-1">
              <ChecklistItem number={'2'}
                title={this.props.proposta.cdStatus === 'ANALISE_DADOS_VENDEDOR' ? 'Em análise' : 'Dados do veículo'}
                html={this.props.proposta.cdStatus === 'DADOS_VENDEDOR' ? 'ATENÇÃO: precisamos que o vendedor cadastre as informações do veículo. Clique agora no botão abaixo para enviar o link da proposta ao vendedor. Caso ele não cadastre os dados do veículo, a proposta não será aprovada.'
                  : 'Nossa equipe está analisando os dados enviados. Fique atento, você receberá uma mensagem com as próximas instruções. Você também pode consultar esta proposta através deste aplicativo.'}
                action={this.props.proposta.cdStatus === 'DADOS_VENDEDOR' ? 'Enviar link ao vendedor' : null}
                onClickAction={this.props.proposta.cdStatus === 'DADOS_VENDEDOR' ? this.handleNotificarVendedor : null}
                complete={stepNumber > 2}
                current={stepNumber === 2} />
            </div> : <div className="row animated fadeIn py-1">
              <ChecklistItem number={'2'}
                title={'Dados do veículo'}
                complete={stepNumber > 2}
                current={stepNumber === 2} />
            </div>
          : null}

        {this.props.proposta.cdOrigem != 'OLX' ?
          stepNumber <= 2 ?
            <div className="row animated fadeIn py-1">
              <ChecklistItem number={'2'}
                title={this.props.proposta.cdStatus === 'ANALISE_DADOS_VENDEDOR' ? 'Em análise' : 'Dados do veículo'}
                html={this.props.proposta.cdStatus === 'DADOS_VENDEDOR' ? possuiPendencias ? 'Preencha seu cadastro corretamente. Existem pendências: ' + textoPendencias
                  : 'Verifique os dados preenchidos para confirmar se está tudo correto. Logo após, você pode enviar para análise do crédito.'
                  : 'Nossa equipe está analisando os dados enviados. Fique atento, você receberá uma mensagem com as próximas instruções. Você também pode consultar esta proposta através deste aplicativo.'}
                action={this.props.proposta.cdStatus === 'DADOS_VENDEDOR' ? possuiPendencias ? 'Preencher cadastro' : 'Verificar e encaminhar proposta' : null}
                onClickAction={this.props.proposta.cdStatus === 'DADOS_VENDEDOR' ? possuiPendencias ? this.handlePreencherDados : this.handleEnviarProposta : null}
                complete={stepNumber > 2}
                current={stepNumber === 2} />
            </div> : <div className="row animated fadeIn py-1">
              <ChecklistItem number={'2'}
                title={'Dados do veículo'}
                complete={stepNumber > 2}
                current={stepNumber === 2} />
            </div>
          : null}



        {stepNumber <= 3 ?
          <div className="row animated fadeIn py-1">
            <ChecklistItem number={'3'}
              title={'Análise de crédito'}
              description={this.props.permiteSalvar ?
                'Sua proposta está sendo analisada por nossa equipe. Fique atento, você receberá uma mensagem com as próximas instruções. Você também pode salvar esta proposta na tela inicial do seu celular para consultá-la quando quiser.'
                : 'Sua proposta está sendo analisada por nossa equipe. Fique atento, você receberá uma mensagem com as próximas instruções. Você também pode consultar esta proposta através deste aplicativo.'}
              action={this.props.permiteSalvar ? 'Salvar proposta' : null}
              onClickAction={this.props.permiteSalvar ? this.handleSalvarProposta : null}
              complete={stepNumber > 3}
              current={stepNumber === 3} />
          </div> : <div className="row animated fadeIn py-1">
            <ChecklistItem number={'3'}
              title={'Crédito aprovado'}
              complete={stepNumber > 3}
              current={stepNumber === 3} />
          </div>}

        {stepNumber <= 4 ?
          <div className="row animated fadeIn py-1">
            <ChecklistItem number={'4'}
              title={this.props.proposta.cdStatus === 'ANALISE_COMPROVANTES' ? 'Análise dos comprovantes' : 'Envio de comprovantes'}
              description={this.props.proposta.cdStatus === 'ENVIO_COMPROVANTES' ? 'Parabéns! A proposta foi pré-aprovada! Agora você precisa enviar alguns comprovantes. Lembre-se de que as imagens deve estar legíveis!'
                : 'Nossa equipe está analisando os comprovantes enviados. Fique atento, você receberá uma mensagem com as próximas instruções. Você também pode salvar esta proposta na tela inicial do seu celular para consultá-la quando quiser.'}
              action={this.props.proposta.cdStatus === 'ENVIO_COMPROVANTES' ? 'Enviar comprovantes' : null}
              onClickAction={this.props.proposta.cdStatus === 'ENVIO_COMPROVANTES' ? this.handleEnviarComprovantes : null}
              complete={stepNumber > 4}
              current={stepNumber === 4} />
          </div> : <div className="row animated fadeIn py-1">
            <ChecklistItem number={'4'}
              title={'Comprovantes aprovados'}
              complete={stepNumber > 4}
              current={stepNumber === 4} />  </div>}

        {stepNumber <= 5 ?
          <div className="row animated fadeIn py-1">
            <ChecklistItem number={'5'}
              title={this.props.proposta.cdStatus === 'ANALISE_ASSINATURA_DIGITAL' ? 'Analisando assinatura' : 'Assinatura do contrato'}
              description={this.props.proposta.cdStatus === 'AGUARDANDO_ASSINATURA_DIGITAL' ?
                'Agora você precisa assinar seu contrato e os documentos enviados. Para isso, basta clicar no link abaixo:'
                : 'Nossa equipe está avaliando a assinatura dos documentos. Assim que tudo estiver finalizado você receberá uma mensagem informando os próximos passos.'}
              action={this.props.proposta.cdStatus === 'AGUARDANDO_ASSINATURA_DIGITAL' ? 'Assinar meu contrato' : null}
              onClickAction={this.props.proposta.cdStatus === 'AGUARDANDO_ASSINATURA_DIGITAL' ? this.handleAssinarContrato : null}
              complete={stepNumber > 5}
              current={stepNumber === 5} />
          </div> : <div className="row animated fadeIn py-1">
            <ChecklistItem number={'5'}
              title={'Contrato assinado'}
              complete={stepNumber > 5}
              current={stepNumber === 5} />
          </div>}

        {/* LIBERAÇÃO E FINALIZAÇÃO */}
        {stepNumber <= 6 ? <div className="row animated fadeIn py-1">
          <ChecklistItem number={'6'}
            title={'Liberação do crédito'}
            description={'Os documentos foram enviados ao banco para análise e liberação do crédito. A equipe do banco poderá entrar em contato para confirmar informações. Fique atento ao seu celular.'}
            complete={stepNumber > 6}
            current={stepNumber === 6} />
        </div> : <div className="row animated fadeIn py-1">
          <ChecklistItem number={'6'}
            title={'Pagamento efetuado'}
            description={'Parabéns! A proposta foi aprovada e o pagamento já foi encaminhado! Caso tenha alguma dúvida, fale com a gente.'}
            action={'Dúvidas? Fale com a gente.'}
            onClickAction={this.handleFalarWhatsApp}
            complete={stepNumber > 7}
            current={stepNumber === 7} />
        </div>}

      </Fragment> : null}
    </Fragment>
  }
}

export default ChecklistFormalizacao;
import React, { Fragment } from "react";
// import { createBrowserHistory } from 'history';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import "./assets/styles.css";

import Inicio from "./pages/inicio";
import Servicos from "./pages/servicos";
import Produtos from "./pages/produtos";
import Clientes from "./pages/clientes";
import Jornada from "./pages/jornada";
import TermosUsoPage from "./pages/termos";
import TermosUsoTarionPage from "./pages/termos-tarion";
import PoliticaPrivacidadePage from "./pages/politica-privacidade";
import PoliticaDadosPage from "./pages/politica-dados";
import { Cookie } from "./components/modal-cookie"
import sessionStoreService from "./services/session-store.service";
import ParamsUtil from "./utils/params";

export default () => {
  let params = ParamsUtil.getUrlParams(window.location.hash);
  if (params && params.origem) {
    let cdOrigem = params.origem;
    let dtOrigem = new Date().getTime();
    sessionStoreService.saveGenericData('cdOrigem', cdOrigem);
    sessionStoreService.saveGenericData('dtOrigem', dtOrigem);
  }

  return (
    <Fragment>
      <Router >

        <Switch >
          <Route path="/termos-uso" exact component={props => <TermosUsoPage {...props} />} />
          <Route path="/termos-uso-tarion" exact component={props => <TermosUsoTarionPage {...props} />} />
          <Route path="/politica-privacidade" exact component={props => <PoliticaPrivacidadePage {...props} />} />
          <Route path="/politica-dados" exact component={props => <PoliticaDadosPage {...props} />} />

          <Route path="/inicio" exact component={props => <Inicio {...props} />} />
          <Route path="/inicio/:cdOrigem" exact component={props => <Inicio {...props} />} />
          <Route path="/clientes" component={props => <Clientes {...props} />} />
          <Route path="/jornada" component={props => <Jornada {...props} />} />
          <Route path="/j" component={props => <Jornada {...props} />} />
          <Route path="/servicos" component={props => <Servicos {...props} />} />
          <Route path="/produtos" component={props => <Produtos {...props} />} />

          {/* Rotas para legado. */}
          <Route path="/cp/:cdProposta" render={(props) => <Redirect to={`/produtos/veiculos/cp/${props.match.params.cdProposta}`} />} />
          <Route path="/auth/:cdProposta" render={(props) => <Redirect to={`/produtos/veiculos/cp/${props.match.params.cdProposta}`} />} />

          <Route render={() => <Redirect to="/inicio" />} />
        </Switch>

      </Router>
      <Cookie />
    </Fragment>
  );
}

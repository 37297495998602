import React, { Fragment } from "react";
import Page from '../../page';

import Table from "../../../components/table";
import ContratosService from "../../../services/contratos.service";
import AbaProposta from '../../../components/aba-proposta';
import SessionStore from '../../../services/session-store.service';
import TextUtils from '../../../utils/text';
import authService from "../../../services/auth.service";
import Loader from '../../../components/loader';
import sessionStoreService from "../../../services/session-store.service";
import analyticsService from "../../../services/analytics.service";
import BannerCampanha from "../../../components/banner-campanha";


class ListarContratos extends Page {
    constructor(props) {
        super(props);

        analyticsService.sendPageView('servicos/contratos', 'Serviços - Contratos');

        let cliente = SessionStore.getGenericData('cliente');
        let dsCliente = cliente && cliente.dsCliente ? TextUtils.getFirstNameWithUpperCase(cliente.dsCliente) : '';

        this.state = {
            loader: false,
            error: undefined,
            dsCliente,
            contratos: [],
            cliente: {}
        }

        this.cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        this.setState({ loader: { message: 'Iniciando aplicação...', show: true } });
        let contratos;

        try {
            contratos = await ContratosService.listarContratos();
            this.setState({ contratos });
        } catch (err) {
            console.error(err);
            this.setState({ error: err.msg || 'Falha ao listar os contratos. Tente novamente ou contate nosso suporte.' });
            analyticsService.sendException(err, false);
        }

        this.setState({ loader: {} });
        if (contratos && contratos.length === 1) {
            window.location.hash = `servicos/contratos/${encodeURIComponent(this.state.contratos[0].cdContrato)}`;
        }

        if ((!contratos || contratos.length <= 0) && ['MUTIRAO_FEBRABAN'].includes(this.cdOrigem)) {
            let ultimaSolicitacao = sessionStoreService.getGenericData('solicitacaoContato');
            if (!ultimaSolicitacao || ultimaSolicitacao < new Date().getTime() - 3600000) {
                await sessionStoreService.saveGenericData('solicitacaoContato', new Date().getTime());
                await ContratosService.solicitarContato();
            }
        }
    }

    onListItemClick = async (index, values) => {
        analyticsService.sendEvent('selecionou_contrato');
        window.location.hash = `servicos/contratos/${encodeURIComponent(this.state.contratos[index].cdContrato)}`;
    }

    handleSair = () => {
        authService.clearAuthData();
        sessionStoreService.clearSession();
        window.location.hash = '/inicio'
    }

    handleVoltarMenu = () => {
        window.location.hash = '/inicio'
    }

    render() {
        return <Fragment>
            <AbaProposta
                key={'Contratos'}/*  icon={aba.icon}  */
                label={'Meus contratos'}
                active={true}
            />
            {this.state.loader.show ? <Loader message={this.state.loader.message} show={this.state.loader.show} />
                : this.state.contratos && this.state.contratos.length > 0 ? <div className="container-fluid">
                    <div className="mt-1">
                        <Table
                            onListItemClick={this.onListItemClick}
                            rows={ContratosService.mapContratosToTable(this.state.contratos || [])}
                        />
                        <button className="btn btn-block btn-secondary mt-4" onClick={this.handleVoltarMenu}>Voltar ao início</button>
                    </div>
                </div>
                    : <div className="container-flex">
                        <BannerCampanha campanha={this.cdOrigem} />

                        <div className="col-12 pt-3">
                            <h5>Olá{this.state.cliente ? ' ' + this.state.dsCliente : ''}!</h5>
                            {this.state.error ?
                                <h6 className="mt-4">{this.state.error}</h6> :
                                <Fragment>
                                    {['MUTIRAO_FEBRABAN'].includes(this.cdOrigem) ?
                                        <Fragment>
                                            <h6 className="mt-4">Obrigado por procurar o Banco Daycoval!</h6>
                                            <h6 className="mt-4">Para seu melhor atendimento e apresentação de nossa oferta do Mutirão Febraban, entraremos em contato com você pelo telefone ou e-mail informado. Fique atento!</h6>
                                        </Fragment>
                                        : <Fragment>
                                            <h6 className="mt-4">Você não possui contratos disponíveis para acessar através deste aplicativo.</h6>
                                            <h6 className="mt-4">Você pode solicitar informações adicionais ligando para:</h6>
                                            <a href="tel:03001110500"><h5>0300 111 0500</h5></a>
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                            <button className="btn btn-block btn-secondary mt-5" onClick={this.handleVoltarMenu}>Voltar ao início</button>
                            {/* <button className="btn btn-block btn-secondary my-4" onClick={this.handleSair}>Sair</button> */}
                        </div>
                    </div>
            }
        </Fragment >
    }
}

export default ListarContratos;
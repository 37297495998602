import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';

import Msgs from './msgs';
import Utils from '../utils/text';

class CampoValor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: '',
      err: '',
      emBranco: true,
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.id;
    let value = target.value || '';

    let emBranco = String(value).replace(/[^a-z0-9]/gi, "").length < 1

    value = this.aplicarFormatacao(value);
    this.setState({
      [name]: value,
      emBranco
    });
  }

  aplicarFormatacao = (value) => {
    if (this.props.formatacao !== 'mascara') {
      if (this.props.tamanho) {
        value = value.substr(0, this.props.tamanho);
      }

      if (this.props.maximo) {
        if (parseInt(value.replace(/[^\d]+/g, "")) > this.props.maximo) value = String(this.props.maximo);
      }
    }

    if (this.props.formatacao) {
      switch (this.props.formatacao) {
        case 'moeda':
          value = Utils.formatarMoedaV2('R$ ', value);
          break;
        case 'digitos':
          value = value.replace(/[^\d]+/g, "");
          break;
        default:
          break;
      }
    }
    return value;
  }

  handleConfirmar = () => {

    if (this.props.formatacao !== 'mascara') {
      if (this.props.minimo) {
        if (!this.state.valor || parseInt(this.state.valor.replace(/[^\d]+/g, "")) < this.props.minimo) {
          let valor = String(this.props.minimo);
          valor = this.aplicarFormatacao(valor);
          this.setState({ valor, err: 'O valor foi alterado para o mínimo possível.' });
          return;
        }
      }
    }

    if (this.props.tipo === 'cpf') {
      if (!Utils.validarCPF(this.state.valor)) {
        this.setState({ err: 'O CPF digitado não é válido.' });
        return;
      }
    }

    if (this.props.tipo === 'email') {
      if (!Utils.validarEmail(this.state.valor)) {
        this.setState({ err: 'O e-mail digitado não é válido.' });
        return;
      }
    }

    if (this.props.tipo === 'celular') {
      if (!Utils.validarCelular(this.state.valor)) {
        this.setState({ err: 'O telefone celular digitado não é válido.' });
        return;
      }
    }

    if (this.props.tipo === 'data') {
      let tmpData = moment.utc(this.state.valor, 'DD/MM/YYYY', true);
      if (!tmpData.isValid()) {
        this.setState({ err: 'A data digitada não é válida.' });
        return;
      }
    }

    if (this.props.obrigatorio && (!this.state.valor || String(this.state.valor).length < 1)) {
      this.setState({ err: 'Você deve preencher corretamente esta informação.' });
      return;
    }

    this.props.onClickConfirmar(this.state.valor);
  }
  handleRecuperarAcesso = () => {

    this.props.onClickRecuperarAcesso();
  }

  handleVoltar = () => {
    if (this.props.onClickVoltar)
      this.props.onClickVoltar();
  }

  render() {
    return <div className="row justify-content-center py-2">

      {this.props.label ? <h5 className="text-center">{this.props.label}</h5> : null}

      <div className="col-12 py-2">
        {this.props.tipo === 'opcoes' ?
          <select className="form-control" id="valor" value={this.state.valor} onChange={this.handleInputChange} placeholder={this.props.placeholder || ''}>
            <option disabled={true} value="">{this.props.placeholder || 'Selecione'}</option>
            {this.props.opcoes ? this.props.opcoes.map(o => <option key={o.cdOpcao} value={o.cdOpcao}>{o.dsOpcao}</option>) : null}
          </select>

          : this.props.formatacao === 'mascara' && this.props.mascara ?
            <InputMask mask={this.props.mascara} className="form-control text-center" id="valor" value={this.state.valor} onChange={this.handleInputChange} placeholder={this.props.placeholder || 'Informe o valor desejado'} />
            : <input type={this.props.type} className="form-control text-center" id="valor" value={this.state.valor} onChange={this.handleInputChange} placeholder={this.props.placeholder || 'Informe o valor desejado'} />
        }
      </div>

      {this.state.err ?
        <div className="col-12 py-2">
          <Msgs error={this.state.err} />
        </div>
        : null
      }
      {this.props.mensagem ? <p className="text-center text-muted ">{this.props.mensagem}</p> : null}

      {this.props.obrigatorio && this.props.esconderOk ? null :
        <div className="col-12">
          <button className="btn btn-block btn-primary"
            onClick={(e) => this.handleConfirmar()}
          >{this.props.textoAcao || 'Confirmar'}</button>
        </div>

      }
      {this.props.textoVoltar ?
        <div className="col-12 pt-2">
          <button className="btn btn-block btn-secondary"
            style={{ paddingBottom: '10px', paddingTop: '10px' }}
            onClick={(e) => this.handleVoltar()}
          >{this.props.textoVoltar || 'Voltar'}</button>
        </div> : null}
    </div>
  }
}

export default CampoValor;
import ApiService from "./api.service";

class DataService {

  async listarCamposFormularios() {
    return await ApiService.get(`financiamentos/listar-campos-formularios`);
  }

}

export default new DataService()
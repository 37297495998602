import React, { Component, Fragment } from 'react';
import Recaptcha from 'react-recaptcha';
import moment from 'moment';
import SessionStore from '../../../services/session-store.service';
import ClientesService from '../../../services/clientes.service';

import Environment from '../../../environment';
import CampoValor from '../../../components/campo-valor';

import Loader from '../../../components/loader';
import Msgs from '../../../components/msgs';
import AuthService from '../../../services/auth.service';
import analyticsService from '../../../services/analytics.service';
import Navbar from '../../../components/navbar';
import sessionStoreService from '../../../services/session-store.service';
import BannerCampanha from '../../../components/banner-campanha';
class AutenticarCliente extends Component {

  constructor(props) {
    super(props);

    analyticsService.sendPageView('clientes/auth', 'Login Cliente');

    this.state = {
      loader: {},
      cliente: {
        digitosCpf: '',
        dataNascimento: '',
        numeroCelular: '',
        dsEmail: ''
      },
      url: this.props.match.url,
      flAceitoNotificacoes: true,
      flVerificar: false,
      esconderOk: true,
      auth: null,
      cdProposta: SessionStore.getGenericData('cdPropostaAutenticacao'),
      cdToken: undefined,
      flTokenEnviado: false,
      exibirAceito: false,
      etapa: undefined,
      error: undefined,
      textoAcao: undefined,
      celularDigitos: undefined,
      email: undefined,
      flNovoNumeroCelular: undefined,
      cdOrigem: sessionStoreService.getGenericData('cdOrigem'),
      aba: 'AUTENTICACAO'
    };

  }

  componentDidMount() {

    if (AuthService.isAuthenticated()) {
      let redirecionamento = sessionStoreService.getGenericData('redirecionamentoAutenticacao');
      if (redirecionamento) {
        SessionStore.clearGenericData('redirecionamentoAutenticacao')
        return window.location.hash = redirecionamento;
      } else {
        return window.location.hash = '/'
      }
    } else {
      if (this.state.cdProposta) {
        this.setState({
          etapa: 'SOLICITAR_DIGITOS_CPF',
          textoAcao: 'Não tenho acesso a este número.'
        })

      } else {
        this.setState({
          etapa: 'SOLICITAR_CPF',
          textoAcao: 'Não tenho acesso a este número.'
        });

      }

    }
  }



  handleRecuperarAcesso = (etapa) => {
    switch (etapa) {
      case 'SOLICITAR_TOKEN':
        this.setState({ etapa: 'SOLICITAR_CELULAR_COMPLETO', textoAcao: 'Não reconheço este número' });
        break;
      case 'SOLICITAR_CELULAR_COMPLETO':
        this.setState({ etapa: 'SOLICITAR_EMAIL_COMPLETO' });
        break;

      default:
        break;
    }

  }
  handleVerificarDados = async (valor) => {
    this.setState({ loader: { show: true, message: 'Aguarde...' }, error: null });

    let cdCliente = String(this.state.cliente.digitosCpf).replace(/[^\w\s]/g, '');
    let dsContato = valor
    try {
      let flVerificado = await ClientesService.verificarDadosAcesso({ cdCliente, dsContato })
      if (flVerificado)
        this.setState({ error: null, etapa: 'SOLICITAR_CELULAR', msNumero: 'novo', flNovoNumeroCelular: true, exibirAceito: true });

    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao verificar o número informado, verifique os dados e tente novamente.' });
      analyticsService.sendException(err, false);
    }

    this.setState({ loader: {} });
  }

  handleCPFDigitado = (valor) => {
    let cliente = this.state.cliente;
    cliente.digitosCpf = valor;
    SessionStore.saveGenericData('cdCliente', cliente.digitosCpf);
    this.setState({ cliente, error: null, etapa: 'SOLICITAR_DATA_NASCIMENTO' });
  }

  handleDataNascimentoDigitado = (valor) => {
    let cliente = this.state.cliente;
    cliente.dataNascimento = valor;
    SessionStore.saveGenericData('dtNascimento', cliente.dataNascimento);
    this.setState({ cliente, error: null }, this.solicitarTokenWhatsapp);
  }

  handleCelularDigitado = async (valor) => {
    let cliente = this.state.cliente;
    cliente.numeroCelular = valor;
    SessionStore.saveGenericData('cdCelular', cliente.numeroCelular);
    this.setState({ cliente, error: null }, this.solicitarTokenWhatsapp);
  }

  handleTokenDigitado = (valor) => {
    let cdToken = valor;
    this.setState({ cdToken, error: null }, this.autenticar);
  }

  handleEmailDigitado = (valor) => {
    let cliente = this.state.cliente;
    cliente.dsEmail = valor;
    SessionStore.saveGenericData('dsEmail', cliente.dsEmail);
    this.setState({ cliente, error: null }, this.persistirEmail);
  }

  persistirEmail = async () => {
    this.setState({ loader: { show: true, message: 'Aguarde...' } });
    try {
      let cliente = SessionStore.getGenericData('cliente')
      cliente.dsEmail = this.state.cliente.dsEmail

      await ClientesService.persistirPerfil(cliente);
      let redirecionamento = SessionStore.getGenericData('redirecionamentoAutenticacao');
      if (redirecionamento) {
        SessionStore.clearGenericData('redirecionamentoAutenticacao')
        return window.location.hash = redirecionamento;
      } else {
        return window.location.hash = '/'
      }
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao solicitar token, verifique os dados e tente novamente.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  solicitarTokenWhatsapp = async () => {
    this.setState({ loader: { show: true, message: 'Aguarde...' } });
    try {
      let cdCliente = String(this.state.cliente.digitosCpf).replace(/[^\w\s]/g, '');
      let dtNascimento = moment(this.state.cliente.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
      let cdCelular = String(this.state.cliente.numeroCelular ? this.state.cliente.numeroCelular : '').replace(/[^\w\s]/g, '');
      let { flTokenEnviado, celular, email } = await ClientesService.solicitarTokenWhatsapp({
        cdCliente,
        dtNascimento,
        cdCelular,
        flNovoNumeroCelular: this.state.flNovoNumeroCelular ? this.state.flNovoNumeroCelular : undefined,
        flAceitoNotificacoes: cdCelular ? this.state.flAceitoNotificacoes : undefined,
        cdProposta: this.state.cdProposta
      });
      if (flTokenEnviado) {
        this.setState({ etapa: 'SOLICITAR_TOKEN', celularDigitos: celular, email: email });
      }
      else {
        this.setState({ etapa: 'SOLICITAR_CELULAR', exibirAceito: true });
      }
    } catch (err) {
      console.error(err);
      if (err.type == 'DADOS_NAO_ENCONTRADOS') {
        sessionStoreService.clearAuthData('cdPropostaAutenticacao');
        this.setState({ error: err.msg || 'Falha ao solicitar token, verifique os dados e tente novamente.', cdProposta: undefined, etapa: 'SOLICITAR_CPF' });
      } else {
        this.setState({ error: err.msg || 'Falha ao solicitar token, verifique os dados e tente novamente.' });

      }
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked || '';
    const name = target.name;
    this.setState({ [name]: value });
  }

  autenticar = async () => {
    analyticsService.sendEvent('login');

    this.setState({ loader: { show: true, message: 'Aguarde...' } });
    try {
      let cdCliente = String(this.state.cliente.digitosCpf).replace(/[^\w\s]/g, '');
      let dtNascimento = moment(this.state.cliente.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');
      let cdToken = String(this.state.cdToken).replace(/[^\w\s]/g, '');
      let cdProposta = this.state.cdProposta;
      let auth = await ClientesService.autenticar({ cdCliente, dtNascimento, cdToken, cdProposta });
      if (!auth || !auth.token) {
        if (auth.status === 'not_found') {
          return this.setState({
            error: 'Não encontramos seu cadastro.',
            cliente: {
              digitosCpf: '',
              dataNascimento: '',
              numeroCelular: '',
            }
          });

        }
        else {
          return this.setState({
            error: 'As informações digitadas estão incorretas. Verifique e tente novamente.',
            cliente: {
              digitosCpf: '',
              dataNascimento: '',
              numeroCelular: '',
            }
          });
        }
      }

      AuthService.setAuthData(auth);
      let cliente = await ClientesService.recuperar();
      SessionStore.saveGenericData('cliente', cliente);
      if (!cliente.dsEmail) {
        this.setState({ loader: {}, etapa: 'SOLICITAR_EMAIL' });
      } else {
        let redirecionamento = SessionStore.getGenericData('redirecionamentoAutenticacao');
        if (redirecionamento && auth) {
          SessionStore.clearGenericData('redirecionamentoAutenticacao')
          return window.location.hash = redirecionamento;
        } else {
          return window.location.hash = '/'
        }
      }


    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao autenticar, verifique os dados e tente novamente.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ loader: {} });
  }

  handleCancelar = () => {
    window.location.hash = `#/`;
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({ flVerificar: true, esconderOk: false })
    }
  }

  render() {
    return <Fragment>
      <Loader message={this.state.loader.message} show={this.state.loader.show} />
      <BannerCampanha campanha={this.cdOrigem} />

      <div className="container-fluid">
        <div className="row justify-content-center" >
          <div className="col-12 col-sm-6 col-md-6 col-lg-6">
            <div className="animated fadeIn py-4" >
              <Msgs info={this.state.msg} error={this.state.error} />

              {this.state.etapa === 'SOLICITAR_CPF' ? <Fragment>
                <h4 className="text-center pb-3" style={{ fontWeight: 'bold' }}>Obrigado por escolher o Daycoval para financiar seu veículo!</h4>
                <h5 className="text-center pb-3" style={{}}>Falta pouco para finalizarmos. Primeiro vamos garantir que temos seu contato correto e dar segurança à todo processo.</h5>
                <CampoValor
                  label={'Por gentileza, digite o seu CPF:'}
                  mensagem={'Precisamos do CPF para iniciar o processo de autenticação e garantir segurança no acesso às informações. O seu CPF é a sua chave de acesso.'}
                  placeholder={this.state.tokenVendedor || this.state.isDocumentos ? 'CPF do cliente' : 'Seu CPF'}
                  obrigatorio={true}
                  formatacao={'mascara'}
                  tipo={'cpf'}
                  mascara={'999.999.999-99'}
                  onClickConfirmar={this.handleCPFDigitado} />
              </Fragment> : null}



              {this.state.etapa === 'SOLICITAR_DATA_NASCIMENTO' ? <Fragment>
                <CampoValor
                  label={'Agora precisamos confirmar sua data de nascimento:'}
                  mensagem={'A data de nascimento será utilizada para garantir que estamos falando com a pessoa certa. Ela faz parte da primeira camada de segurança junto de seu CPF.'}
                  placeholder={'DD/MM/AAAA'}
                  formatacao={'mascara'}
                  tipo={'data'}
                  obrigatorio={true}
                  mascara={'99/99/9999'}
                  esconderOk={this.state.esconderOk}
                  onClickConfirmar={this.handleDataNascimentoDigitado} />
                <div className='row justify-content-center m-0'>
                  <Recaptcha
                    sitekey={Environment.get('TOKEN_RECAPTCHA')}
                    render="explicit"
                    hl="pt-BR"
                    verifyCallback={this.verifyCallback}
                  />
                </div>
              </Fragment> : null}

              {this.state.etapa === 'SOLICITAR_CELULAR' ? <Fragment>
                <h5 className="text-center pb-3" style={{}}>Digite seu número de Whatsapp. Este será nosso principal meio de contato</h5>
                <CampoValor
                  label={`Informe seu ${this.state.msNumero ? this.state.msNumero : ''} número WhatsApp:`}
                  mensagem={'ATENÇÃO: precisamos do seu número de WhatsApp para realizar a autenticação em 2 fatores.'}
                  placeholder={'(DDD) 99999-9999'}
                  formatacao={'mascara'}
                  tipo={'celular'}
                  obrigatorio={true}
                  esconderOk={false}
                  mascara={'(99) 99999-9999'}
                  onClickConfirmar={this.handleCelularDigitado} />
                {this.state.exibirAceito ? <div className="form-check pt-2">
                  <input type="checkbox" className="form-check-input" id="flAceitoNotificacoes" name="flAceitoNotificacoes" checked={this.state.flAceitoNotificacoes} onChange={this.handleCheckboxChange} />
                  <label className="form-check-label-center" htmlFor="flAceitoNotificacoes">Autorizo o Banco Daycoval e seu correspondente bancário a enviar comunicações ativas pelo aplicativo Whatsapp, para
                    assuntos relacionados a aprovação de crédito ou a qualquer contrato de financiamento em meu nome.</label>
                </div>
                  : null}

              </Fragment> : null}

              {this.state.etapa == 'SOLICITAR_DIGITOS_CPF' ? <Fragment>
                <CampoValor
                  label={'Informe os quatro primeiros números do seu cpf'}
                  mensagem={'ATENÇÃO: Seu numero é utilizado para entrar no sistema, dessa forma você não precisará de senha, em seguida enviaremos um token para finalizar a autenticação.'}
                  placeholder={'9999'}
                  formatacao={'mascara'}
                  tipo={'number'}
                  obrigatorio={true}
                  mascara={'9999'}
                  onClickConfirmar={this.handleCPFDigitado}
                />
              </Fragment> : null}

              {this.state.etapa === 'SOLICITAR_TOKEN' ? <CampoValor
                label={'Informe o token enviado para o seu WhatsApp'}
                mensagem={`Digite corretamente o token enviado ao seu WhatsApp no número ${this.state.celularDigitos}.`}
                placeholder={'TOKEN'}
                formatacao={''}
                tipo={'text'}
                obrigatorio={true}
                mascara={''}
                onClickConfirmar={this.handleTokenDigitado} />
                : null}

              {this.state.etapa === 'SOLICITAR_EMAIL' ? <CampoValor
                label={'Por gentileza, digite o seu E-mail:'}
                mensagem={'O seu e-mail servirá para recuperação de acesso, caso esqueça seu numero de celular.'}
                placeholder={'Informe seu E-mail'}
                obrigatorio={true}
                formatacao={''}
                tipo={'email'}
                mascara={'teste@teste.com'}
                onClickConfirmar={this.handleEmailDigitado} />
                : null}

              {this.state.etapa === 'SOLICITAR_CELULAR_COMPLETO' ? <Fragment>
                <CampoValor
                  label={`Precisamos que você informe o número de telefone ${this.state.celularDigitos}`}
                  mensagem={'ATENÇÃO: Precisamos que confirme o número para garantir que você é a pessoa certa.'}
                  placeholder={'(DDD) 99999-9999'}
                  formatacao={'mascara'}
                  tipo={'celular'}
                  obrigatorio={true}
                  esconderOk={false}
                  mascara={'(99) 99999-9999'}
                  onClickConfirmar={this.handleVerificarDados} />
              </Fragment> : null}

              {this.state.etapa === 'SOLICITAR_EMAIL_COMPLETO' ? <CampoValor
                label={`Por gentileza, Precisamos que você informe o e-mail: ${this.state.email}`}
                mensagem={'ATENÇÃO: Precisamos que confirme o e-mail para garantir que você é a pessoa certa..'}
                placeholder={'Informe seu E-mail'}
                obrigatorio={true}
                formatacao={''}
                tipo={'email'}
                mascara={'teste@teste.com'}
                onClickConfirmar={this.handleVerificarDados} />
                : null}


              {this.state.etapa === 'SOLICITAR_CELULAR_COMPLETO' || this.state.etapa === 'SOLICITAR_TOKEN' ?
                <p className="text-center">
                  <button type="button" className="btn btn-link m-0 p-0" onClick={(e) => this.handleRecuperarAcesso(this.state.etapa)} >{this.state.textoAcao}</button>
                </p> : null}

              <p className="text-muted pt-4 pb-4">Ao prosseguir com a autenticação, você confirma que está de acordo os
                <a href="#/termos-uso-tarion" target="_blank">Termos de Uso Tarion-Beck</a>,&nbsp;
                <a href="#/termos-uso" target="_blank">Termos de Uso do Portal do Cliente</a>,&nbsp;
                <a href="#/politica-privacidade" target="_blank">Políticas de Privacidade</a> e&nbsp;
                <a href="#/politica-dados" target="_blank">Políticas de Dados</a>.&nbsp;
                É muito importante para nós que você leia atentamente e tenha consciência das informações disponíveis nestes documentos.</p>


              {this.state.etapa === 'VERIFICANDO_CADASTRO' ? <h5 className="text-center">Estamos verificando os dados informados.</h5> : null}
              {this.state.etapa === 'VERIFICANDO_CADASTRO' ? <h5 className="text-center">Por favor, aguarde...</h5> : null}
              {this.state.etapa === 'VERIFICANDO_CADASTRO' ? <p className="text-center"><img alt="Daycoval" src={Environment.get('ASSETS') + '/img/search.svg'} style={{ height: '90px', paddingTop: '5px' }} className="animated pulse infinite slow" /></p> : null}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  }
}

export default AutenticarCliente;
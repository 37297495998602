import React from 'react';
import Environment from '../environment';

function AbaFormulario({ id = '', label = '', active = false, onClickAba = () => { } }) {
  return <div className="col px-1 py-2" key={id} onClick={onClickAba} style={{ borderRight: '1px solid #ddd', borderLeft: '1px solid #ddd' }}>
    <h6 className="m-0 p-0 text-center text-muted" style={{ fontSize: '12px' }}>
      <span className="text-center" style={{ display: 'block', padding: '2px' }}>
        <img alt="Daycoval" src={Environment.get('ASSETS') + (active ? '/img/radio_checked.svg' : '/img/radio_unchecked.svg')} style={{ height: '14px' }} />
      </span>
      {label}
    </h6>
  </div>
}

export default AbaFormulario
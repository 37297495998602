import React from 'react';
import TextUtil from '../../../utils/text';
import moment from 'moment';

export default function ViewListaOfertas({ ofertas = [], textoAceitar, ofertaSelecionada = undefined, onClickExibirOferta = () => { }, onClickConfirmarOferta = () => { }, onClickVoltar = () => { } }) {

  return <div className="row pt-3 m-0">
    <div className="col-12 pb-2">
      <h6 className="">Estas são as opções disponíveis:</h6>
    </div>
    {(ofertas || []).map((o, index) => <div key={index} className="col-12 pb-2" style={{ fontSize: '14px' }}>
      <button className="btn btn-pill btn-block btn-outline-info" style={{ borderRadius: '15px' }} onClick={() => onClickExibirOferta(o)}>{o.dsTitulo}</button>
      {ofertaSelecionada && ofertaSelecionada.cdOferta === o.cdOferta ? <div className="col-12 p-0 detalhe text-center font-weight-normal">

        <p className="m-0 pb-2 pt-3 titulo font-weight-bold">{o.dsTitulo}</p>
        <p className="m-0 pb-2 titulo" dangerouslySetInnerHTML={{ __html: o.dsInformacoes }}></p>

        {o.vlEntrada > 0 ? <p className="m-0 pb-2">Entrada: <span className="font-weight-bold">{TextUtil.formatarMoeda('R$', o.vlEntrada)}</span></p>
          : <p className="m-0 pb-2 font-weight-bold">Sem entrada</p>}

        {o.vlEntrada > 0 ? <p className="m-0">Pagamento da entrada: <span className="font-weight-bold">{moment.utc(o.dtPagamento).format('DD/MM/YYYY')}</span></p>
          : null}

        <p className="m-0">Valor das parcelas: <span className="font-weight-bold">{TextUtil.formatarMoeda('R$ ', o.vlParcelas)}</span></p>
        <p className="m-0">Quantidade de parcelas: <span className="font-weight-bold">{o.qtParcelas}</span></p>
        <p className="m-0 pb-2">Vencimento da primeira parcela: <span className="font-weight-bold">{moment.utc(o.dtVencimento).format('DD/MM/YYYY')}</span></p>

        <button className="btn btn-block btn-success mb-2"onClick={() => onClickConfirmarOferta(o)}>Aceitar esta oferta</button>
      </div> : null}
    </div>)}
    <div className="col-12">
      <button type="button" className="btn btn-block btn-secondary mb-2 " onClick={() => onClickVoltar()}>Voltar</button>

    </div>
  </div>
}
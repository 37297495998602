import React, { } from 'react';
import S3FileUpload from '../s3-file-upload';

export default function AdicionarArquivo({ componente = {}, onEvento = () => { } }) {
  async function handleGetUploadLink(dsArquivo) {
    let result = await onEvento(componente.props.cdEventoRecuperarLinkUpload, { dsArquivo });
    return result.dsUploadLink;
  }

  async function handleSalvarComprovante() {
    onEvento(componente.props.cdEventoUploadConcluido);
  }

  return (
    <div className="justify-content-center" style={{ marginBottom: 20 }}>
      <div style={{ borderRadius: '7px', border: '1px solid #B7B7B7', margin: '40px 40px 0 40px', backgroundColor: '#F2F2F2' }}>
        <S3FileUpload
          btnClass="btn btn-primary btn-block"
          labelClass="text-md"
          filename="comprovante"
          icon={true}
          directUpload={true}
          getUploadLink={handleGetUploadLink}
          onFileUploaded={handleSalvarComprovante}
        />
      </div>
    </div>
  )
}
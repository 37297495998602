import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router-dom";

import DetalheJornada from "./detail"

class Jornada extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return <Fragment>
            <Switch>
                <Route path="/jornada/:cdJornada" exact render={props => <DetalheJornada {...props} />} />
                <Route path="/j/:cdJornada" exact render={props => <DetalheJornada {...props} />} />
            </Switch>
        </Fragment>
    }
}
export default Jornada;
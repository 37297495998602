import React from 'react';
import Environment from '../environment';
import sessionStoreService from '../services/session-store.service';
import Estilos from "./estilos-parceiros";

function AbaProposta({ onClickAba = () => { }, icon = '', label = '', active = false, SizeFont = '' }) {
  let layout = (Estilos[sessionStoreService.getGenericData('cdOrigem') || ''] || Estilos.default).layout;

  return <div className="col py-2 px-1" onClick={onClickAba} style={active ? { background: layout.corPrincipal || '#0373b0', border: 'none' } : {}}>
    <h6 className="m-0 p-0 text-center" style={{ fontSize: SizeFont, color: '#fff', fontWeight: active ? 'bold' : 'normal' }}>
      {icon ?
        <span className="text-center" style={{ display: 'block', padding: '2px' }}>
          <img src={Environment.get('ASSETS') + '/img/' + icon} style={{ height: '20px' }} />
        </span>
        : null}
      {label}
    </h6>
  </div>
}

export default AbaProposta;
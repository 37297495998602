import React, { Component, Fragment } from 'react';
import sessionStoreService from '../services/session-store.service';
import Estilos from './estilos-parceiros';

class Footer extends Component {
  constructor(props) {
    super(props);
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem') || '';
    let layout = (Estilos[cdOrigem] || Estilos.default).layout;
    this.state = {
      layout,
      cdOrigem,
    }
  }

  render() {
    return <Fragment>
      <div className="animated fadeIn py-3 px-2 bg-primary footer" style={{ backgroundImage: `linear-gradient(to right, ${this.state.layout.cores.join(',')})`, width: "100%", }} >
        <p className="text-center mb-0" style={{ fontSize: '12px', color: this.state.layout.corTextoPrincipal || '#fff' }}>Tarion-Beck LTDA / Barueri - SP</p>
        <p className="text-center mb-0" style={{ fontSize: '12px', color: this.state.layout.corTextoPrincipal || '#fff' }}>Correspondente Autorizado Banco Daycoval S.A.</p>
        <p className="text-center mb-0" style={{ fontSize: '12px', color: this.state.layout.corTextoPrincipal || '#fff' }}>Atendimento: segunda à sexta das 10:00 às 17:00</p>
        <p className="text-center mb-0" style={{ fontSize: '12px', color: this.state.layout.corTextoPrincipal || '#fff' }}>Todos os direitos reservados.</p>
      </div>
      <div className="animated fadeIn py-3 px-2" >
        <p className="text-center mb-1" style={{ fontSize: '13px' }}>Termos de Uso e Políticas</p>
        <a href="#/termos-uso-tarion"><p className="text-center mb-1" style={{ fontSize: '12px' }}>Termos de uso dos serviços e produtos Tarion-Beck</p></a>
        <a href="#/termos-uso"><p className="text-center mb-1" style={{ fontSize: '12px' }}>Termos de uso do Portal do Cliente</p></a>
        <a href="#/politica-privacidade"><p className="text-center mb-1" style={{ fontSize: '12px' }}>Política de Privacidade do Portal do Cliente</p></a>
        <a href="#/politica-dados"><p className="text-center mb-1" style={{ fontSize: '12px' }}>Política de Dados do Portal do Cliente</p></a>
      </div>
    </Fragment>
  }
}

export default Footer;
import React from 'react';

import AbaProposta from '../../../aba-proposta';

const ABAS = [
  {
    id: 'RESUMO',
    label: 'Resumo',
    icon: 'info.svg'
  },
  {
    id: 'CADASTRO',
    label: 'Cadastro',
    icon: 'picture.svg',
    etapaProcesso: 'CADASTRO'
  },
  {
    id: 'DADOS_COMPRADOR',
    label: 'Dados comprador',
    icon: 'picture.svg',
    etapaProcesso: 'DADOS_COMPRADOR'
  },
  {
    id: 'DADOS_VENDEDOR',
    label: 'Dados vendedor',
    icon: 'picture.svg',
    etapaProcesso: 'DADOS_VENDEDOR'
  },
  {
    id: 'ENVIO_COMPROVANTES',
    label: 'Comprovantes',
    icon: 'picture.svg',
    etapaProcesso: 'ENVIO_COMPROVANTES'
  },
  {
    id: 'PROPOSTA',
    label: 'Proposta',
    icon: 'file.svg'
  },
]

function AbasProposta({ abaAtiva = '', etapaProcesso = '', cdOrigem = '', exibirInstrucoes = false, onClickAba = () => { } }) {
  return <div className="row justify-content-center" style={{ background: '#B0C4DE' }}>
    {ABAS.filter(aba => {
      if (cdOrigem == 'OLX') {
        if (aba.etapaProcesso === 'DADOS_VENDEDOR') return false;
        else return (!aba.etapaProcesso || aba.etapaProcesso === etapaProcesso)
      } else {
        return (!aba.etapaProcesso || aba.etapaProcesso === etapaProcesso)
      }
    })
      .map(aba => <AbaProposta key={aba.id} icon={aba.icon} label={aba.label} active={abaAtiva === aba.id} onClickAba={e => onClickAba(aba.id)} />)}
  </div>
}

export default AbasProposta;
import ApiService from './api.service';
import sessionStoreService from './session-store.service';

class ContratosService {


  async solicitarContato() {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (cdOrigem == 'undefined') cdOrigem = undefined;
    return ApiService.post(`clientes/solicitar-contato`, { cdOrigem });
  }

  async listarContratos() {
    return ApiService.get(`clientes/listar-contratos`);
  }

  async recuperarPorContrato(cdContrato) {
    return await ApiService.get(`clientes/recuperar-contrato?cdContrato=${cdContrato}`);
  }

  async calcularPagamento(cdContrato, dtPagamento, cdTipoNegociacao, qtParcelas) {
    return await ApiService.post(`cobrancas/calcular-pagamento`, { cdContrato, dtPagamento, cdTipoNegociacao, qtParcelas });
  }

  async listarProximasDatasVencimento() {
    return await ApiService.get(`cobrancas/listar-proximas-datas-vencimento`);
  }

  async confirmarNegociacao(cdContrato, dtPagamento, cdTipoNegociacao, qtParcelas) {
    let cdOrigem = sessionStoreService.getGenericData('cdOrigem');
    if (cdOrigem == 'undefined') cdOrigem = undefined;
    return await ApiService.post(`cobrancas/confirmar-negociacao`, { cdContrato, dtPagamento, cdTipoNegociacao, qtParcelas, cdOrigem });
  }

  async recuperarNegociacao(cdNegociacao) {
    return await ApiService.get(`cobrancas/recuperar-negociacao?cdNegociacao=${cdNegociacao}`);
  }

  async cancelarNegociacao(cdNegociacao, dsMotivoCancelamento) {
    return await ApiService.post(`cobrancas/cancelar-negociacao`, { cdNegociacao, dsMotivoCancelamento });
  }

  async confirmarEmail(params) {
    return ApiService.post('cobrancas/confirmar-email', params);
  }

  mapContratosToTable(contratos = [], exibirSeta) {
    return contratos.map(c => {
      let mappedContrato = [
        { html: `<span"><img alt="Daycoval" src="/daycoval/img/FIN_VEIC.svg" style="height: 40px; width: 40px; alt="veiculo;></img></span>` },
        {
          html: `<span>
            <h6 style="color: #555555; font-size: 15px; margin: 0px" >
              <strong>${c.dsDescricao}</strong>
              </br>
              ${c.tipo === 'COBRANCA' ? `<small>${c.qtPrazoContrato} parcelas de ${parseFloat(c.vlParcela).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</small>` : ''}
              ${c.tipo === 'CONTRATO_DIGITAL' ? `<small>Contrato assinado disponível para baixar</small>` : ''}
              ${c.tipo === 'CARNE' ? `<small>Carnê disponível para baixar</small>` : ''}
              
              ${c.tipo === 'COBRANCA' ?
              [0, 1].indexOf(c.cdStatus) > -1 ? `</br><small style="color: #FF0000; font-size: 11px;">Seu contrato está em atraso.</h6></small>` :
                [2, 4].indexOf(c.cdStatus) > -1 ? `</br><small style="font-size: 11px;">Você solicitou um boleto para pagar as parcelas em atraso deste contrato</h6></small>` :
                  '' : ''}
            </h6>
          </span>` },
        { html: `${!exibirSeta ? `<span><img alt="Daycoval" src="daycoval/img/iconfinder_forward.svg" style="height:30px; width: 35px; margin-top: 15px; alt="seta; "</span>` : ''}` }

      ]
      return mappedContrato;
    })
  }
}

export default new ContratosService();
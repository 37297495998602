import React, { Component, Fragment } from 'react';

import CampoFormatado from '../../../campo-formatado';
import formulariosService from '../../../../services/formularios.service';
import EnderecosService from '../../../../services/enderecos.service';
import TextUtil from '../../../../utils/text';

class CadastroProposta extends Component {
  constructor(props = { proposta: {}, flPermiteEditar: true }) {
    super(props);

    let proposta = props.proposta || {};
    let state = {};
    for (let key of Object.keys(proposta)) {
      state[key] = proposta[key] || '';
    }

    for (let campo of props.campos) {
      state[campo.id] = proposta[campo.id] || '';
    }
    this.state = state
  
    this.verificarCamposHabilitados();
  }

  handleCampoAlterado = (campoId, valor) => {
    this.setState({ [campoId]: valor }, () => {
      this.verificarCamposHabilitados();
      if (this.props.onDadosPropostaAlterados)
        this.props.onDadosPropostaAlterados(this.state);
    
    })
  }

  verificarCamposHabilitados = () => {
    for (let c of (this.props.campos || [])) {
      if (c.id === 'cdProprietario') {
        if (this.state.cdTipoProprietario === 'PESSOA_FISICA') {
          c.label = 'CPF do proprietário (conforme documento)';
          c.mascara = '999.999.999-99';
          c.tipo = 'cpf';
        } else {
          c.label = 'CNPJ do proprietário (conforme documento)';
          c.mascara = '99.999.999/9999-99';
          c.tipo = 'cnpj';
        }
      }
    }
  }

  handleOnBlurCep = async (valor) => {
    this.setState({ loader: { message: 'processando...', show: true }, error: null });

    let cdCepLimpo = TextUtil.retornarNumerosSemEspaco(valor);
    if (cdCepLimpo.length !== 8) {
      return this.state.msg = 'Cep incompleto, verificar por favor!';
    }
    let cep = await EnderecosService.recuperarCep(cdCepLimpo);
    let state = this.state

    state.cdUf = cep.cdUf
    state.dsCidade = cep.dsCidade
    state.dsLogradouro = cep.dsLogradouro
    state.dsBairro = cep.dsBairro

    this.setState({
      state,
      loader: {}

    });
  }
  render() {
      return <div className="row justify-content-center animated fadeIn pb-5" >
      {this.props.flAvisoPagamento && this.state.cdLocalCompra === 'PARTICULAR' ?
        <div className="col-12"> <h6>Importante: para compra de veículos particulares, o pagamento poderá ser feito apenas para ao atual proprietário do veículo, conforme o documento</h6></div> : null}
      {(this.props.campos || []).filter(c => {
        return formulariosService.avaliarPreRequisito(c, this.state)

      }).map(campo => <CampoFormatado
        key={campo.id}
        id={campo.id}
        valor={this.state[campo.id]}
        label={campo.label}
        placeholder={campo.placeholder}
        formatacao={campo.formatacao}
        mascara={campo.mascara}
        tamanho={campo.tamanho}
        tipo={campo.tipo}
        opcoes={campo.opcoes}
        texto = {'Autorizo que meus dados de contato sejam enviados ao vendedor para que ele entre em contato comigo'}
        obrigatorio={campo.obrigatorio}
        desabilitado={!this.props.flPermiteEditar || !campo.habilitado}
        onChange={(valor) => { this.handleCampoAlterado(campo.id, valor) }}
        onBlurOk={(valor) => { this.handleOnBlurCep(valor) }}
      />
      )}
    </div>
  }
}

export default CadastroProposta;
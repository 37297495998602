import React, { Component, Fragment } from 'react';
import Titulo from '../../components/jornadas/titulos';
import Componentes from './componente';
import CamposInput from '../campo-input';
import BtnActions from '../../components/jornadas/btn-actions'
import LoaderCircular from '../loader/loader-circular';
import Msgs from '../msgs';

class CardJornada extends Component {

    handleDadosAlterados = async (dados) => {
        if (this.props.onDadosAlterados)
            await this.props.onDadosAlterados(dados);
    }

    render() {

        return <Fragment>
            {this.props.conteudo ?
                <div className={`card-jornada ${this.props.tipoCabecalho == 'GRANDE' ? 'pequeno' : 'grande'}`} style={this.props.conteudo.tamanho ? { height: `${this.props.conteudo.tamanho}%` } : {}}>
                    {this.props.loading ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <LoaderCircular
                                show={true}>
                            </LoaderCircular>
                        </div>
                        : <Fragment>
                            {this.props.conteudo.titulo.props ? <div className='titulo pb-2'>
                                <Titulo titulo={this.props.conteudo.titulo || {}} />
                            </div> : null}

                            {this.props.error ? <Msgs error={this.props.error} /> : null}

                            <div className='conteudo'>
                                <Componentes
                                    componentes={this.props.conteudo.componentes}
                                    input={this.props.input}
                                    onEvento={this.props.onEvento}
                                    onDadosAlterados={this.handleDadosAlterados} />

                                <CamposInput
                                    campos={this.props.conteudo.campos || []}
                                    valores={this.props.input}
                                    flPermiteEditar={true}
                                    onDadosAlterados={this.handleDadosAlterados}
                                    onEvento={this.props.onEvento} />
                            </div>

                            <div className='acoes'>
                                <BtnActions
                                    tipo={this.props.acoes.tipo}
                                    botoes={this.props.acoes.botoes || []}
                                    onEvento={this.props.onEvento} />
                            </div>
                        </Fragment>}
                </div> : null}
        </Fragment>
    }

}
export default CardJornada;
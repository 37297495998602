import React, { Component, Fragment } from 'react';

import Msgs from '../../components/msgs';
import axios from 'axios';
import analyticsService from '../../services/analytics.service';
import Environment from '../../environment';
class S3UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedFile: null, loaded: 0 }
  }

  handleselectedFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
      uploading: false
    }, () => {
      if (this.props.directUpload)
        this.handleUpload();
    })
  }

  handleUpload = async () => {
    if (!this.state.selectedFile)
      return this.setState({ error: `Selecione o arquivo '${this.props.filename}' para enviar.` });

    this.setState({ error: undefined, uploading: true });
    try {
      let uploadLink = await this.props.getUploadLink(this.state.selectedFile.name);
      await axios.put(uploadLink, this.state.selectedFile, {
        headers: {
          'content-type': this.state.selectedFile.type
        },
        onUploadProgress: ProgressEvent => {
          this.setState({
            loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
          })
        },
      });

      this.setState({ msg: `O arquivo ${this.props.filename} foi enviado corretamente!`, finished: true });

      if (this.props.onFileUploaded)
        this.props.onFileUploaded(this.props.filename);
    } catch (err) {
      console.error(err);
      this.setState({ error: 'Ocorreu uma falha ao enviar o arquivo. Pode ser um problema de conexão. Recarregue a página e tente novamente.' });
      analyticsService.sendException(err, false);
    }
    this.setState({ uploading: false });
  }

  render() {
    return <Fragment>
      <div className="form-group m-0">
        {this.state.selectedFile ? <label htmlFor={'file' + this.props.filename} className="btn btn-block btn-light">Arquivo selecionado.</label>
          : this.props.hasValue ? <label htmlFor={'file' + this.props.filename} className="btn btn-block btn-light">Arquivo enviado. Clique aqui para alterar.</label>
            : <label htmlFor={'file' + this.props.filename} className={this.props.icon ? "btn btn-block" : "btn btn-block btn-secondary"} >
              {this.props.icon ? <img src={Environment.get('ASSETS') + `/img/file_upload.svg`} style={Object.assign({ width: 50, display: 'block', margin: '5px auto' }, this.props.styleIcon)}></img> : null}
              Clique aqui para adicionar arquivo
            </label>
        }
        <input type="file" style={{ display: 'none' }} name={'file' + this.props.filename} id={'file' + this.props.filename} onChange={this.handleselectedFile} accept="image/png,image/jpeg,application/pdf" />
      </div>
      {this.state.selectedFile && !this.props.directUpload ? <button className="btn btn-block btn-success" onClick={this.handleUpload}>Enviar {this.props.filename}</button> : null}
      <Msgs info={this.state.msg} error={this.state.error} />
    </Fragment>
  }
}

export default S3UploadFile;
import React, { Component, Fragment } from 'react';

class BackgroundJornada extends Component {
    render() {
        return <Fragment>
            {this.props.tela ? <div className='container-background' style={Object.assign({ background: '#fff' }, this.props.style)}>
                {this.props.children}
            </div> : null}
        </Fragment>
    }
}

export default BackgroundJornada;
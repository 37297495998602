import ApiService from "./api.service";

class JornadaService {
    async processarEvento(dados) {
        return ApiService.post(`jornadas/processar-evento`, dados);
    }

    async recuperarJornada(cdJornada, input) {
        return ApiService.post(`jornadas/recuperar-jornada`, { cdJornada, input });
    }
}

export default new JornadaService();
class SessionStoreService {
  saveAuthData = (authData) => {
    authData = JSON.stringify(authData);
    authData = btoa(authData);
    window.localStorage.setItem('authData', authData);
  }

  getAuthData = () => {
    let authData = window.localStorage.getItem('authData');
    if (!authData) return;
    authData = atob(authData);
    authData = JSON.parse(authData);
    return authData;
  }

  clearAuthData = () => {
    window.localStorage.removeItem('authData');
  }

  saveGenericData = (key, data) => {
    data = JSON.stringify(data);
    data = btoa(data);
    window.localStorage.setItem(key, data);
  }

  getGenericData = (key) => {
    let data = window.localStorage.getItem(key);
    if (!data) return;
    data = atob(data);
    try {
      data = JSON.parse(data);
    } catch (err) {
      console.error(err.message);
    }
    return data;
  }

  clearGenericData = (key) => {
    window.localStorage.removeItem(key);
  }

  clearSession = () => {
    let cdOrigem = this.getGenericData('cdOrigem');
    let dtOrigem = this.getGenericData('dtOrigem');
    let cookie = this.getGenericData('cookie');
    window.localStorage.clear();
    if (cdOrigem) this.saveGenericData('cdOrigem', cdOrigem);
    if (dtOrigem) this.saveGenericData('dtOrigem', dtOrigem);
    if (cookie) this.saveGenericData('cookie', cookie);
  }
}

export default new SessionStoreService();